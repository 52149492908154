html {
  overflow-y: scroll;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  top: 0 !important;
  overflow-x: hidden;
}

.row .col {
  padding-right: calc(var(--bs-gutter-x) * .3) !important;
  padding-left: calc(var(--bs-gutter-x) * .3) !important;
}

#root {
  height: 100%;
  width: 100%;
}

#root .navbar {
  padding-top: 10px;
  margin: 0 auto;
  min-height: 75px;
  display: flex;
  width: 100%;
}

.orange {
  color: #FF9017;
}

.header-actions li{
  display:block;
  padding-top:5px;
}

.header-actions {
  width: 300px;
  position: relative;
}

.header-link-container {
  margin: 10px;
  width: 100%;
  position: relative;
}

.header-link {
  text-decoration: none;
  font-size: 12px;
  display: flex;
  width: fit-content;
  white-space: nowrap;
  margin: 0 10px;
}

.header-link div {
  text-align: center;
  margin: 0 auto;
}

.home-navbar-background-container {
  width: 100%;
  min-height: 75px;
}

.navbar-image-container {
  float: left;
  width: 140px;
  height: 100px;
  margin-top: 25px;
}

.navbar-image-container.fixed-nav {
  width: 100px;
  height: 50px;
  margin-top: 0;
}

.fixed-nav {
  transition: height;
}

.header-content-container {
  width: calc(100% - 140px);
}

.header-content-container.fixed-nav {
  width: calc(100% - 100px);
}

.navbar-image-container img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.header-content {
  display: flex;
}

.fixed-nav-container {
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 9999999;
  width: 100% !important;
  padding: 0 5% !important;
}

.fixed-nav-container {
  margin: 0;
}

.ticker-container {
  height: 47px;
  width: 100%;
}

#ticker {
  margin: auto;
}

.home-contents {
  max-width: 1100px !important;
  margin: auto;
}

.home-navbar-container {
  margin: 0 auto;
  width: 1100px;
  padding-right: 10px;
  min-height: 75px;
}

.home-search-container {
  display: flex;
}

.home-search-container button {
  float: right;
  margin-right: 1rem;
}

.home-settings {
  display: flex;
  font-size: 12px;
  margin-left: 10px;
}

.home-settings-item {
  text-align: end;
  letter-spacing: -0.18pt;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.home-settings-item.dropdown {
  padding-top: 2px;
}

.home-search-box-container input[type=text] {
  font-size: 12px;
  padding: 8px 5px 7px 35px;
}

.home-search-box:focus-visible {
  outline: none;
}

.home-search-box-container {
  z-index: 10;
  width: 190px;
  margin-left: auto;
}

.deals-search-box-container,
.search-search-box-container,
.home-search-box-container {
  position: relative;
}

.deals-search-box-container img,
.home-search-box-container img {
  position: absolute;
  top: 9px;
  left: 9px;
  width: 18px;
  height: 18px;
}

.home-search-box {
  width: 100%;
  border-radius: 18px;
  border: none;
  height: 36px;
}

.home-theme-icon {
  vertical-align: top;
  width: 18px;
  height: 18px;
}

.home-settings-register-button.plus {
  padding: 8px 8px;
  border: none !important;
}

.home-settings-register-button {
  border: none;
  border-radius: 3px;
  font-size: 10px;
  padding: 8px 16px;
}

.login-button,
.recovery-page-mailapp-button {
  border: none;
  border-radius: 3px;
  font-size: 14px;
  padding: 15px 16px;
  width: 100%;
}

.main-contents {
  width: 100%;
  margin-top: 10px;
}

.home-section-news-main-story,
.home-section-news-list-story,
.news-container,
.news-page-main-story,
.news-page-list-story,
.card-container,
.home-section-course,
.home-section-info-container,
.news-page-market-story,
.story-page-list,
.search-page-list,
.search-page-encyclopedia-list,
.wallet-subpage-card-container,
.learn-module-card-container,
.learn-category-card-container,
.trending-card-container,
.gainer-card-container,
.loser-card-container {
  overflow-y: hidden;
}

.news-page-list-story {
  display: flex !important;
}

.one-stop-container {
  margin: 30px 30px 10px -5px;
}

.one-stop-head {
  font-size: 40px;
}

.one-stop-text {
  margin-top: 15px;
  font-size: 16px;
}

.one-stop-btn-light {

}

.one-stop-actions-link {
  text-decoration: none !important;
}

.one-stop-btn-dark {
  margin-left: auto;
}

.one-stop-btn-dark,
.one-stop-btn-light {
  width: 95% !important;
  margin-right: 0 !important;
}

.one-stop-btn-light:hover,
.learn-page-intro-button-light:hover,
.coin-data-analysis-vote-choose button:hover {
  transition: all 0.3s ease-in-out;
  transition-property: box-shadow, border;
}

.back-to-top-button:hover,
.one-stop-btn-dark:hover,
.home-section-news-button:hover,
.newsletter-button:hover,
.market-data-button:hover,
.learn-2-earn-button:hover,
.home-section-course-button:hover,
.home-section-coins-button:hover,
.home-section-coins-grid-button:hover,
.subscribe-button:hover,
.news-page-card-button:hover,
.market-news-button-mob:hover,
.news-page-card-carousel-button:hover,
.coin-portfolio-title-button:hover,
.info-page-summary-button:hover,
.home-settings-register-button:hover,
.learn-page-intro-button-dark:hover,
.learn-module-card-button:hover,
.learn-category-card-button:hover,
.course-section-block-button:hover {
  transition: all 0.3s ease-in-out;
  transition-property: box-shadow;
}

.encyclopedia-read-more-container {
  padding-top: 10px;
}

.encyclopedia-read-more-button {
  width: fit-content;
  margin-right: 5px;
  border: none;
  border-radius: 3px;
  padding: 7px 20px 7px 20px;
  font-size: 10px;
}

.back-to-top-button,
.one-stop-subscribe-btn,
.home-section-news-button,
.newsletter-button,
.market-data-button,
.learn-2-earn-button,
.home-section-course-button,
.coin-portfolio-title-button,
.subscribe-button,
.news-page-card-button,
.news-page-card-carousel-button,
.market-news-button-mob,
.info-page-summary-button,
.learn-module-card-button,
.learn-category-card-button,
.course-section-block-button,
.newsletter-signup-form-button {
  width: 40%;
  margin-right: 5px;
  border: none;
  border-radius: 3px;
  padding: 7px 5px 7px 5px;
  font-size: 10px;
}

.learn-category-card-button {
  margin-left: -4px;
}

.learn-page-intro-button {
  width: 130px !important;
  margin-right: 5px;
  border: none;
  border-radius: 3px;
  padding: 7px 5px 7px 5px;
  font-size: 10px;
}

.one-stop-actions {
  margin-top: 40px;
  position: unset;
  z-index: 5;
  display: flex !important;
}


.home-learn-container,
.news-page-market-container {
  margin-top: 2rem;
}

.news-page-market-container .container {
  padding-left: 15px;
  padding-right: 15px;
}

.home-news-content, .home-learn-content, .home-info-content {
  margin-top: -1px;
}

.home-newsletter-content,
.home-coins-content {
  margin-top: 10px;
}

.home-learn-container-mobile {
  margin-top: 90px;
}

.home-coins-container {
  padding-bottom: 90px;
}

.news-chevron-container-light,
.news-chevron-container-dark {
  border-style: solid;
  border-width: 70px 50vw 10vw 50vw;
  margin-bottom: -80px;
  margin-top: -1px;
  position: absolute;
}

.chevron-container-dark {
  border-style: solid;
  border-width: 100px 50vw 0 50vw;
  margin-top: -70px;
}

.chevron-container-light {
  border-style: solid;
  border-width: 100px 50vw 0 50vw;
  margin-top:-100px;
}

.news-container,
.card-container,
.home-section-news-list-story,
.news-page-list-story,
.news-page-market-story,
.story-page-list,
.search-page-list,
.search-page-encyclopedia-list,
.search-page-coin-info,
.home-section-course,
.home-section-info-container,
.home-section-coins-card-container,
.wallet-subpage-card-container,
.learn-module-card-container,
.learn-category-card-container,
.course-section-card,
.profile-section-card,
.coin-data-analysis-technical,
.coin-data-statistics-container,
.coin-data-analysis-vote,
.login-action-container {
  border: none;
  border-radius: 0 10px 10px 10px;
  display:flex;
  overflow: clip;
  overflow-x:hidden;
}

.trending-card-container,
.gainer-card-container,
.loser-card-container {
  border: none;
  border-radius: 10px 10px 10px 10px;
  display:flex;
  overflow: clip;
  overflow-x:hidden;
}

.learn-category-card-container {
  display: block;
  position: relative;
}

.news-container {
  height: 279px;
}

.news-container .row {
  --bs-gutter-x: 0 !important;
}

.news-container .container {
  padding-left: 0;
  padding-right: 0;
}

.learn-module-summary-column .container {
  padding-left: 15px;
  padding-right: 15px;
}

.news-container .main-story {
  max-width: 350px;
}

.main-story .news-story {
  height: 279px;
  overflow-y: hidden;
}

.news-story {
  position: relative;
  padding: 0 !important;
  overflow-x: hidden;
  margin: 0 !important;
}

.visible-story {
  animation: fadeIn 1s;
  height: 279px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.news-story img {
  object-fit: cover;
  object-position: center center;
  height: 100%;
  width: 100%;
}

.news-overlay {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 20px;
  width: 100%;
  padding:30px;
}

.news-overlay .news-overlay-details-container {
  padding-left: 0;
}

.news-overlay-details-container {
  margin-top: 5px !important;
  padding-left: 5px;
}

.news-overlay-details-container .card-button {
  position: unset;
}

.crypto-learn-card-container .card-button {
  z-index: 100;
}

.news-page-news-information-container {
  display: flex;
  position: relative;
}

.news-page-trending-information-container{
  display: flex;
  position: relative;
}

.home-page-news-information-container {
  display: flex;
  position: relative;
}

.market-news-information-container {
  display: flex;
  position: absolute;
}

.row.market-news-date-row {
  position: absolute;
}

.news-read-more-button.market-news {
  margin-right: 22px;
}

.market-news-date-row,
.market-news-info-row {
  width: 100%;
}

.market-news-read-more-button {
  position: absolute;
  bottom: 0;
  right: 0;
}

.news-read-more-button {
  position: absolute;
  top: 0;
  right: 12px;
}

.news-author,
.news-page-story-author {
  font-size: 10px;
  padding-right: 20px;
}

.news-date,
.news-author-mob.market {
  font-size: 10px;
}

.news-time-expired,
.news-tab-time-expired {
  font-size: 10px;
}

.news-list {
  margin-top: 20px;
}

.news-headline {
  animation: fade 2s;
}

@keyframes fade {
  0% { opacity: 0 }
  100% { opacity: 1 }
}


.news-list-story {
  padding: 8px 10px 0 20px;
  border-radius: 0 5px 5px 0;
}

.news-story-detail {
  padding-bottom: 8px;
}

.news-story-paragraph-break {
  font-size: 16px;
  margin-bottom: 20px;
}

.learn-category-text a,
.news-story-subheading a,
.news-story-paragraph-break a {
  text-decoration: underline !important;
}

.news-story-paragraph-break h1,
.news-story-paragraph-break h2 {
  font-size: 16px;
  margin-top: 8px;
  height: 34px;
}

.news-headline {
  font-size: 12px;
  height: 30px;
  overflow-y: hidden;
  margin-bottom: 4px;
}

.news-selection-indicator {
  display: flex;
  position: absolute;
  top: 255px;
  left: 15px;
}


.news-story-selected-false {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin: 1px;
}

.news-story-selected-true {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin: 1px;
}

.tab-heading {
  position: relative;
  display: flex;
  width: 149px !important;
  border-radius: 0 10px 0 0;
  padding: 10px 10px 10px 32px !important;
  font-size: 12px;
}

.tab-heading img {
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px 10px 10px 8px;
}

.card-container,
.trending-card-container,
.gainer-card-container,
.loser-card-container {
  padding-bottom: 20px;
  height: 223px;
  position: relative;
  width: 350px;
}

.card-contents-container {
  height: 220px;
  position: relative;
  padding-left: 20px !important;
  max-width: 197px !important;
}

.card-title {
  font-size: 10px;
  margin-top:15px;
}

.card-heading {
  font-size: 20px;
  margin-bottom: 10px;
  max-width: 150px;
  height: 44px;
  overflow: hidden;
}

.card-text {
  font-size: 12px;
  margin-bottom: 10px;
  height: 72px;
  overflow: hidden;
}

.card-button {
  border: none;
  border-radius: 3px;
  font-size: 10px;
  padding: 6px 20px;
  float: left;
  position: absolute;
  left: 20px;
  bottom: 20px;
}

.course-section-take-quiz-button:hover,
.card-button:hover,
.module-button:hover {
  transition: all 0.3s ease-in-out;
  transition-property: box-shadow;
}

.card-image {
  position: relative;
}

.card-image img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: -10px;
  right: 0;
  margin: auto;
  width: 120px;
}

.card-image.learn img {
  bottom: -3px;
}

.coin-text-heavy {
  font-size: 12px;
  padding: 5px 5px;
  text-align: left;
  overflow: hidden;
  height: 18px;
}

.coin-text-light {
  font-size: 12px;
  padding: 5px 5px;
  text-align: left;
  overflow: hidden;
}

.coin-text-up {
  margin-left: 2px;
}

.coin-text-down {
  margin-left: 2px;
}

.coin-price-change .coin-text-up,
.coin-price-change .coin-text-down {
  font-size: 12px;
  padding: 0 2px;
}

.home-section-coins-grid-body-text {
  font-size: 14px;
  margin-top: 3px !important;
}

.fixed-col-1,
.fixed-col-2,
.fixed-col-3,
.fixed-col-4,
.fixed-col-5 {
  padding-left: 2px !important;
  padding-right: 2px !important;
  overflow-x: hidden;
}

.fixed-col-1 {
  width: 22px !important;
}

.fixed-col-2 {
  width: 44px !important;
}

.fixed-col-3 {
  width: 66px !important;
}

.fixed-col-4 {
  width: 88px !important;
}

.fixed-col-5 {
  width: 110px !important;
}

.coin-card-row {
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
  max-height: 22px;
}

.coin-card-row.expanded {
  height: 26px;
  max-height: 26px;
}

.coin-identifier {
  display: flex;
  overflow: hidden;
}

.coin-identifier img {
  height: 20px;
  margin-right: 5px;
}

.coin-counter {
  text-align: left;
  padding: 5px 5px;
}

.coin-price-change,
.home-loser-coin-price-change {
  text-align: right;
  display: block;
  margin-left: 10px;
  padding: 0 !important;
}

.coin-price-change {
  padding: 5px 5px;
  overflow: hidden;
  margin-top: 1px;
}

.coin-price-change img,
.home-loser-coin-price-change img {
  height: 5px;
  width: 6px;
}

.coin-price-change img {
  padding-left: 0 !important;
  margin-top: 0 !important;
}

.home-loser-coin-price-change {
  margin-left: 0;
  padding: 5px 5px 5px 20px !important;
}

.home-section-icon-container {
  position: relative;
}

.home-section-icon-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
}

.home-section-icon {
  position: absolute;
  top: 13px;
  left: 14px;
  width: 22px;
  height: 22px;
}

.home-section-icon.learn {
  width: 24px;
  left: 13px;
}

.home-section-icon.info {
  left: 13px;
  top: 12px;
}

.home-section-icon.coins {
  top: 11px;
}

.coin-react {
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
  background-image: initial;
}

.coin-react-container {
  width: fit-content;
  height: fit-content;
  margin-right: 40px;
}

.home-section-heading {
  font-size: 16px;
  margin-left: 60px;
  padding-top: 16px;
}

.news-story-paragraph-break,
.news-story-paragraph-break a:visited,
.news-story-paragraph-break a:hover,
.news-story-paragraph-break a:link,
.news-story-paragraph-break a:active,
.home-section-heading .link {
  text-decoration: none;
}

.home-section-trending-heading,
.home-section-recommend-heading,
.home-section-info-howto-heading,
.news-page-trending-heading,
.news-story-trending,
.wallet-subpage-howto-heading {
  font-size: 8pt;
  padding-top: 30px;
  margin-bottom: 35px;
}

.home-section-recommend-heading {
  padding-top: 30px;
}

.home-section-tag {
  font-size: 10px;
  margin-bottom: 8px;
}

.home-section-news-main-story, .news-page-main-story, .news-page-market-story {
  margin-top: 40px;
  border: none;
  border-radius: 0 10px 10px 10px;
  overflow: clip;
  overflow-x:hidden;
  height: 436px;
  display: block;
}

.home-section-news-main-story img,
.news-page-main-story img,
.news-page-market-story img {
  width: 100%;
  height: 223px;
  object-fit: cover;
}

.home-section-news-main-story a,
.news-page-main-story a,
.coin-grid-row-container a,
.home-section-coins-grid a,
.market-data-grid-row-container a{
  text-decoration: none;
}

.home-section-main-news-block,
.news-page-market-story-block,
.news-story-block {
  border-radius: 5px;
  padding: 30px;
}

.visible-story-mobile .home-section-main-news-block,
.visible-story-mobile .news-page-market-story-block,
.visible-story-mobile .news-story-block {
  padding: 20px 10px !important;
}

.news-story-block,
.news-story-trending{
  padding-top: 0 !important;
}

.home-section-main-news-block {
  position: relative;
}

.home-section-main-news-heading {
  font-size: 24px;
  height: 98px;
  overflow-y: hidden;
}

.home-section-news-button-container,
.footer-subscribe-button-container,
.market-data-button-container{
  width: 100%;
  margin-top: 30px !important;
  position: relative;
}
.back-to-top-button-container,
.home-section-news-button-container {
  margin-top: 50px !important;
}

.home-section-story-nav-buttons {
  right: 12px;
  height: 20px;
  display: flex;
}

.home-section-story-nav-buttons img {
  height: 12px;
  width: 8px;
  margin-right: 20px;
}

.home-section-news-button {
  z-index: 10 !important;
}

.home-section-news-button,
.market-data-button{
  width: 150px;
  margin: auto;
  position: absolute;
  top: 0;
  left: calc(50% - 75px);
  height: 28px;
}

.home-section-news-headline {
  font-size: 20px;
  margin-bottom: 10px;
  height: 65px;
  overflow: hidden;
}

.home-section-main-news-block .news-date {
  padding-top: 5px;
}

.home-section-news-list-story,
.news-page-list-story {
  margin-top: 12px;
  height: 136px;
}

.story-page-list {
  margin-top: 12px;
  height: 100px;
}

.home-section-news-list-story .home-section-news-text,
.news-page-list-story .news-page-story-text{
  padding: 15px 25px 20px 25px !important;
}

.home-section-news-text,
.news-page-story-text {
  height: 100%;
}

.news-page-story-image {
  width: 40%;
}
.news-page-story-image img {
  overflow: hidden;
  height: 100%;
  object-fit: contain;
}
.news-page-story-text {
  width: 60%;
}

.home-section-news-image {
  width: 215px;
}

.search-page-list img {
  height: 140.5px !important;
}

.search-page-encyclopedia-list img {
  width: 100%;
  height: 114%;
}

.search-page-list img,
.home-section-news-list-story img,
.news-page-list-story img,
.story-page-list img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.home-learn-2-earn-container {
  margin-top: 60px;
  margin-right: 70px;
}

.learn-2-earn-heading {
  font-size: 24px;
  margin-top:30px;
  margin-bottom: 10px;
}

.learn-2-earn-text {
  font-size: 16px;
  margin-top: 20px;
}

.learn-2-earn-button-container {
  margin-top: 20px !important;
}

.learn-2-earn-button {
  width: 150px;
}

.home-section-course-list {
  margin-top: -20px;
}

.home-section-course {
  margin-top: 10px;
  height: 112px;
  position: relative;
}

.home-section-course-content {
  height: 100%;
}

.home-section-course-image {
  height: 100%;
}

.home-section-course a {
  width: 100%;
}

.home-section-course img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home-section-course-text {
  padding: 16px 20px 10px 34px !important;
  min-width: 349px !important;
  max-width: 349px !important;
  z-index: 2;
}

.home-section-course-text .col {
  display: flex;
}

.home-section-course-title {
  font-size: 18px;
  margin-left: -5px;
  margin-bottom: 5px;
}

.home-section-course-label {
  font-size: 8pt;
}

.home-section-course-value {
  font-size: 8pt;
  padding-left:2px;
  padding-right: 20px;
}

.home-section-course-details {
  display: flex !important;
  margin-top:3px;
}

.home-section-coins-button,
.home-section-coins-grid-button,
.news-page-card-button,
.news-page-card-carousel-button,
.market-news-button-mob,
.info-page-summary-button{
  width: 112px !important;
  margin-top: 20px !important;
  margin-bottom: -3px;
  border: none;
  border-radius: 3px;
  font-size: 10px;
  padding: 6px 20px;
}

.home-section-course-button{
  width: 112px !important;
  margin-top: 12px !important;
  margin-bottom: -3px;
  border: none;
  border-radius: 3px;
  font-size: 10px;
  padding: 6px 20px;
}

.home-section-course-button,
.home-section-coins-button,
.home-section-coins-grid-button,
.info-page-summary-button {
  float: left;
  position: relative;
}

.market-news-button-mob {
  margin-bottom: 5px;
}

.col.market-date-author {
  margin-top: -20px;
  padding-left: 4% !important;
}

.news-page-card-button,
.news-page-card-carousel-button {
  position: absolute;
  margin-top: 0 !important;
  bottom: 0;
}

.news-page-card-button {
  right: 22px;
}

.news-page-card-carousel-button {
  right: -2px;
}

.news-page-card-carousel-button {
  bottom: -8px !important;
}

.story-page-news-list {
  width: 324px;
  margin-bottom: 60px;
}

.home-section-info-title,
.home-section-coins-title,
.learn-module-card-heading {
  font-size: 24px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.home-section-info-guide-title,
.wallet-subpage-guide-title {
  font-size: 20px;
  margin-bottom: 5px;
  height: 44px;
  overflow: hidden;
  text-decoration: none !important;
}

.home-section-info-container,
.wallet-subpage-card-container{
  margin-bottom: 20px;
  padding: 20px 40px 20px 20px;
  height: 223px;
  position: relative;
}

.learn-module-card-container {
  margin-bottom: 20px;
  height: 223px;
  position: relative;
}

.home-section-info-container.search {
  padding: 10px 15px 10px 25px;
}


.home-section-info-container:hover,
.home-section-news-list-story:hover,
.news-page-list-story:hover,
.search-page-list:hover,
.search-page-encyclopedia-list:hover,
.story-page-list:hover,
.home-section-news-main-story:hover,
.home-section-course:hover,
.news-page-main-story:hover,
.news-page-market-story:hover,
.wallet-subpage-card-container:hover,
.learn-module-card-container:hover,
.learn-category-card-container:hover,
.course-section-card:hover,
.profile-section-card:hover,
.login-action-container:hover {
  border-radius: 0 10px 10px 10px;
  overflow: clip;
  overflow-x:hidden;
  transition: all 0.3s ease-in-out;
  transition-property: box-shadow, border;
}

.home-section-info-howto-heading {
  padding-top: 0;
  margin-bottom: 10px;
}

.home-section-info-text.search {
  font-size: 15px !important;
  line-height: 1.05 !important;
  height: 91px !important;
}

.home-section-info-text,
.wallet-subpage-guide-text {
  font-size: 12px;
  padding-bottom: 40px;
  height: 73px;
  width: 158px;
  overflow: hidden;
}

.home-section-info-pages-container {
  width: fit-content;
  margin: auto;
}

.home-section-info-pages-container img {
  padding: 10px;
}

.home-section-page-selected-true {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin: 1px 5px;
}

.home-section-page-selected-false {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin: 1px 5px;
}

.home-section-coins-container {
  width: 1088px;
  height: fit-content;
}

.home-section-coins-contents {
  padding-top: 30px;
}

.home-section-coins-button, .home-section-coins-grid-button {
  width: 70px !important
}

.home-section-coins-grid-button-container {
  width: fit-content;
  margin: auto;
}

.home-section-coins-grid-button {
  margin: 0 !important;
}

.home-section-coins-coins {
  margin-top: 40px !important;
  position: relative;
}

.home-section-coins-grid {
  display: inline-block;
  text-align: left;
  padding: 0;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.home-section-market-cap-head {
  position: relative;
}

.home-section-market-cap-head img,
.coin-grid-header .market-cap img,
.market-data-grid-header .market-cap img{
  position: absolute;
  top: -5px;
  right: -5px;
}

.home-section-coins-grid-head {
  font-size: 10px;
  width: calc(100% - 40px);
  margin-left: 20px !important;
  margin-right: 20px !important;
  padding-bottom: 10px;
}

.home-section-coins-grid-body {
  width: calc(100% - 40px);
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.home-section-coins-more {
  width: fit-content;
  font-size: 8pt;
  padding-top: 10px;
  margin: 10px auto auto;
  cursor: pointer;
}

.home-footer-container {
  width: 100%;
}

.footer-container {
  width: 100%;
  height: fit-content;
  padding-top: 40px;
  padding-bottom: 30px;
}

.quiz-contents {
  position: relative;
  min-width: 800px;
  height: 100%;
}

.footer-container .home-contents {
  position: relative;
  height: 100%;
}

.footer-subscribe-container {
  height: 303px;
}

.footer-subscribe-title {
  font-size: 30px;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 5px;
}

.footer-subscribe-text {
  font-size: 18px;
  text-align: center;
  padding-bottom: 20px;
}

.footer-topic-heading {
  font-size: 12px;
  padding-top: 5px;
}

.footer-topic-text {
  font-size: 12px;
  padding-top: 10px;
}

.social-media-logo {
  width: 26px !important;
  display: flex;
  padding-top: 10px;
}

.social-media-logo img {
  padding-right: 15px;
}

.legal-container {
  width: fit-content;
  margin: 0 auto;
  left: 0;
  display: flex;
  padding-top: 25px;
}

.legal-text {
  font-size: 9px;
  text-align: center;
  padding-right: 40px;
}

.subscribe-banner-container {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 99;
  height: 106px;
  max-height: 0; /* modified by scrolling script */
  -webkit-transition: max-height .3s;
  -moz-transition: max-height .3s;
  -ms-transition: max-height .3s;
  -o-transition: max-height .3s;
  transition: max-height .3s;
}

.subscribe-banner-contents {
  height: 100%;
  display: flex;
}

.subscribe-banner-text {
  font-size: 18px;
  justify-content: right;
  align-items: center;
  display: flex;
  padding-right: 50px !important;
}

.subscribe-image-container, .subscribe-button-container {
  display: flex;
  align-items: center;
}

.subscribe-banner-contents .row {
  margin: 0;
}

.subscribe-image-container {
  padding-left: 10px !important;
}

.subscribe-button-container {
  position: relative;
  padding-left: 24px !important;
  padding-right: 0 !important;
}

.subscribe-x {
  position: absolute;
  font-size: 20px;
  right: 10px;
  top: 10px;
}

.subscribe-button {
  padding: 5px 30px 5px 30px;
  width: 150px;
  height: 28px;
}

.news-page-heading,
.info-page-heading,
.coin-page-heading {
  font-size: 30px;
  padding-bottom: 10px;
  padding-left: 5px;
}

.nav-border {
  padding-top: 20px;
}

.nav-border.fixed-nav {
  padding-top: 0;
}

.nav-border.portfolio-transaction-row {
  margin-left: 0;
  opacity: 33%;
  padding: 0 !important;

}

.news-page-trending-heading {
  padding-top: 5px;
  margin-bottom: 5px;
}

.news-page-main-story {
  margin-top: 10px;
}

.encyclopedia-submenu-heading,
.news-page-most-read-heading,
.page-submenu-heading {
  display: flex;
  width: 100%;
}

.encyclopedia-submenu-heading {
  padding-bottom: 40px;
}

.news-page-most-read-heading {
  padding-bottom: 10px;
}

.news-page-most-read-image {
  width: 215px !important;
  height: 136px;
}

.news-page-most-read-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
}

.encyclopedia-menu-container,
.market-menu-container,
.page-submenu-container,
.page-menu-container {
  position: relative;
  padding-bottom: 20px;
  padding-top: 5px;
}

.encyclopedia-menu-container {
  padding-left: 20px;
  display: block;
}

.encyclopedia-small-menu-container {
  position: relative;
  padding-left: 0;
  display: none;
}

.encyclopedia-small-menu-container select{
  width: 100px;
  padding: 4px;
}

.page-submenu-container {
  padding-left: 32px;
}

.page-menu-container {
  padding-bottom: 10px;
}

.encyclopedia-submenu-heading ul,
.page-submenu-heading ul,
.page-menu-container ul {
  list-style: none;
  padding-left: 0;
}

.encyclopedia-submenu-heading {
  letter-spacing: -0.18pt;
}

.encyclopedia-submenu-heading a {
  float: left;
  width: 60px;
  text-align: center;
  letter-spacing: -0.18pt;
}

.page-submenu-heading li {
  float: left;
  text-align: center;
  font-size: 14px;
}

.page-menu-container li {
  float: left;
  width: 60px;
  text-align: center;
  font-size: 14px;
}

.page-menu-container li {
  width: unset;
}

.news-page-market-title,
.news-page-most-read-title,
.page-submenu-title {
  font-size: 20px;
}

.page-submenu-title.transactions {
  padding-bottom: 10px;
}

.home-contents.portfolio-transaction {

}

.market-heading-indicator,
*[id^="page-submenu-heading-indicator"],
.page-menu-indicator {
  height: 3px;
  margin-top: 15px !important;
  border-radius: 5px;
  transition: left 0.5s;
  position: absolute;
  bottom: 0;
}


.page-menu-indicator {
  width: 22px;
}

.news-page-market-story {
  height: 355px;
  position: relative;
}

.news-page-market-story img {
  height: 203px;
}

.news-page-market-story-heading {
  font-size: 18px;
  height: 60px !important;
  overflow: hidden;
  padding-bottom: 70px;
  margin-bottom: 5px;
}

.news-page-market-story-block {
  position: relative;
  padding: 20px !important;
}

.news-page-market-story-details-container {
  width: 100%;
}

.news-page-market-story-tag-container {
  position: absolute;
  top: 20px;
  left: 20px;
  border-radius: 5px;
  height: 20px;
  padding: 5px 10px 5px 10px;
}

.news-page-market-story-tag {
  font-size: 9px;
  margin-top: 1px;
}

.news-page-story-author {
  margin-left: 5px;
}

.market-news-author {
  font-size: 10px;
  padding-top: 15px;
}

.home-newsletter-container {
  padding-top: 70px;
  margin: auto;
  padding-bottom: 60px;
}

.newsletter-container {
  margin: auto;
  padding-top: 40px;
  padding-right: 55px;
}

.newsletter-container.mob {
  padding-right: 0;
  padding-top: 10px;
}

.newsletter-img-container {
  margin: 10px auto 50px;
}

.col.img{
  display: flex;
  justify-content: center;
  align-items: center;
}

.newsletter-heading {
  font-size: 24px;
  padding-top: 10px;
  padding-right: 33%;
  letter-spacing: -.18pt;
  line-height: 29px;
}
.newsletter-heading.mob {
  padding-right: 0;
  text-align: center;
}

.newsletter-text {
  margin-top: 20px;
  padding-right: 25px;
  font-size: 13px;
}

.newsletter-text.mob {
  padding-right: 0;
  text-align: center;
}

.newsletter-img {
  width: 100%;
  height: 100%;
  padding-left: 20px;
}

.newsletter-button-container {
  padding-top: 40px;
}

.newsletter-button-container.mob {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.newsletter-button {
  width: 150px !important;
}

.news-page-most-read-counter {
  font-size: 14px;
  padding-top: 20px;
  margin-left: 10px;
  width: 32px !important;
}

.news-page-most-read-story-text {
  width: 291px !important;
}

.news-page-most-read-story-headline {
  font-size: 20px;
  height: 65px;
  overflow: hidden;
  margin-bottom: 25px;
  margin-top: 20px;
}

.news-page-most-read-container {
  padding-bottom: 50px;
}

.news-page-most-read-story-details,
.news-story-details {
  padding-left: 5px !important;
}

.news-page-most-read-list {
  padding-top: 20px;
}

.story-page-chevron,
.info-article-chevron,
.learn-module-chevron,
.course-page-chevron,
.coins-page-chevron {
  border-style: solid;
  border-width: 100px 50vw 0 50vw;
  margin-top: 600px;
}

.learn-module-chevron {
  margin-top: 200px;
}

.course-page-chevron {
  margin-top: 300px;
}

.coins-page-chevron {
  margin-top: 320px;
}

.profile-page-container,
.story-page-container,
.story-page-content,
.info-article-container,
.info-article-content,
.learn-module-container,
.learn-module-content,
.course-page-container,
.coin-page-container,
.coin-page-content {
  position: relative;
  min-height: 500px;
}

.story-page-most-read {
  padding-top: 30px;
}

.story-page-chevron-container,
.info-article-chevron-container,
.learn-module-chevron-container,
.course-page-chevron-container,
.coins-page-chevron-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 600px;
  width: 100%;
}

.learn-module-chevron-container {
  height: 200px;
}

.course-page-chevron-container {
  height: 300px;
}

.coins-page-chevron-container {
  height: 320px;
}

.encyclopedia-article-block,
.news-story-block,
.info-article-block {
  padding-right: 50px !important;
}

.encyclopedia-article-block {
  min-height: 800px;
}

.info-article-block {
  padding-right: 20px !important;
}

.encyclopedia-breadcrumb,
.learn-chapter-breadcrumb,
.learn-category-breadcrumb,
.news-story-breadcrumb,
.info-article-breadcrumb,
.leaderboard-breadcrumb,
.coin-detail-breadcrumb,
.portfolio-breadcrumb,
.portfolio-transactions-breadcrumb {
  font-size: 10px;
  display: flex;
}

.portfolio-breadcrumb{
  margin-bottom: 20px;
}

.portfolio-transactions-breadcrumb {
  width: fit-content;
  margin-bottom: 30px;
}

.leaderboard-breadcrumb a,
.info-article-breadcrumb a {
  padding-right: 10px;
}

.encyclopedia-breadcrumb img,
.learn-chapter-breadcrumb img,
.news-story-breadcrumb img,
.coin-detail-breadcrumb img,
.portfolio-transactions-breadcrumb img {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 1px;
}

.leaderboard-breadcrumb img,
.learn-category-breadcrumb img,
.info-article-breadcrumb img {
  padding-right: 15px;
  padding-bottom: 1px;
}

.tab-heading-container a:link,
.tab-heading-container a:visited,
.tab-heading-container a:hover,
.tab-heading-container a:active,
.search-page-info-list a:link,
.search-page-info-list a:visited,
.search-page-info-list a:hover,
.search-page-info-list a:active,
.encyclopedia-breadcrumb a:link,
.encyclopedia-breadcrumb a:visited,
.encyclopedia-breadcrumb a:hover,
.encyclopedia-breadcrumb a:active,
.news-story-breadcrumb a:link,
.news-story-breadcrumb a:visited,
.news-story-breadcrumb a:hover,
.news-story-breadcrumb a:active,
.info-article-breadcrumb a:link,
.info-article-breadcrumb a:visited,
.info-article-breadcrumb a:hover,
.info-article-breadcrumb a:active,
.leaderboard-breadcrumb a:link,
.leaderboard-breadcrumb a:visited,
.leaderboard-breadcrumb a:hover,
.leaderboard-breadcrumb a:active,
.learn-category-breadcrumb a:link,
.learn-category-breadcrumb a:visited,
.learn-category-breadcrumb a:hover,
.learn-category-breadcrumb a:active,
.coin-detail-breadcrumb a:link,
.coin-detail-breadcrumb a:visited,
.coin-detail-breadcrumb a:hover,
.coin-detail-breadcrumb a:active,
.portfolio-breadcrumb a:link,
.portfolio-breadcrumb a:visited,
.portfolio-breadcrumb a:hover,
.portfolio-breadcrumb a:active,
.learn-chapter-breadcrumb a:link,
.learn-chapter-breadcrumb a:visited,
.learn-chapter-breadcrumb a:hover,
.learn-chapter-breadcrumb a:active,
.register-login a:link,
.register-login a:visited,
.register-login a:hover,
.register-login a:active,
.login-register a:link,
.login-register a:visited,
.login-register a:hover,
.login-register a:active,
.login-contents a:link,
.login-contents a:visited,
.login-contents a:hover,
.login-contents a:active,
.portfolio-transactions-breadcrumb a:link,
.portfolio-transactions-breadcrumb a:visited,
.portfolio-transactions-breadcrumb a:hover,
.portfolio-transactions-breadcrumb a:active
{
  text-decoration: none;
}

.legal-content a:link,
.legal-content a:visited,
.legal-content a:hover,
.legal-content a:active,
.crypto-learn-card-container a:link,
.crypto-learn-card-container a:visited,
.crypto-learn-card-container a:hover,
.crypto-learn-card-container a:active,
.encyclopedia-page-word-container a:link,
.encyclopedia-page-word-container a:visited,
.encyclopedia-page-word-container a:hover,
.encyclopedia-page-word-container a:active,
.search-page-coins a:link,
.search-page-coins a:visited,
.search-page-coins a:hover,
.search-page-coins a:active,
.search-page-articles a:link,
.search-page-articles a:visited,
.search-page-articles a:hover,
.search-page-articles a:active,
.crypto-info-card-container a:link,
.crypto-info-card-container a:visited,
.crypto-info-card-container a:hover,
.crypto-info-card-container a:active,
.news-list a:link,
.news-list a:visited,
.news-list a:hover,
.news-list a:active,
.home-section-info-list a:link,
.home-section-info-list a:visited,
.home-section-info-list a:hover,
.home-section-info-list a:active,
.story-page-news-list a:link,
.story-page-news-list a:visited,
.story-page-news-list a:hover,
.story-page-news-list a:active,
.news-page-news-list a:link,
.news-page-news-list a:visited,
.news-page-news-list a:hover,
.news-page-news-list a:active,
.news-page-market-contents a:link,
.news-page-market-contents a:visited,
.news-page-market-contents a:hover,
.news-page-market-contents a:active,
.related-news-cards-container a:link,
.related-news-cards-container a:visited,
.related-news-cards-container a:hover,
.related-news-cards-container a:active,
.news-page-most-read-list a:link,
.news-page-most-read-list a:visited,
.news-page-most-read-list a:hover,
.news-page-most-read-list a:active,
.home-section-news-list a:link,
.home-section-news-list a:visited,
.home-section-news-list a:hover,
.home-section-news-list a:active,
.home-contents .row .col a:link,
.home-contents .row .col a:visited,
.home-contents .row .col a:hover,
.home-contents .row .col a:active,
.learn-link-container a:link,
.learn-link-container a:visited,
.learn-link-container a:hover,
.learn-link-container a:active,
.home-section-course a:link,
.home-section-course a:visited,
.home-section-course a:hover,
.home-section-course a:active
{
  text-decoration: none;
  color: unset;
}

.news-story-heading {
  font-size: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.info-article-heading {
  font-size: 40px;
  padding-top: 20px;
}

.news-story-subheading {
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 20px;
}

.news-story-details-container {
  display: flex;
  padding-bottom: 40px;
  padding-top: 10px;
  position: relative;;
}

.news-story-details-container .news-author {
  padding-right: 40px;
}

.news-story-image {
  height: 371px;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}

.info-article-image {
  height: 255px;
  padding-top: 30px;
  margin-bottom: 5px;
}

.info-article-image img {
  max-width: 100%;
}

.news-story-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.news-story-trending {
  margin-bottom: 20px;
}

.search-page-encyclopedia-list,
.search-page-list {
  margin-bottom: 20px;
  height: 140px;
}

.story-page-list .story-list-text{
  padding: 10px 5px 10px 25px !important;
  width: 220px;
}

.story-list-text .news-author {
  font-size: 10px;
  padding: 10px 15px 10px 12px;
}

.story-list-text .news-time-expired {
  font-size: 10px;
  padding: 10px 15px 10px 12px !important;
}

.story-list-headline {
  font-size: 15px;
  height: 54px;
  overflow: hidden;
}

.story-page-list img {
  width: 108px;
  object-fit: cover;
  object-position: center top;
}

.news-story-content-container {
  display: flex;
  padding-top: 30px;
  margin-bottom: 50px;
}

.news-story-content {
  font-size: 16px;
  width: calc(100% - 50px);
}

.news-story-content img {
  width: 100%;
}

.story-social-container {
  width: 50px;
  display: block;
  padding-right:50px;
}

.story-social-container img {
  padding-bottom: 10px;
}

.story-social-heading {
  font-size: 9pt;
  padding-bottom: 5px;
}

.info-page-heading-container {
  display: flex;
}

.info-page-nav-container {
  padding-left: 45px !important;
}

.info-submenu-item {
  padding-left: 30px;
}

.encyclopedia-submenu-item li{
  font-size: 12px;
}

.info-page-nav {
  font-size: 10pt;
  width: 100px !important;
  padding: 0 !important;
}

.info-page-nav:active {
  font-size: 10pt;
  width: 100px !important;
  padding: 0 !important;
}

.info-subpage-summary-container,
.info-subpage-summary {
  display: flex;
  min-width: 1100px;
}

.info-subpage-summary {
  padding-left: 5px;
  padding-right: 5px;
}

.most-read-news-time-expired {
  font-size: 10px;
}

.crypto-info-encyclopedia-articles-container .home-contents {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.news-page-content .home-contents {
  padding-left: 3px;
  padding-right: 3px;
}

.deals-page-content .home-contents,
.info-page-content .home-contents,
.info-subpage-cards-container .home-contents,
.user-crypto-dashboard.home-contents{
  padding-left: 5px;
  padding-right: 5px;
}

.learn-categories-container .home-contents,
.learn-page-intro{
  padding-left: 10px;
  padding-right: 10px;
}


.tou-container,
.how-funded-container {
  margin-top: 60px;
}

.tou-publish-text,
.tou-publish-container {
  text-align: end;
}

.tou-publish-container {
  padding-top: 20px;
}

.coin-portfolio-container .home-contents,
.coin-detail-container .home-contents {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.coin-portfolio-container {
  min-height: 70vh;
}

.coin-detail-summary-block .home-contents{
  display: flex;
  padding: 0;
}

.portfolio-breadcrumb .home-contents,
.coin-detail-breadcrumb .home-contents {
  display: flex;
  padding-left: 5px;
  padding-right: 5px;
}

.portfolio-transactions-breadcrumb .home-contents {
  display: flex;
  padding: 0 5px;
}

.tou-container .home-contents,
.how-funded-container .home-contents {
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-bottom: 200px;
}

.learn-page-menu-container.home-contents {
  padding-top: 20px;
}

.info-subpage-summary-content {
  width: 560px;
  padding-top: 30px;
}

.info-subpage-summary-image {
  width: 426px;
  margin-left: 80px;
}

.info-subpage-summary-image img {
  width: 85%;
  margin-top: -20px;
}

.info-subpage-heading {
  font-size: 40px;
}

.exchange-subpage-text,
.wallet-subpage-text {
  font-size: 16px;
  padding-top: 20px;
}

.info-subpage-cards {
  padding-top: 40px;
  padding-bottom: 120px;
}

.info-subpage-cards a {
  text-decoration: none;
}

.wallet-subpage-howto-heading {
  padding: 0;
  margin-bottom: 10px;
}

.wallet-subpage-guide-title {
  height: 40px;
}

.wallet-subpage-card-container {
  padding-right: 10px;
}

.info-article-howto {
  font-size: 20px;
  padding-top: 30px;
}

.info-article-content,
.learn-module-content {
  padding-left: 0 !important;
  padding-bottom: 50px;
}

.learn-module-content {
  padding-bottom: 120px !important;
}

.info-article-content-heading h1{
  font-size: 16px;
  padding-top: 30px;
}

.info-article-content-heading h2{
  font-size: 20px;
  padding-top: 30px;
}
.news-article-content-text,
.info-article-content-text {
  font-size: 16px;
  padding-top: 10px;
}

.info-article-content-text img {
  width: 100%;
  max-width: fit-content;
}

.info-article-content-text ol {
  margin-top: 20px;
}

.info-article-nav-container,
.info-article-nav-container.notfixed {
  position: unset;
}

.info-article-nav-container.fixed{
  position: fixed;
  top: 0;
}

.info-article-nav-container.bottom{
  position: absolute;
  bottom: 60px;
}

.info-article-like-container {
  display: flex;
  margin-top: 70px;
}

.info-article-like-text {
  font-size: 12px;
  padding: 10px;
}

.info-article-contents-list-heading {
  font-size: 20px;
  padding-top: 30px;
  padding-bottom: 20px;
}

.info-article-social-media-heading {
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 10px;
}

.info-article-offer {
  margin-top: 25px;
  width:226px;
  margin-bottom:20px;
  padding:3px;
}

.info-article-offer-text {
  text-align: center;
  padding-bottom: 10px ;
}

.info-article-offer-container {
  width: 226px;
  min-height: 70px;
  padding:5px;
  border-radius: 0 5px 5px 5px;
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items:center;

}
.info-article-offer-link {
  border:none;
  border-radius: 5px;
  font-size:12px;
  height:30px;
  width: fit-content;
}

.info-article-social-media img {
  padding-right: 15px;
}

.content-section-menu-item {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}

.content-section-menu-heading-indicator {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  z-index: 2;
}

.content-section-menu-heading {
  font-size: 12px;
  padding-left: 20px;
  padding-top: 3px;
  height: 16px !important;
  overflow: hidden;
  width: 300px;
  text-align: left;
}

.info-article-contents-list-container {
  position: relative;
}

.content-section-menu-connector {
  position: absolute;
  top: 19px;
  left: 7px;
  z-index: 1;
}

.user-display {
  margin-right:10px;
}

.link {
  cursor:pointer !important;
  text-decoration: none !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}


.ticker-coin-image {
  width: 16px;
  height: 16px;
  float: left;
}

.ticker-section-top-gainer {
  margin-left: -6px;
}

.ticker-section-top-loser {
  margin-left: -4px;
}

.ticker-section-top-gainer,
.ticker-section-top-loser {
  text-align: left !important;
}

.ticker-section-top-gainer,
.ticker-section-top-loser,
.ticker-24hr-label {
  font-size: 8px;
  text-decoration: none;
  text-align: right;
}

.ticker-24hr-label {
  margin-right: 0;
}

.ticker-section-container a {
  text-decoration: none;
}

.ticker-coin-name,
.ticker-coin-price,
.ticker-coin-change{
  text-decoration: none;
  float:left;
  vertical-align: middle;
  display: flex;
  overflow: hidden;
}

.ticker-change-arrow {
  padding-bottom: 10px;
}

.ticker-change-arrow img {
  vertical-align: top !important;
  padding-top: 3px;
}

.ticker-section-container {
    padding: unset !important;
    display: flex;
    width: 25% !important;
    height: 47px;
}

.ticker-section-coin {
    padding: 10px 40px 10px 40px !important;
    width: 100%;
}

.ticker-coin-name {
  padding-top: 3px;
  padding-left: 5px;
  width: 50px;
  height: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ticker-coin-name,
.ticker-coin-change {
  font-size: 10px;
}

.ticker-coin-change {
  padding-left: 4px;
  margin-left: auto;
}

.ticker-coin-price {
  font-size: 13px;
  text-align: right;
  padding-top: 1px;
  width: 80px;
  white-space: nowrap !important;
}

.ticker-coin-change-container {
  display: flex;
  padding-top: 3px;
  text-align: right;
  width: 44px;
}

.learn-page-season-container {
  display: flex;
}

.learn-page-menu-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
}

.learn-page-menu-container .page-menu-container {
  margin-left: 26px;
}

.learn-page-intro {
  display: flex;
}

.learn-page-intro-container {
  width: 555px;
}

.learn-page-intro-heading {
  padding-top: 20px;
  font-size: 70px;
  display: flex;
  margin-left: -3px;
}

.learn-page-intro-heading .orange {
  padding-left: 15px;
}

.learn-page-intro-subheading {
  font-size: 16px;
  padding-top: 20px;
}

.learn-page-intro-text {
  font-size: 16px;
  padding-top: 20px;
}

.learn-page-intro-button-container {
  display: flex;
  padding-top: 30px;
  width: 300px;
}

.learn-page-intro-button-dark {
  margin-left:20px;
}

.learn-page-hero-container {
  width: 465px;
  position: relative;
  margin-left: 100px;
}

.learn-page-hero-container img {
  width: 100%;
}

.learn-page-hero-container .video {
  position: absolute;
  left: 197px;
  top: 97px;
  width: 58px;
  height: 58px;
}

.chapter-overview-video-container {
  position: relative;
}

.chapter-overview-video-container .video {
  position: absolute;
  left: 167px;
  top: 100px;
  width: 58px;
  height: 58px;
}

.learn-categories-container {
  padding-bottom: 60px;
}

.learn-modules-container {
  padding-bottom: 150px;
}

.learn-modules-heading,
.learn-categories-heading {
  padding-top: 30px;
  padding-bottom: 10px;
  margin-bottom: 40px;
  font-size: 18px;
}

.learn-module-card-container {
  padding: 10px 15px 10px 25px !important;
}

.learn-module-card-container,
.learn-category-card-container {
  width: 100%;
  height: 256px;
}

.learn-module-cards {
  margin-bottom: 0;
}

.learn-module-card a,
.learn-category-card a {
  text-decoration: none;
}

.learn-module-card-contents{
  display: block;
}

.learn-category-card-contents {
  margin-bottom: 20px;
  padding: 20px 0 0 15px;
}

.learn-module-card-heading {
  margin: 0;
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 24px;
  height: 70.4px;
}

.learn-module-card-blurb {
  margin: 0;
  padding-bottom: 10px;
  width: 100%;
  max-height: 100px;
  font-size: 14px;
  overflow-y: hidden;
}

.learn-category-card-heading {
  display: table-cell;
  height: 56px;
  overflow: hidden;
  font-size: 25px;
  vertical-align: middle;
}

.learn-module-card-text,
.learn-category-card-text {
  font-size: 10px;
  height: 86px;
  overflow: hidden;
}

.learn-module-card-image {
  position: relative;
}


.learn-module-card-image img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: -10px;
  margin: auto;
}

.learn-module-card-button {
  font-size: 10px;
  padding: 4px 2px 4px 2px;
  position: absolute;
  left: 25px;
  bottom: 20px;
  width: 125px;
  height: 29px;
}



.learn-module-card-completed-tick {
  padding-right: 10px;
}

.learn-category-card-button {
  font-size: 10px;
  padding: 4px 2px 4px 2px;
  position: absolute;
  left: 20px;
  bottom: 20px;
  height: 29px;
  width: 125px;
}

.learn-module-completion-bar {
  position: relative;
  width: 70%;
}

.learn-module-background-bar {
  position: absolute;
  border-radius: 5px;
  height: 8px;
  padding: 3px;
  width: 100%;
  margin: 7px 14px 7px 0;
  top: 65px;
}

.info-article-progress-button {
  border: none;
  background-color: transparent;
  text-align: left;
  padding-left: 20px;
}

.info-article-progress-menu {
  padding-left: 20px;
  display: block;
}

.learn-module-completed-bar {
  position: absolute;
  border-radius: 5px;
  height: 8px;
  padding: 3px;
  margin: 7px 14px 7px 0;
  top: 65px;
}

.learn-module-completed-bar.complete {
  width: 100%;
}

.learn-category-completion-perc-container{
  position: absolute;
  width: 60px;
  left: 21px;
  top: 60px;
  height: 24px;
}

.learn-module-result-perc-container{
  position: absolute;
  width: 60px;
  left: 24px;
  top: 60px;
  height: 24px;
}

.learn-module-completion-percentage {
  font-size: 13px;
  position: absolute;
  left: 0;
  top: 50px;
}

.learn-module-trophy {
  width: calc(100% - 191px);
  text-align: end;
}

.learn-category-completion-result,
.learn-module-completion-result{
  font-size: 18px;
  text-align: center;
}

.learn-category-score-label {
  position: absolute;
  bottom: 20px;
  right: 100px;
  font-size: 12px;
}

.learn-module-card-module-score-label {
  font-size: 10px;
  position: absolute;
  bottom: 0;
  right: 6px;
}

.learn-module-card-level {
  font-size: 12px;
  padding-top: 10px;
}



.learn-module-card-text-col {
  flex: 0 0 auto;
  width: 100%;
}

.learn-module-card-top-row {
  display: flex;
}

.learn-module-card-img-col {
  flex: 0 0 auto;
  width: 35%;
}

.learn-category-card-img-col {
  flex: 0 0 auto;
  width: 100%;
  overflow: hidden;
}

.learn-category-card-img-col img {
  width: 100%;
  height: 105px;
}

.learn-category-card-time  {
  display: flex;
}

.learn-category-card-time-text  {
  font-size: 12px;
  padding-left: 10px;
  padding-top: 3px;
}

.learn-module-summary-container{
  width: 490px;
}

.learn-category-summary-container {
  width: 100%;
}

.learn-category-heading,
.learn-module-heading,
.course-page-title {
  font-size: 40px;
  padding-top: 25px;
  width: calc(100% - ((165px + 10px) + (20px)));
}

.learn-category-subheading {
  font-size: 18px;
  padding-top: 25px;
}

.course-page-about {
  font-size: 22px;
  padding-top: 20px;
}

.learn-module-card-completion-time {
  display: flex;
}

.learn-module-card-completion-time img {
  margin-top: 7px;
  width: 17px;
}

.learn-module-card-completion-value {
  font-size: 12px;
  padding-top: 10px;
  padding-left: 10px;
  width: 80px;
}

.learn-module-completion-metrics {
  width: 100%;
  display: flex;
  margin-top: 10px;
}

.course-page-subtitle {
  font-size: 16px;
  padding-top: 30px;
}

.learn-category-text,
.learn-module-text {
  font-size: 16px;
  padding-top: 20px;
}

.learn-category-column {
  display: flex;
}

.learn-category-content-areas {
  margin-top: 7px;
  display: flex;
}

.learn-category-content-area-container {;
  border-radius: 5px;
  height: 20px;
  padding: 5px 5px 5px 5px;
  margin: 7px 7px 7px 0;
}

.learn-category-content-area {
  padding: 0 10px 2px 10px;
  font-size: 12px;
}

.learn-category-description-column {
  width: 686px;
}

.learn-category-learn-areas-column {
  width: 375px;
  padding-left: 40px;
}

.learn-module-summary-column {
  display: flex;
}

.learn-module-menu {
  font-size: 18px;
  padding-bottom: 10px;
  margin-top: -65px;
  margin-bottom: 30px;
}

.info-card-image {
  position: relative;
}

.info-card-image img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: -10px;
  right: 0;
  margin: auto;
  width: 120px;
}

.course-page-summary {
  display: flex;
  padding-bottom: 10px;
  z-index: 2;
  position: relative;
  width: 100%;
}

.hidden,
.pagehidden {
  display: none;
}

.course-page-block {
  width: 100%;
  position: relative;
}

.course-page-hero {
  width: 390px;
}

.course-page-hero img {
  display: block;
  margin: auto;
  width: 394px;
  height: 259px;
}

.course-content-container {
  position: relative;
  z-index: 2;
  margin-top: 20px;
}

.course-content-block,
.profile-content-block{
  display: flex;
  width: 100%;
}

.course-content-menu {
  width: 200px
}

.coin-nav-bar-container {
  display: flex;
}


.course-content-menu {
  font-size: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.course-section-list {
  padding-top: 30px;
  min-width: 350px;
  max-width: 350px;
  padding-bottom: 120px;
}

.profile-section-list {
  padding-top: 5px;
  min-width: 250px;
  max-width: 250px;
  padding-bottom: 150px;
}

.course-section-list-small {
  padding-top: 30px;
  min-width: 51px;
  max-width: 51px;
}

.hide-chapters-menu-button,
.chapter-listen-button {
  background:none;
  border:none;
  margin:0;
  padding:0;
  display: flex;
}

.hide-chapters-menu-button img{
  padding-left: 20px;
  padding-top: 5px;
}

.chapter-listen-button-text {
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-right: 10px;
}

.course-section-block {
  padding-left: 50px;
  max-width: 1000px;
  min-width: 700px;
  width: 1000px;
  padding-bottom: 120px;
}

.course-section-block-heading {
  font-size: 20px;
  padding-bottom: 30px;
  padding-top: 30px;
}

.course-section-card{
  height: 50px;
  margin-bottom: 10px;
  position: relative;
}

.profile-section-card{
  height: 42px;
  margin-bottom: 10px;
  position: relative;
}

.course-section-card-text {
  padding-top: 18px;
  padding-left: 40px;
  max-width: 230px;
  font-size: 12px;
  height: 31px;
  overflow: hidden;
}

.profile-section-card-text {
  padding-top: 12px;
  padding-left: 20px;
  max-width: 230px;
  font-size: 14px;
  height: 34px;
  overflow: hidden;
}

.course-section-card-indicator {
  margin-right: 0;
  margin-left: auto;
  padding-right: 20px;
  height: 50px;
  width: 50px;
  position: relative;
}

.chapter-score-text {
  position: absolute;
  float: right;
  top: 18px;
  right: 48px
}

.chapter-score-text {
  font-size: 14px;
}

.completed-score-merit {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  float: right;
  top: 15px;
  right: 90px
}

.chapter-progress-indicator {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.chapter-listen-button-container {
  margin-left: 80%;
  width: 20%;
  height: 20px;
  position: relative;
}

.chapter-listen-button {
  position: absolute;
  right: 10px;
  margin-top: 10px;
}

.course-section-opener,
.course-section-opener-large {
  font-size: 16px;
  min-width: 698px;
  min-height: 20px;
  padding: 10px 10px;
  border-radius: 10px;
}

.course-section-block-text-large,
.course-section-block-text {
  font-size: 16px;
  min-width: 698px;
  min-height: 20px;
  padding: 10px 20px;
  border-radius: 10px;
}

.course-section-block-text {
  word-wrap: break-word;
}

.course-section-block-text-large h2,
.course-section-block-text h2,
.course-section-block-text-large h3,
.course-section-block-text h3{
  font-size: 16px;
}

.chapter-section-type-image {
  position: absolute;
  top: 15px;
  left: 10px;
}

.course-section-block-text iframe{
  width: 699px;
  height: 394px;
}

.course-section-block-text img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
}

.course-section-block-text-large iframe{
  width: 1000px;
  height: 563px;
}

.course-section-block-button {
  width: 140px;
}

.course-section-take-quiz {
  min-width: 700px;
  padding-top: 60px;
  padding-bottom: 120px;
}


.course-section-take-quiz-button {
  float: left;
  margin-left: 35%;
}

.course-section-take-quiz-button {
  width: 183px;
  height: 42px;
  font-size: 14px;
  text-align: center;
  overflow: hidden;
  border: none;
  border-radius: 3px;
  padding: 0 2px 0 2px;
}

.coin-page-nav-container {
  display: flex;
}

.coin-page-heading {
  padding-left: 10px;
  padding-top: 30px;
}

.trending-card-container,
.gainer-card-container,
.loser-card-container {
  padding-top: 20px;
}

.trending-card-button {
  border: none;
  border-radius: 3px;
  font-size: 10px;
  padding: 6px 20px;
  float: left;
  position: absolute;
  left: 30px;
  bottom: 20px;
}

.coin-grid-button {
  border: none;
  border-radius: 3px;
  font-size: 10px;
  padding: 6px 20px;
  margin-top: -4px;
}

.trending-card-button:hover,
.coin-grid-button:hover,
.login-button:hover,
.recovery-page-mailapp-button:hover {
  transition: all 0.3s ease-in-out;
  transition-property: box-shadow;
}

.coins-subpage-contents {
  margin-top: 30px;
  position: relative;
}

.coin-grid-container,
.market-data-grid-container{
  margin-top: 30px;
}

.coin-grid-header,
.market-data-grid-header,
.transaction-grid-header {
  display: flex;
  font-size: 12px;
  padding-bottom: 10px;
}

.transaction-grid-header.row {
  padding-bottom: 15px !important;
}

.coin-grid-header .number {
  width: 50px;
  text-align: end;
}

.coin-grid-header .coin {
  width: 350px;
  padding-left: 10px;
}

.coin-grid-header .price,
.coin-grid-row .price {
  width: 140px;
  text-align: end;
}


.coin-grid-header .price-portfolio{
  width: 100px !important;
  text-align: end;
}

.coin-grid-header .coin-portfolio{
  width: 200px !important;
}

.coin-grid-header .market-cap,
.market-data-grid-header .market-cap{
  width: 220px;
  text-align: end;
  position: relative;
  padding-right: 10px;
}

.coin-grid-header .hour,
.coin-grid-header .day {
  width: 100px;
  text-align: end;
}

.coin-grid-header .chart {
  width: 140px;
  text-align: end;
  padding-right: 30px;
}

.coin-grid-header .holdings {
  width: 200px;
  text-align: end;
}

.coin-grid-header .profitLoss{
  width: 150px;
  text-align: end;
}

.coin-grid-header .actions {
  width: 150px;
  text-align: end;
}


.market-data-grid-header .number {
  width: 40px;
  text-align: start;
}

.market-data-grid-header .exchange {
  width: 200px;
  text-align: start;
}

.market-data-grid-header .pairs {
  width: 120px;
  text-align: start;
}

.market-data-grid-header .price,
.market-data-grid-header .plus-depth,
.market-data-grid-header .minus-depth,
.market-data-grid-header .day-volume{
  width: 144px;
  text-align: end;
}

.market-data-grid-header .spread,
.market-data-grid-header .perc-volume {
  width: 80px;
  text-align: end;
}


.portfolio-coin-grid-row-container,
.coin-grid-row-container,
.market-data-row-container{
  width: 100%;
}

.coin-grid-row {
  height: 50px;
  display: flex;
  font-size: 14px;
  align-items: center;
  margin-left: 20px;
}

.market-data-grid-row{
  height: 50px;
  display: flex;
  font-size: 14px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 20px;
}

.coin-grid-single-row-container {
  position: relative;
}

.coin-grid-row-star {
  width: 20px;
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;
}

.coin-grid-row-star-container {
  position: absolute;
  left: 0;
  top: 18px;
  z-index: 10;
}

.coin-grid-row .number {
  width: 30px;
  text-align: end;
  padding-left: 0;
  padding-right: 0;
}

.coin-grid-row .coin {
  width: 350px;
  padding-left: 10px;
  padding-right: 0;
  display: flex;
  align-items: center;
}

.portfolio-coin-search {
  display: flex;
  width: 100%;
}

.portfolio-coin-search img {
  width: 29px;
  height: 29px;
}

.portfolio-coin-search-name,
.portfolio-coin-search-symbol {
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.coin-grid-row .search {
  margin: 0 20px 0 0 !important;
}

.coin-grid-row .coin img {
  height: 24px;
  width: 24px;
}

.coin-grid-row .coin .name {
  font-size: 14px;
  padding-left: 20px;
  width: 150px;
}

.coin-grid-row .price,
.coin-grid-row .market-cap {
  font-size: 14px;
}

.coin-grid-row .price,
.coin-grid-row .hour,
.coin-grid-row .day {
  width: 140px;
  text-align: end;
  padding-left: 0;
  padding-right: 0;
}

.coin-grid-row .market-cap {
  width: 220px;
  text-align: end;
  padding-left: 0;
  padding-right: 0;
}

.coin-grid-row .hour,
.coin-grid-row .day {
  width: 105px;
  text-align: end;
  padding-left: 0;
  padding-right: 0;
}

.coin-grid-row .chart {
  width: 140px;
  text-align: end;
  padding-right: 10px;
  padding-left: 0;
}

.market-data-grid-row .number {
  width: 40px;
  text-align: start;
  padding-left: 0;
  padding-right: 0;
}

.market-data-grid-row .exchange {
  width: 200px;
  text-align: start;
  padding-left: 0;
  padding-right: 0;
  display: flex;
}

.market-data-grid-row .exchange-logo {
  width: 22px;
  height: 22px;
  margin-right: 10px;
  margin-top: -4px;
}

.market-data-grid-row .pairs {
  width: 120px;
  text-align: start;
  padding-left: 0;
  padding-right: 0;
}

.market-data-grid-row .price,
.market-data-grid-row .plus-depth,
.market-data-grid-row .minus-depth,
.market-data-grid-row .day-volume{
  width: 144px;
  text-align: end;
  padding-left: 0;
  padding-right: 0;
}

.market-data-grid-row .spread,
.market-data-grid-row .perc-volume{
  width: 80px;
  text-align: end;
  padding-left: 0;
  padding-right: 0;
}

.coin-grid-footer-container {
  margin-top: 80px;
  padding-bottom: 120px;
  width: 100%;
  position: relative;
}

.coin-grid-footer-contents {
  margin: auto;
  width: fit-content;
}

.coin-grid-footer-contents,
.coin-grid-footer-pages-container {
  display: flex;
}

.coin-grid-footer-contents button {
  font-size: 10px;
  height: 21px;
  width: 51px;
  border: none;
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 1px;
}

.coin-grid-footer-contents button img {
  height: 10px;
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 2px;
}

.coin-grid-footer-pages-container {
  font-size: 14px;
}

.coin-grid-footer-pages-container .page {
  width: 24px;
  padding-top: 3px;
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
}

.coin-grid-footer-pages-container .page.active {
  border-radius: 5px;
}

.coin-grid-footer-showing {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
}

.coin-page-heading-container {
  z-index: 2;
  position: relative;
}

.coin-subpage-cards {
  padding-top: 30px;
  padding-left: 5px;
}

.coin-subpage-menu {
  position: relative;
}

.slider-container {
  position: relative;
}

.slider-background {
  position: absolute;
}

.slider-button-on {
  position: absolute;
  top: 2px;
  left: 22px;
  transition: all 0.3s ease-in-out;
}

.slider-button-off {
  position: absolute;
  top: 2px;
  left: 2px;
  transition: all 0.3s ease-in-out;
}

.coin-page-show-rows {
  display: flex;
  position: absolute;
  top: 0;
  right: 10px;
}

.coin-page-show-rows-text {
  font-size: 12px;
  padding-top: 7px;
}

.coin-page-top100 img {
  padding-left:10px;
}

.coin-page-top100 {
  display: flex;
  position: absolute;
  top: 5px;
  right: 190px;
}

.coin-page-top100-text {
  font-size: 12px;
  padding-top: 3px;
}

.portfolio-breadcrumb,
.coin-detail-breadcrumb,
.portfolio-transactions-breadcrumb {
  padding-left:10px;
}

.coin-detail-action {
  height: 16px;
  margin-top:6px;
}

.coin-detail-action img{
  height: 16px;
}

.coin-detail-summary {
  display: block;
  margin-top: 30px;
  width: 550px;
}

.coin-detail-stats{
  margin-top: 30px;
  width: 550px;
}

.coin-detail-summary img {
  padding-left: 10px;
}

.coin-detail-summary-block {
  display: flex;
  margin-bottom: 70px;
}

.coin-detail-summary-logo {
  height: 30px;
}

.coin-detail-summary-name {
  font-size: 20px;
  width: 150px;
  padding-top: 5px;
  padding-left: 10px;
}

.coin-detail-summary-ticker {
  font-size: 12px;
  width: 50px;
  padding-top: 10px;
}

.coin-detail-summary-header {
  display: flex;
}

.coin-detail-summary-price-block,
.coin-detail-summary-papers-block {
  display: flex;
  padding-left: 10px;
  padding-top: 20px;
}

.coin-detail-summary-price-block .coin-text-up,
.coin-detail-summary-price-block .coin-text-down {
  font-size: 18px;
  padding-top: 6px;
}

.coin-detail-summary-price-block img {
  margin-top: 12px;
}

.coin-detail-summary-price {
  font-size: 40px;
}

.coin-detail-summary-pair {
  font-size: 18px;
  padding-left: 12px;
}

.coin-detail-summary-whitepaper,
.coin-detail-summary-web{
  font-size: 12px;
  padding-left: 20px;
  padding-top: 5px;
}

.coin-detail-summary-whitepaper img,
.coin-detail-summary-web img {
  padding-right: 10px;
}

.coin-detail-summary-web a:visited,
.coin-detail-summary-web a:hover,
.coin-detail-summary-web a:link,
.coin-detail-summary-web a:active,
.coin-detail-summary-whitepaper a:visited,
.coin-detail-summary-whitepaper a:hover,
.coin-detail-summary-whitepaper a:link,
.coin-detail-summary-whitepaper a:active {
  text-decoration: none;
  font-size: 12px;
}

.coin-detail-summary-rank {
  font-size: 10px;
  border: none;
  border-radius: 5px;
  height: 22px;
  width: 80px;
  text-align: center;
  padding-top: 5px;
}

.coin-detail-stats-header {
  font-size: 20px;
}

.coin-detail-stats-block {
  display: flex;
  padding-left: 20px;
  padding-top: 20px;
}

.coin-detail-stats-block .col {
  padding-left: 0 !important;
}

.coin-detail-stats-info {
  font-size: 12px;
}

.coin-detail-stats-info img {
  padding-left:20px;
  margin-top: -15px;
  margin-right: -5px;
}

.coin-detail-stats-text {
  font-size: 15px;
  padding-top: 10px;
  padding-bottom: 20px;
  width: 50%;
}

.coin-search-grid-single-row-container {
  position: relative;
  width: 100%;
  padding: 10px 0;
}

.coin-detail-stats-block .coin-price-change {
  padding-top: 12px;
  width: 50%;
}

.coin-detail-stats-block .separator {
  display: flex;
}

.coin-detail-contents {
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
}

.coin-data-analysis-block {
  display: flex;
  padding-top: 30px;
  padding-bottom: 50px;
}

.coin-data-analysis-wide {
  width: 66.666%;
}

.coin-data-analysis-narrow {
  width: 33.333%;
}

.coin-data-analysis-narrow.mobile {
  display: none;
}

.coin-data-analysis-chart {
  padding-top: 10px;
  padding-right: 10px;
}

.coin-data-analysis-technical {
  height: 450px;
  margin-top: 10px;
}

.coin-data-statistics-container {
  margin-top: 10px;
  height: fit-content;
  padding: 20px;
  display: block;
}

.coin-data-statistics-heading {
  font-size: 20px;
}

.coin-data-statistics-subheading {
  font-size: 12px;
  padding-top: 10px;
}

.coin-data-statistics-row {
  display: flex;
  padding-top: 20px;
  padding-bottom: 12px;
}


.coin-data-statistics-row .left-col,
.coin-data-statistics-row .right-col{
  width: 50%;
}

.right-col .block {
  display: flex;
  margin-right: 0;
  margin-left: auto;
  width: fit-content;
}

.coin-data-statistics-text {
  font-size: 12px;
}

.coin-data-statistics-value {
  font-size: 14px;
  text-align: right;
}

.coin-data-analysis-description {
  padding-top: 30px;
  padding-right: 50px;
  font-size: 14px;
}

.coin-data-analysis-description h1 {
  font-size: 20px;
  padding-bottom: 10px;
}

.coin-data-analysis-vote-container {
  padding-right: 10px;
  padding-top: 10px;
  width: 100%;
}

.coin-data-analysis-vote {
  height: 70px;
  width: 100%;
  padding: 20px;
  margin-top: 10px;
  font-size: 12px;
}

.coin-data-analysis-vote .heading {
  font-size: 14px;
  padding-bottom: 5px;
}

.coin-data-analysis-vote-text {
  width: 70%;
}

.coin-data-analysis-vote-choose {
  width: 30%;
  display: flex;
  padding-left: 60px;
}

.coin-data-analysis-vote-choose button{
  width: 29px;
  height: 29px;
  font-size: 12px;
  background-color: transparent !important;
  border: none;
  border-radius: 15px !important;
}

.coin-data-analysis-voted-text {
  width: fit-content !important;
  margin-right: 20px !important;
}

.coin-data-analysis-voted-text,
.coin-data-analysis-voted-choice {
  font-size: 14px;
}

.coin-data-analysis-result-text,
.user-react-result {
  padding-top: 8px;
}

.coin-data-analysis-voted-choice {
  width: fit-content;
}

.coin-data-analysis-voted-choice.div {
  display: flex;
  margin-right: 20px;
}

.coin-data-analysis-voted-choice img {
  padding-right: 10px;
  margin-top: -2px;
}

.coin-data-analysis-voted-result {
  width: 65%;
  font-size: 10px;
  margin-right: 10px;
}

.coin-data-analysis-voted-result.left {
  text-align: left;
}

.coin-data-analysis-voted-result.right {
  text-align: left;
}

.coin-data-analysis-vote-result-slider {
  margin-left: 7px;
  height: 10px;
  border-radius: 5px;
  width: 97% !important;
  margin-top: 3px !important;
  margin-bottom: 3px;
}

.coin-data-analysis-vote-result-slider-overlay {
  margin-left: 7px;
  height: 10px;
  border-radius: 5px;
  width: 0 !important;
  margin-top: -13px !important;
  margin-bottom: 3px;
  padding: 0 !important;
}

.login-container {
  border: none;
  border-radius: 11px;
  display:flex;
  overflow: clip;
  overflow-x:hidden;
  margin: 20px auto;
  width: 450px;
  padding: 20px 0;
}

.login-page,
.register-page,
.recovery-page {
  margin-bottom: 80px;
}

.login-action-container {
  margin: 20px auto;
  width: 450px;
  height: 50px;
}

.login-contents {
  width: 100%;
  padding: 10px 40px 10px 40px;
}

.login-contents .row {
  padding-top: 10px;
}

.login-contents input {
  padding: 15px;
  width: 100%;
}

.login-heading {
  font-size: 15px;
  text-align: center;
}

.login-subheading {
  font-size: 13px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.login-text {
  font-size: 14px;
  padding-top: 3px;
}

.login-input {
  font-size: 14px;
  padding: 3px;
  border-radius: 3px;
}

.login-action-text {
  width: 100% !important;
  margin: auto;
  display: block;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 25px;
  font-size: 13px;
}

.login-action-button {
  margin: auto;
}

.login-action-text a {
  text-decoration: none;
}



.col-sm-4 {
  padding-bottom: 20px;
}

.learning-objectives-container {
  column-count: 3;
  column-gap: 28px;
  padding-bottom: 120px;
}

.learning-objectives-title-container {
  padding-top: 40px;
  padding-bottom: 40px;
}

.learning-objectives-title {
  font-size: 22px;
}

.learning-objective-component {
  display: flex;
  padding-bottom: 20px;
  padding-right: 5px;
}

.learning-objective {
  padding-top: 4px;
  font-size: 16px;
}

.learning-objective-tick {
  padding-right: 20px;
}


.quiz-background {
  display: block;
  width: 100%;
  background-size: 50% calc(50% + 1px);
  background-repeat: no-repeat;
  min-height: 100vh;
  height: 100%;
  padding-bottom: 150px;
}

.quiz-background-dark-triangle {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  margin-left: 600px;
  overflow-y: hidden;
  z-index: 1;
}

.quiz-background-dark-square {
  position: absolute;
  top: 0;
  left: 0;
  width: 600px;
  min-height: 100%;
  z-index: 1;
}

.quiz-page-header-container {
  display: flex;
  padding-top: 10px;
  margin-left: 8%;
  margin-right: 8%;
  z-index: 10;
}

.quiz-page-cse-logo {
  margin-top: 2%;
  width: 8%;
  min-width: 129px;
  z-index: 10;
}

.quiz-page-breadcrumbs-container {
  position: relative;
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

.quiz-page-bc-separator,
.quiz-page-chapter-bc,
.quiz-page-module-bc {
  z-index: 11;
}

.quiz-page-bc-separator {
  padding-left: 20px;
  padding-right: 20px;
}


.news-story-subheading,
.news-story-subheading a:visited,
.news-story-subheading a:hover,
.news-story-subheading a:link,
.news-story-subheading a:active,
.quiz-page-module-bc,
.quiz-page-module-bc a:visited,
.quiz-page-module-bc a:hover,
.quiz-page-module-bc a:link,
.quiz-page-module-bc a:active,
.quiz-page-chapter-bc,
.quiz-page-chapter-bc a:visited,
.quiz-page-chapter-bc a:hover,
.quiz-page-chapter-bc a:link,
.quiz-page-chapter-bc a:active,
.quiz-page-bc-separator {
  text-decoration: none;
  font-size: 18px;
}


.quiz-exit-header-button-container {
  margin-top: 3%;
  right: 0;
}

.quiz-exit-header-button {
  padding-left: 5px;
  display: flex;
  width: 129px;
  height: 28px;
  border: none;
  border-radius: 3px;
}

.quiz-exit-header-button img{
  width: 7px;
  height: 7px;
  margin-top: 10px;
}

.quiz-exit-header-button-text {
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 10px;
}

.quiz-page-body-area {
  min-width: 846px;
  width: 846px;
  min-height: 70vh;
  margin-left: calc((100vw - 846px) /2);
  margin-right: calc((100vw - 846px) /2);
  border: none;
  border-radius: 10px;
  position: sticky;
  margin-top: 60px;
  z-index: 10;
}

.quiz-start-page-title {
  font-size: 25px;
  padding-top: 80px;
  text-align: center;
}

.quiz-start-page-question-count {
  font-size: 18px;
  text-align: center;
  padding-top: 12px;
}

.quiz-start-page-description {
  width: 100%;
  text-align: center;
  font-size: 14px;
}

.quiz-start-page-description-container {
  margin-left: 18%;
  margin-right: 18%;
  padding-top: 40px;
}

.quiz-start-button-container {
  width: 100%;
  align-items: center;
  margin-top: 50px;
}

.quiz-start-button {
  margin: auto;
  padding-left: 5px;
  display: flex;
  width: 135px;
  height: 34px;
  border: none;
  border-radius: 3px;
}

.quiz-start-button img{
  width: 10px;
  height: 10px;
  margin-left: 10px;
  margin-top: 2px;
}

.quiz-start-button {
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 8px;
  font-size: 12px;
}

.quiz-return-button-container {
  width: 100%;
  align-items: center;
  margin-top: 35px;
}

.quiz-return-button {
  margin: auto;
  display: flex;
  width: 135px;
  height: 34px;
  border-radius: 3px;
}

.quiz-return-button img{
  width: 10px;
  height: 10px;
  margin-left: 5px;
  margin-top: 2px;
}

.quiz-return-button {
  padding-top: 10px;
  padding-right: 8px;
  font-size: 12px;
}

.quiz-return-button-text {
  margin-left: 7px;
}

.results-container,
.quiz-progress-bar-container {
  width: 100%;
  height: 30px;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  margin: auto;
 }

.quiz-individual-result-progress-container,
.quiz-individual-question-progress-container {
  margin: auto;
  display: flex;
  width: 100%;
  justify-content: center;
}

.results-progress-bar {
  margin-right: 10px;
  width: 67px;
  height: 7px;
  border: none;
  border-radius: 3px;
}



.questions-progress-bar {
  background: none;
  margin-right: 10px;
  max-width: 67px;
  width: 100%;
  min-width: 10px;
  height: 7px;
  border: none;
  border-radius: 3px;
}

.quiz-questions-page-navigation-buttons {
  display: flex;
  position: absolute;
  width: 100%;
  height: 40px;
  bottom: 20px;
  margin-top: 60px;
}

.quiz-submit-button-locked,
.quiz-submit-button,
.quiz-next-button-container {
  position: absolute;
  right: 5%;
  bottom: 10px;
}

.quiz-previous-button-container {
  position: relative;
  left: 5%;
  bottom: 10px;
}

.quiz-submit-button-locked,
.quiz-submit-button,
.quiz-previous-button,
.quiz-next-button {
  padding-left: 5px;
  display: flex;
  width: 140px;
  font-size: 12px;
  height: 34px;
  border: none;
  border-radius: 3px;
}

.quiz-submit-button img,
.quiz-submit-button-locked img,
.quiz-previous-button img,
.quiz-next-button img{
  width: 10px;
  height: 10px;
  margin-left: 10px;
  margin-top: 2px;
  margin-right: 10px;
}

.quiz-submit-button-locked,
.quiz-submit-button,
.quiz-previous-button,
.quiz-next-button {
  padding-top: 10px;
  padding-right: 8px;
  font-size: 12px;
}


.quiz-next-button {
  padding-left: 50px;
}

.quiz-submit-button-locked,
.quiz-submit-button {
  text-align: center;
  padding-left: 25px;
}


.quiz-question-progress-fraction {
  padding-top: 30px;
  padding-right: 8px;
  font-size: 18px;
  text-align: center;
}

.quiz-question {
  padding-top: 30px;
  font-size: 18px;
  text-align: center;
  padding-left: 50px;
  padding-right: 50px;
  max-height: 145px;
  overflow-y: hidden;
}

.quiz-question-options-container {
  display: block;
  padding-top: 10px;
  min-height: 64%;
  overflow-y: hidden;
  padding-bottom: 100px;
}

.quiz-question-container {
  min-height: 80px;
}

.result-image-container.pass img{
  width: 16%;
  margin-left: 42%;
  margin-top: 50px;
}

.result-image-container.fail img{
  width: 16%;
  margin-left: 42%;
  margin-top: 25px;
}

.result-image-container.merit {
  margin: 5% auto 0 auto;
  width: fit-content;
}

.quiz-result-users-score-message {
  font-size: 22px;
  margin: auto;
  text-align: center;
  width: 100%;
}

.quiz-result-users-score-message.merit {
  width: 50% !important;
}

.quiz-result-users-score-label {
  font-size: 14px;
  margin: auto;
  text-align: center;
  width: 100%;
  padding-top: 10px;
}

.quiz-result-users-score {
  font-size: 22px;
  margin: auto;
  text-align: center;
  width: 100%;
  padding-top: 10px;
}

.quiz-results-generic-message {
  font-size: 14px;
  margin: auto;
  text-align: center;
  width: 80%;
  padding-top: 10px;
}

.quiz-results-page-brush-up-message {
  font-size: 14px;
  margin: auto;
  text-align: center;
  width: 80%;
}

.quiz-results-page-brush-up-container {
  width: 100%;
  max-height: 300px;
  margin-top: 21px;
  overflow-y: hidden;
  overflow-x: hidden;
}

.quiz-results-page-brush-up-area-container {
  margin: 10px;
  text-align:center;
  position:relative;
  width: 100%;
}

.quiz-back-to-course-button {
  padding-left: 20px;
  padding-top: 5px;
  display: flex;
  width: 154px;
  height: 35px;
  border: none;
  border-radius: 3px;
}

.quiz-back-to-course-button img{
  width: 10px;
  height: 10px;
  margin-top: 7px;
  margin-right: 2px;
  transform: rotate(180deg);
}

.quiz-back-to-course-button-text {
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 2px;
  font-size: 11px;
}

.quiz-continue-course-button {
  padding: 5px 20px;
  display: flex;
  height: 35px;
  border: none;
  border-radius: 3px;
}

.quiz-continue-course-button img{
  width: 10px;
  height: 10px;
  margin-top: 7px;
  margin-left: 0;
}

.quiz-continue-course-button-text {
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 11px;
}

.quiz-retake-button {
  padding-top: 5px;
  padding-left: 10px;
  display: flex;
  width: 154px;
  height: 35px;
  border-radius: 3px;
}

.quiz-retake-button img{
  padding-top: 2px;
  width: 15px;
  margin-left: 15px;
}

.quiz-retake-button-text {
  padding-top: 8px;
  padding-left: 20px;
  padding-right: 0;
  font-size: 11px;
}

.learn-module-card-progress-ring-container {
  position: relative;
  display: flex;
  padding-top: 120px;
  padding-left: 100px;
  bottom: 10px;
  left: 2px;
}

.learn-category-card-progress-ring-container {
  position: relative;
  width: 100px;
  height: 120px;
  margin-top: 20px;
  margin-right: 5px;
}

.learn-module-card-progress-ring-background {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
}

.progress-ring-circle {
  transition: 0.35s stroke-dashoffset;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.progress-ring {
  position: absolute;
  right: -2px;
  padding-bottom: 2px;
}

.encyclopedia-search-box-container input[type=text] {
  font-size: 10px;
  padding: 7px 5px 7px 30px;
  width: 100%;
}

.encyclopedia-search-box-container {
  position: relative;
  width: 250px;
  height: 30px;
}

.encyclopedia-search-box-container img {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 7px;
  padding-left: 7px;
}

.encyclopedia-subpage-summary-container {
  margin-top: 40px;
  margin-left: 8px;
}

.encyclopedia-subpage-heading {
  font-size: 40px;
}

.encyclopedia-subpage-text {
  font-size: 16px;
  margin-top: 10px;
}

.encyclopedia-subpage-cards-container {
  margin-top: 40px ;
}

.encyclopedia-page-letters {
  font-size: 120px;
  width: 100%;

}

.encyclopedia-page-word-container {
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;

  width: 100%;
  height: 160px;
}



.encyclopedia-thumbnail-image {
  width: 286px;
  height: 165px;
}

.encyclopedia-word-title-container {
  padding: 0 10px 5px 0;
  width: fit-content;
}

.encyclopedia-title {
  font-size: 25px;
}

.encyclopedia-word-description-container {
  padding: 10px 60px 5px 0;
  width: 800px;
}

.encyclopedia-description {
  font-size: 16px;
}



.encyclopedia-article-heading {
  margin-top: 40px;
  font-size: 40px;
  margin-bottom: 20px;
  overflow-wrap: anywhere;
}

.encyclopedia-body-content {
  margin-top: 20px;
  font-size: 16px;
  margin-bottom: 20px;
}

.encyclopedia-body-content h1{
  margin-top: 40px;
  font-size: 25px;
  margin-bottom: 20px;
}

.encyclopedia-body-content img {
  width: 100%
}

.disabled-learn-category-card {
  pointer-events: none;
  display: block;
}

.learn-category-completion-metrics,
.learn-category-card-contents-container {
  display: flex;
}

.learn-category-completion-metrics {
  width: 30%;
}

.learn-category-card-contents {
  width: 100%;
}

.learn-category-modules-completed-label {
  font-size: 12px;
  position: absolute;
  width: 200px;
  top: 50%;
  right: 5px;
}

.learn-category-modules-completed {
  font-size: 18px;
  position: absolute;
  top: 48%;
  right: 15px;
}

.crypto-dashboard {
  display: flex;
  padding-left: 5px;
  padding-right: 5px;
}

.crypto-dashboard-intro-container {
  width: 35%;
}

.crypto-dashboard-heading {
  padding-top: 20px;
  font-size: 40px;
  margin-left: -2px;
}

.crypto-dashboard-welcome-message {
  padding-top: 20px;
  font-size: 20px;
  width: 143px;
}

.crypto-dashboard-welcome-user {
  padding-left: 0;
  padding-top: 20px;
  font-size: 20px;
}

.crypto-dashboard-welcome-container {
  display: flex;
}

.crypto-dashboard-intro-text {
  padding-top: 20px;
  font-size: 16px;
}

.crypto-dashboard-progress-container {
  display: flex;
  width: 625px;
  height: 250px;
  margin-left: 8%;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.crypto-dashboard-progress-container-left {
  width: 421px;
  height: 100%;
  border-bottom-left-radius: 15px;
}

.crypto-dashboard-progress-container-right {
  width: 204px;
  height: 100%;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  display: flex;
}


.crypto-dashboard-progress-wheel-container {
  border-radius: 50%;
  margin-top: 2%;
  margin-left: -36%;
  height: 96%;
  width: 110%;
  position: relative;
}

.crypto-dashboard-progress-wheel {
  width: 350px;
  height: 350px;
  stroke-width: 12px;
  border-radius: 10px;
  stroke-linecap: round;
  margin-left: -74px;
  margin-top: -30px;
}

.crypto-dashboard-progress-wheel-level {
  position: absolute;
  left: 45%;
  top: 18%;
  font-size: 15px;
}

.crypto-dashboard-progress-wheel-view_rockets {
  position: absolute;
  left: 41.5%;
  top: 78%;
  font-size: 10px;
  padding-bottom: 2px;
  border-bottom: 1px solid #FFFFFF
}

.crypto-dashboard-profile-picture-container {
  display: block;
  position: relative;
  width: 90px;
  height: 78px; /* width * 0.866 */
  -webkit-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
  -moz-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
}

.crypto-dashboard-profile-picture-mask {
  position: absolute;
  width: 86px;
  height: 74px;
  top: 2px;
  left: 2px;
  -webkit-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
  -moz-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
}

.crypto-dashboard-profile-picture {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
  -moz-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
}

.crypto-dashboard-profile-picture-blank {
  width: 42% !important;
  height: 50% !important;
  margin-top: 20%;
  margin-left: 30%;
}

.crypto-dashboard-profile-picture-container {
  margin-top: 20px;
  margin-left: 20px;
}

.crypto-dashboard-progress-container-row {
  display: flex;
}

.crypto-dashboard-profile-name {
  margin-top: 22px;
  font-size: 18px;
  margin-left: 10px;
}

.crypto-dashboard-edit-profile {
  margin-top: 5px;
  font-size: 10px;
  margin-left: 10px;
  text-decoration: underline;
}

.crypto-dashboard-progress-container-merits-complete-container{
  display: flex;
  margin-top: 10px;
  margin-left: 10px;
}

.crypto-dashboard-progress-container-merits-complete-container .merit{
  height: 25px;
  width: 15px;
}

.crypto-dashboard-progress-container-merits-complete-score,
.crypto-dashboard-progress-container-merits-complete-label{
  margin-top: 8px;
  margin-left: 8px;
  font-size: 14px;
}

.crypto-dashboard-progress-modules-complete-label {
  display: flex;
  margin-left: 30px;
  margin-top: 85px;
}

.crypto-dashboard-progress-modules-completed {
  font-size: 14px;
}

.crypto-dashboard-progress-completed-level-modules{
  display: flex;
  margin-left: 25px;
}

.crypto-dashboard-progress-completed-module,
.crypto-dashboard-progress-completed-module-complete {
  width: 58px;
  height: 24px;
  margin-top: 5px;
  margin-left: 2px;
  margin-right: 2px;
}

.crypto-dashboard-progress-completed-module-tick {
  height: 100%;
  width: 40%;
  margin: auto;
}

.crypto-dashboard-progress-completed-module-tick img {
  height: 60%;
  margin-top: 5px;
}

.crypto-dashboard-progress-completed-module-number {
  margin-top: 3px;
  font-size: 18px;
  text-align: center;
}

.crypto-dashboard-progress-wheel-rocket {
  position: absolute;
  left: 42%;
  top: 24%;
}

.crypto-dashboard-progress-wheel-rocket img {
  margin-left: -30px;
  width: 120px;
  height: 120px;
}

.small-header {
  display: none;
}

.large-header {
  display: flex;
  padding: 0;
  width: 100%;
  margin: auto;
  justify-content: space-evenly;
}

.menu-scroll-indicator {
  display: none;
}

.nav-search {
  display: flex;
}

.nav-menu {
  display: none;
}


.search-page-submenu-heading {
  font-size: 18px;
}

.search-page-coin-info {
  display: flex;
  height: 53px;
  padding: 10px 10px 10px 10px
}

.search-page-coin-image {
  height: 32px;
  margin: auto 0;
}

.search-page-coin-symbol,
.search-page-coin-name {
  padding: 10px 10px 10px 20px;
  font-size: 16px;
  letter-spacing: -0.18pt;
  max-height: 26px;
  overflow: hidden;
}

.search-page-list-text {
  padding: 10px 5px 10px 25px !important;
  width: 220px
}

.search-page-list-text .news-author {
  font-size: 10px;
  padding: 0 15px 10px 12px;
  letter-spacing: -0.18pt;
}

.search-page-list-text .news-time-expired {
  font-size: 10px;
  padding: 10px 15px 10px 12px !important;
}

.search-page-list-headline {
  font-size: 15px;
  height: 64px;
  overflow: hidden;
  letter-spacing: -0.18pt;
}

.search-page-list-type {
  font-size: 15px;
  height: 15px;
  overflow: hidden;
  margin: 10px 0 10px 0;
  letter-spacing: -0.18pt;
}

.search-page-list-title {
  margin-top: 10px;
  font-size: 15px;
  height: 25px;
  overflow: hidden;
  letter-spacing: -0.18pt;
}

.search-page-list-description {
  font-size: 15px;
  height: 64px;
  overflow: hidden;
  letter-spacing: -0.18pt;
}

.search-page-encyclopedia-list img {
  object-fit: cover;
  object-position: center top;
}

.encyclopedia-word-image-container.search {
  position: relative;
  width: 100%;
  height: 100%;
}

.encyclopedia-word-image-container.search,
.search-page-list img {
  object-fit: cover;
  object-position: center top;
}

.search-page-list .search-page-list-text {
  padding: 10px 15px 10px 25px !important;
  width: 330px;
  letter-spacing: -0.18pt;
}

.search-page-encyclopedia-list .search-page-encyclopedia-list-text {
  padding: 10px 15px 10px 25px !important;
  width: 330px;
}

.search-page-submenu-heading {
  padding-bottom: 10px;
  margin-bottom: 30px;
  margin-top: 40px;
}

.search-page-title {
  letter-spacing: -0.18pt;
  font-size: 30px;
}

.search-search-box-container input[type=text] {
  font-size: 16px;
  padding: 10px 500px 10px 50px;
}

.search-search-box-container {
  margin: 20px 25px;
  display: flex;
  justify-content: center;
}

.search-search-box-container.search-page {
  justify-content: left;
}

.search-search-box-container img {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 11px;
  padding-left: 12px;
}

.search-page-number-results{
  letter-spacing: -0.18pt;
  font-size: 14px;
  padding-top: 10px;
}

.home-user-container {
  margin-left: 10px;
  width: fit-content;
}

.learn-category-text a,
.home-user-container:hover {
  cursor: pointer;
}

.home-user-image-container,
.navbar-user-image-background,
.navbar-unauthenticated-user-image-background {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.navbar-user-image-background {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-user-image-background img {
  position: relative;
  top: -1px;
}

.navbar-unauthenticated-user-image-background {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-unauthenticated-user-image-background img {
  position: relative;
  top: -1px
}

.home-user-image {
  height: 100%;
  min-height: 25px;
  min-width: 25px;
  object-fit: cover;
  object-position: 30% 30%;
  width: 100%;
  clip-path: circle(50% at 50% 50%);
  border-radius: 50%;
}

.navbar-user-menu {
position: absolute;
top: 43px;
right: 4px;
width: 258px;
height: 328px;
border-radius: 10px;
z-index: 101;
cursor: default;
}

.navbar-user-menu .one-stop-subscribe-btn {
  width: 40% !important;
  margin-right: 5px !important;
}

.navbar-user-menu-triangle {
position: absolute;
top: 25px;
right: 7px;
font-size: 25px;
z-index: 100;
}

.page-mask {
position: fixed;
top: 0;
right: 0;
bottom: 0;
left: 0;
z-index: 99;
cursor: default;
}

.popup-mask {
  cursor: default;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: transparent;
}

.menu-user-menu-buttons,
.menu-user-menu-links,
.menu-user-menu-picture {
width: 100%;
}

.menu-user-menu-picture.true {
height: 130px;
}

.menu-user-menu-picture.false {
height: 100px;
}

.menu-user-menu-links {
  height: 39%;
}

.menu-user-menu-buttons {
height: 33%;
}

.menu-user-image-container,
.menu-user-image-background,
.menu-unauthenticated-user-image-background {
  position: absolute;
  top: 6px;
  left: 6px;
  width: 62px;
  height: 62px;
  border-radius: 50%;
}

.menu-user-image {
  height: 100%;
  min-height: 58px;
  min-width: 58px;
  object-fit: cover;
  object-position: 30% 30%;
  width: 100%;
  clip-path: circle(50% at 50% 50%);
  border-radius: 50%;
}

.profile-user-image-background,
.profile-user-image-container {
position: absolute;
top: 2px;
left: 2px;
width: 82px;
height: 82px;
clip-path: circle(50% at 50% 50%);
}

.profile-user-image {
object-fit: cover;
height: 100%;
width: 100%;
min-height: 82px;
min-width: 82px;
object-position: 30% 30%;
}

.menu-user-image-container-outer-border {
  position: relative;
  width: 74px;
  height: 74px;
  margin: auto;
  border-radius: 50%;
}

.profile-image-container-border {
position: absolute;
width: 86px;
height: 86px;
clip-path: circle(50% at 50% 50%);
}

.menu-user-placeholder-image {
position: absolute;
top: 7px;
left: 16px;
width: 24px;
height: 42px;
margin: auto;
}

.profile-user-placeholder-image {
  position: absolute;
  top: 14px;
  left: 17px;
  width: 32px;
  height: 40px;
  margin-top: 6px;
  margin-left: 8px;
}


.user-menu-image-container {
  width: 70px;
  height: 70px;
  margin: 10px auto;
  position: relative;
}

.unauthenticated-menu-user-menu-buttons {
margin-top: 20px;
margin-left: 35px;
}

.menu-user-menu-link-container.unauthenticated {
  margin: 20px 0px 0px 35px;
}

.menu-user-menu-link-container a {
  text-decoration: none;
}

.menu-user-menu-link-container {
margin-top: 20px;
margin-left: 25px;
cursor: pointer;
}

.menu-user-menu-logout-button {
  margin-top: 13px;
  margin-left: 25px;
  cursor: pointer;
}

.user-menu-name-container{
font-size: 14px;
width: 100%;
text-align: center;
}

.menu-user-menu-link-container,
.menu-user-menu-settings-button,
.menu-user-menu-preferences-button,
.menu-user-menu-logout-button,
.user-menu-email-container {
font-size: 12px;
text-decoration: none;
}

.user-menu-email-container {
padding-top: 10px;
width: 100%;
text-align: center;
}

.profile-page-navigation-buttons {
min-width: 250px;
max-width: 250px;
padding-top: 0 !important;
display: flex;
}

.profile-page-content {
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
}

.profile-page-tab-title {
  font-size: 20px;
}

.profile-page-tab-title.mid-page {
  margin-top: 50px;
}

.profile-page-tab-description {
font-size: 14px;
margin-top: 10px;
margin-bottom: 10px;
}

.profile-page-account-plus,
.profile-page-account-preferences,
.profile-page-account-details,
.profile-page-account-referrals {
  margin: 0 20px 0 20px;
  padding: 5px 20px 20px 20px;
  z-index: 1;
}

.profile-page-account-details {
  width: calc(100% - 80px);
}

.profile-element-label {
  font-size: 14px;
}

.profile-image-container,
.profile-element-container {
  margin: 20px 0 10px 0;
  width: 100%;
}

.profile-image-container {
  display: flex;
}

.profile-element-text-box-container {
  margin: 5px 0 5px 0;
}

.profile-element-text-box-container.discord {
  width: 189px;
}

.profile-element-text-box{
  width: 100%;
  height: 50px;
  padding: 15px;
}

.profile-image-preview {
  height: 90px;
  width: 90px;
}

input[type='file'] {
  display: none;
}

.profile-image-save-button,
.profile-image-upload-button{
  width: 76px;
  height: 35px;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0 10px 0;
}

.profile-image-save-button {
  margin-left: 30px;
}

.dialog-button{
  width: fit-content;
  height: 35px;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  margin: 20px auto;
}

.dialog-button.link.transaction.cancel {
  background-color: transparent;
}

.dialog-button.link.transaction {
  width: 237px;
  height: 50px;
  margin: 0;
}

.dialog-button.link.transaction.remove {
  margin-right: 26px
}

.dialog-button.link.transaction.cancel {
  border: 1px solid #9EAAC6 !important;
}

.dialog-button-text {
  padding: 10px 30px 10px 30px;
}

.dialog-button-text.transaction {
  padding-top: 15px;
}

.profile-image-save-button button {
  padding: 0 !important;
}

.profile-discord-input-container .profile-details-save-button {
  margin: 8px 20px 0 20px;
  height: 30px;
}

.profile-details-save-button {
  width: 76px;
  height: 35px;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  margin: 47px 0 0 20px;
}

.profile-page-tab-text {
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.profile-page-tab-action {
  font-size: 14px
}

.profile-image-upload-button {
padding-top: 10px;
}

.profile-image-input {
margin: 16px 0 20px 40px;
}

.profile-page-newsletter-consent {
width: 22px;
height: 22px;
margin: 10px;
}

.profile-page-newsletter-consent-label {
width: 400px;
padding: 10px;
font-size: 11px;
}

.profile-page-theme-toggle-container,
.profile-page-newsletter-consent-container {
display: flex;
}

.profile-page-theme-toggle-background {
position: relative;
width: 38px;
height: 18px;
border-radius: 15px;
}

.profile-page-theme-toggle-display {
width: 14px;
height: 14px;
border-radius: 7px;
margin: 1px 2px 2px 2px;
}

.profile-page-theme-toggle-display.dark {
margin-left: 20px;
animation-name: light_to_dark;
animation-duration: 0.5s;
}

.profile-page-theme-toggle-display.light {
margin-left: 2px;
animation-name: dark_to_light;
animation-duration: 0.5s;
}

@keyframes light_to_dark {
0% {margin-left: 2px}
100% {margin-left: 20px}
}

@keyframes dark_to_light {
0% {margin-left: 20px}
100% {margin-left: 2px}
}

.profile-page-theme-toggle-background,
.profile-page-theme-toggle-label{
margin-right: 15px;
}

.profile-page-theme-toggle-label {
margin-top: 4px;
font-size: 14px;
}

.profile-page-theme-toggle-container {
padding-top: 10px;
padding-bottom: 40px;
}

.social-media-logo-button:hover {
transform: scale(1.5);
}

.coin-page-show-rows-select {
width: 45px;
height: 25px;
font-size: 12px;
margin-left: 10px;
border: none;
border-radius: 5px;
}

.newsletter-subscribe-form-container {
position: relative;
width: 90%;
height: fit-content;
border-radius: 10px;
margin-left: auto;
margin-right: auto;

}

.newsletter-subscribe-form-aligner {
position: fixed;
width: 60%;
top: 60px;
left: 20%;
z-index: 100;
}

.newsletter-subscribe-form-body {
padding: 40px 40px 20px 40px;
}

.newsletter-subscribe-form-close {
text-align: end;
font-size: 20px;
margin-right: 5px;
}

.newsletter-subscribe-form-title{
font-size: 20px;
margin-top: 10px;
margin-bottom: 5px;
margin-left: 5px;
}

.newsletter-subscribe-form-sub-title {
font-size: 14px;
margin-top: 10px;
margin-bottom: 5px;
}

.newsletter-subscribe-form-required-text {
font-size: 12px;
}

.sign-up-edit-box {
width: 100%;
margin: 10px 0 10px 0;
padding: 10px 5px 10px 5px;
font-size: 14px;
border: none;
border-radius: 2px;
}

.newsletter-subscribe-form-radio-row,
.profile-form-radio-row{
margin-top: 5px;

}

.profile-form-radio-row {
  margin-top: 10px !important;
}

.newsletter-subscribe-form-radio input,
.profile-form-radio input{
margin: 5px 5px 8px 1px;
}

.profile-form-radio {
min-width: 200px;
font-size: 14px;
}

.newsletter-subscribe-form-consent-container {
padding-top: 10px;
padding-bottom: 15px;
display: flex;
}

.newsletter-subscribe-form-consent-message{
padding-top: 10px;
padding-left: 10px;
padding-bottom: 10px;
font-size: 14px;
}

.sign-up-privacy-link-text.link{
margin-left: 5px;
margin-right: 5px;
}

.newsletter-subscribe-form-consent {
margin-left: 30px;
}

.home-settings-register-button.disabled {
opacity: 40%;
}

.newsletter-subscribe-form-error-message {
font-size: 16px;
margin-top: 10px;
}

.user-rocket-display-container {
width: 75%;
height: 80%;
position: fixed;
top: 5%;
left: 12.5%;
z-index: 100;
object-fit: cover;
}

.user-rocket-container {
position: relative;
}

.user-rocket-container img {
height: 100%;
width: 100%;
max-height: 1000px;
}

.user-rocket-display-name,
.user-rocket-display-level {
width: 30%;
left: 35%;
text-align: center;
}

.user-rocket-display-name {
position: absolute;
font-size: 22px;
bottom: -10%;
}

.user-rocket-display-level {
position: absolute;
top: 18%;
font-size: 40px;
}

.rocket-container.left-hidden,
.rocket-container.right-hidden {
opacity: 0;
}

.rocket-lock-container {
position: relative;
}

.rocket-container.prev,
.rocket-container.left-hidden {
position: absolute;
width: 18% !important;
height: 30% !important;
transition: width 1.75s, height 1.75s, top 1.75s, left 0.5s, opacity 1s, transform 2s;
}

.rocket-container.right-hidden,
.rocket-container.next {
position: absolute;
width: 18% !important;
height: 30% !important;
transition: width 1.75s, height 1.75s, top 1.75s, left 0.5s, opacity 1s, transform 2s;
}

.rocket-container.active {
position: absolute;
width: 33% !important;
height: 55% !important;
transition: width 0.75s, height 0.75s, top 0.75s, left 1.75s, opacity 1s, transform 2s;
}

.rocket-lock-container img{
width: 30%;
margin-left: 35%;
margin-top: -120%;
}

.rocket-container.active {
position: absolute;
top: 20%;
left: 33%;
}

.rocket-container {
position: absolute;
top: 27%;
}

.rocket-container.prev {
left: 6%;
}

.rocket-container.next {
left: 70%;
}

.rocket-container.left-hidden {
position: absolute;
top: 25%;
left: -12%;
}

.rocket-container.right-hidden {
position: absolute;
top: 25%;
left: 91%;
}

.user-rocket-display-container-foreground {
z-index: 105;
}

.rocket-container.prev,
.rocket-container.next {
opacity: 50%;
}

.user-rocket-display-x {
cursor: pointer;
position: absolute;
top: 6%;
right: 5%;
font-size: 40px;
z-index: 105;
}

.user-rocket-display-next-rocket,
.user-rocket-display-prev-rocket {
position: absolute;
width: 26px !important;
height: 75px !important;
top: 45%;
cursor: pointer;
}

.user-rocket-display-prev-rocket {
left: 35%;
}

.user-rocket-display-next-rocket {
left: 62%;
}

.encyclopedia-search-box-clear {
position: absolute;
font-size: 14px;
top: 8px;
right: 10px;
cursor: pointer;
opacity: 80%;
}
.home-section-coins-grid-col-favourite {
width: 20px !important;
padding: 5px !important;
}

.home-section-coins-grid-col-number{
width: 40px !important;
padding: 5px 15px 5px 5px !important;
}

.home-section-coins-grid-col-coin {
width: 170px !important;
padding: 5px !important;
}

.home-section-coins-grid-col-coin .home-section-coins-grid-body-text {
padding-left: 10px;
}

.home-section-coins-grid-col-ticker {
width: 100px !important;
padding: 5px !important;
}

.home-section-coins-grid-col-price {
width: 155px !important;
padding: 5px !important;
}

.home-section-coins-grid-col-day,
.home-section-coins-grid-col-week {
width: 105px !important;
padding: 5px !important;
display: block;
}

.home-section-coins-grid-col-market-cap {
width: 170px !important;
padding: 5px 10px !important;
}

.home-section-coins-grid-col-chart {
width: 150px !important;
padding: 5px !important;
}

.legal-content {
display: flex;
margin: auto;
}

.news-story-details-author-info {
  width: 150px;
}

.news-story-details-author-info .news-author {
margin-bottom: 6px;
}

.news-story-read-time {
padding-top: 8px;
padding-left: 10px;
font-size: 12px;
}

.news-story-listen-container {
display: flex;
position: absolute;
right: 0;
bottom: 44px;
}

.news-story-listen {
font-size: 12px;
margin-right: 10px;
padding-top: 8px;
}

.news-story-listen-container img {
height: 17px;
width: 16px;
margin-top: 6px;
}

.ticker-row-label {
display: flex;
  margin-bottom: 3px;
}

.course-overview-about-container {
margin-top: 10px;
display: flex;
}

.course-page-summary-text {
font-size: 18px;
padding-top: 40px;
padding-bottom: 10px;
}

.course-page-summary-container,
.course-page-summary-container-section-container {
display: flex;
margin-right: 40px;
}

.course-page-summary-container-section-container img {
padding-right: 10px;
}

.course-page-summary-level {
margin-right: 7px;
}

.course-page-summary-chapters,
.course-page-summary-quizzes,
.course-page-summary-level,
.course-page-summary-level-value {
padding-top: 3px;
}

.course-page-summary-start-button {
position: relative;
height: 50px;
margin-top: 45px;
margin-bottom: 30px;
}

.module-button {
float: left;
border: none;
border-radius: 3px;
font-size: 14px;
padding: 10px 20px;
}

.module-button img {
margin-left: 25px;
width: 8px;
height: 10px;
}

.coin-nav-bar-container .page-menu-container {
padding-top: 18px;
}



.course-section-title-large-container,
.course-section-title-container {
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
}

.course-section-title-large,
.course-section-title {
  position: relative;
  font-size: 16px;
  padding: 18px;
  border-radius: 0 10px 0 0;
}

.course-section-block-container-large,
.course-section-block-container {
  border-radius: 0 0 10px 10px;
  padding-bottom: 20px;
}

.course-section-show-hide {
position: absolute;
font-size: 25px;
top: 10px;
right: 20px;
}

.home-section-info-card {
  width: 359px !important;
  padding: 0 16px 0 16px !important;
}

.home-section-info-list-container {
  margin-top: 20px;
  width: 1070px;
  overflow-x: hidden;
  padding: 0 !important;
}

.home-section-info-list {
overflow-x: hidden;
width: 1490px;
height: 520px;
padding: 10px;
transition: margin-left 0.5s ;
}

.shift-margin {
margin-left: -376px !important;
}

.news-search-read-watch-container {
padding-top: 20px;
}


.search-page-get-more-container {
display: flex;
width: 200px;
padding: 20px;
margin-left: 90%;
cursor: pointer;
}

.search-page-get-more-chev,
.search-page-get-more {
padding-right: 20px;
}

.search-page-get-more {
font-size: 10px;
}

.search-page-get-more-chev {
font-size: 10px;
transform: scale(1, 2);
}

.page-blank-space {
padding-top: 200px;
}
.tradingview-widget-container-dark,
.tradingview-widget-container-light {
width: 100%;
height: 450px;
}

.tradingview-widget-container-dark-mobile,
.tradingview-widget-container-light-mobile {
  display: none;
}

.coin-data-analysis-technical .light,
.coin-data-analysis-technical .dark {
width: 100%;
}

.chevron-container-dark.newsletter-mob {
  margin-top: -71px !important;
}

.chevron-container-dark,
.story-page-chevron,
.info-article-chevron,
.learn-module-chevron,
.course-page-chevron,
.coins-page-chevron{
/* stops the background flashing when light/dark mode switches */
  border-color: transparent transparent transparent transparent;
}

.ticker-container {
  /* stops the ticker flashing when light/dark mode switches */
  transition: all ease-in-out 0.3s;
}

.register-privacy-text {
  font-size: 11px;
  padding: 10px 10px 20px 10px;
  text-align: center;
}

.privacy-hotjar-link {
  color: #a60c00;
}

.register-login-content,
.login-register-content {
  width: fit-content;
  margin: auto;
}

.register-login-container,
.login-register-container {
  padding-top: 20px !important;
}

.register-login,
.login-register {
  display: flex;
  font-size: 13px;
}

.register-login .link,
.login-register .link {
  margin-left: 10px;
}

.home-section-story-nav-buttons {
  position: absolute;
  top: 230px;
  z-index: 100;
}

.home-section-news-main-story {
  position: relative;
}

.nav-breadcrumb-back-arrow,
.nav-breadcrumb-arrow {
  height: 12px;
  width: 8px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 3px;
  cursor: pointer;
  z-index: 100;
}

.breadcrumb-back-arrow,
.breadcrumb-arrow {
  height: 8px;
  width: 5px;
  margin-right: 10px;
  margin-top: 1px;
}

.recovery-back-arrow {
  height: 12px;
  width: 8px;
  margin-right: 10px;
}

.portfolio-breadcrumb a,
.coin-detail-breadcrumb a,
.news-story-breadcrumb a,
.encyclopedia-breadcrumb a,
.learn-chapter-breadcrumb a,
.portfolio-transactions-breadcrumb a {
  margin-right: 10px;
}

.learn-categories-heading-container {
  margin-top: 50px;
  margin-bottom: 50px;
}

.crypto-dashboard-how-to-get-started{
  margin: 30px 0;
  font-size: 16px;
}

.crypto-dashboard-how-to-get-started img {
  margin-left: 15px;
  margin-top: -2px;
  width: 20px;
  height: 20px;
}

.news-lower-padding {
  height: 220px;
}

.info-article-lower-padding{
  height: 50px;
}

.hardware-wallet-intro-content,
.hardware-wallet-intro-content .col {
  display: flex;
  padding-left: 0 !important;
}

.hardware-wallet-intro-text {
  font-size: 16px;
  padding-left: 5px;
}

.hardware-wallet-intro-list-heading {
  font-size: 16px;
}

.hardware-wallet-intro-text ul {
  padding: 0 14px;
  list-style: none;
}

.hardware-wallet-intro-text ul li::before {
  content: "\2022";
  display: inline-block;
  width: 18px;
  margin-left: -18px;
  font-size: 24px;
}

.hardware-wallet-intro-text li {
  font-size: 16px;
  padding: 2px;
}

.info-article-content h3 {
  display: none;
}

.info-article-content-text ol li {
  list-style: none;
  margin-left: -30px;
  padding-top: 2px;
  margin-bottom: 10px;
  padding-left: 40px;
  position: relative;
  min-height: 24px;
}

.info-article-content-text ol li::before {
  content: counter(list-item,upper-alpha);
  margin-right: 20px;
  margin-left: -33px;
  font-size: 14px;
  position: absolute;
  top: 3px;
}

.quiz-popup{
  position: fixed;
  width: 366px;
  height: 175px;
  top: 35%;
  left: calc((100vw - 366px)/2);
  z-index: 100;
  border-radius: 10px;
  text-align: center;
}

.quiz-popup-title{
  font-size: 18px;
  margin: 20px;
}

.quiz-popup-description{
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.quiz-popup-buttons{
  display: flex;
  justify-content: center;
  width: 80%;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

.quiz-popup-submit-button img{
  margin-top: 5px;
  margin-left: -20px;
}

.quiz-popup-submit-button-text {
  padding-top: 6px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 12px;
  text-align: center;
  width: 100%;
}

.quiz-popup-close {
  position: absolute;
  font-size: 18px;
  top: 20px;
  right: 30px;
}

.quiz-popup-submit-button {
  padding-top: 5px;
  padding-left: 10px;
  display: flex;
  width: 154px;
  height: 35px;
  border-radius: 3px;
}

.recovery-page-login-return {
  display: flex;
  width: fit-content;
  margin: 20px auto 0;
}

.recovery-page-login-return-text {
  font-size: 13px;
  text-decoration: none;
}

.recovery-page-resend-container {
  display: flex;
  font-size: 13px;
  text-align: center;
  width: fit-content !important;
  margin: 20px auto !important;
}

.recovery-page-resend-container button {
  background-color: transparent;
  border: none;
}

.recovery-page-mailapp-button {
  text-align: center;
}

.password-field {
  display: block;
  position: relative;
}

.password-field img {
  position: absolute;
  right: 20px;
  height: 24px;
  top: 10px;
}

.messages {
  padding: 10px;
}

.encyclopedia-cards {
  margin-top: 30px;
}

.encyclopedia-page-video {
  position: relative;
}

.encyclopedia-page-video iframe{
  position: absolute;
  top: 0;
  left: 0;
}

.encyclopedia-get-started-image {
  position: absolute;
  top: 40%;
  left: 120px;
  width: 48px;
  height: 48px;
  z-index: 2;
}

.tou-title,
.how-funded-title {
  margin-top: 15px;
  font-size: 24px;
}

.tou-section-container,
.how-funded-section-container {
  margin-top: 25px;
}

.tou-paragraph-container,
.how-funded-paragraph-container {
  margin-top: 25px;
  margin-left: 30px;
}

.tou-section-number,
.how-funded-section-number {
  float: left;
  margin-right: 8px;
  font-size: 12px;
}

.home-contents .tou-section-content,
.home-contents .how-funded-section-content {
  margin-top: 20px;
}

.learn-module-cards .home-contents .col {
  padding: 0 !important;
}

.tou-section-content,
.how-funded-section-content {
  font-size: 12px;
}

.tou-section-number-title,
.how-funded-section-number-title {
  width: 100%;
  font-size: 16px;
}

.tou-sub-paragraph-container,
.how-funded-sub-paragraph-container {
  margin-top: 15px;
  margin-left: 70px;
}

.tou-sub-sub-paragraph-container {
  margin-top: 15px;
  margin-left: 110px;
}

.info-panel-container {
  position: absolute;
  right: -100px;
  width: 200px;
  padding: 10px;
  z-index: 99;
  border-radius: 5px;
  margin-top: 5px;
  text-align: left;
  font-size: 10px;
}

.info-panel-triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  position: absolute;
  top: -10px;
  right: 92px;
}

.coin-detail-stats-info-content {
  position: relative;
  width: fit-content;
}

.module-cards-menu-container {
  margin-top: 40px;
}

.learn-category-navigation-container {
  position: relative;
  margin-top: 20px;
}

.locked-view-certification {
  position: absolute;
  top: 2px;
  left: 180px;
  display: flex;
  height: 15px;
}

.locked-view-certification-image {
  width: 13px;
}

.locked-view-certification-text {
  font-size: 14px;
  text-align: center;
  margin-top: 3px;
  margin-left: 5px;
}

.related-news-title {
  font-size: 18px;
  padding-bottom: 10px;
}

.related-news-story {
  border-radius: 0 10px 10px 10px;
  margin-top: 20px;
  position: relative;
}

.related-news-story img {
  border-top-right-radius: 10px;
  width: 100%;
  height: 131px;
  object-fit: cover;
}

.related-news-story-heading {
  padding: 10px;
  font-size: 14px;
  height: 78px;
  overflow: hidden;
}

.related-news-story.news-page-market-story-tag-container {
  position: absolute;
  top: 10px !important;
  left: 10px !important;
  border-radius: 5px;
  height: 15px;
  padding: 2.5px 10px 5px 10px;
}

.load-more-related-news-container {
  padding-bottom: 60px;
}

.related-news-container {
  margin-bottom: 60px;
}

.dialog-container {
  position: fixed;
  top: 20%;
  left: calc(50vw - 250px);
  width: 500px;
  margin: auto;
  min-height: 200px;
  z-index: 100;
  border-radius: 10px;
}

.dialog-header {
  padding: 20px;
  font-size: 20px;
}

.dialog-header.transaction {
  padding: 3px 0 0;
}

.dialog-text.transaction {
  padding: 15px 30px 30px 30px;
  font-size: 14px;
}

.portfolio-popup-cross.transactions.link {
  height: 33px;
}

.portfolio-popup-cross.transactions.link.remove {
  position: absolute;
  top: 25px;
  right: 25px;
}

.portfolio-transaction-footer-padding {
  height: 50px;
}

.dialog-text {
  padding: 20px;
  font-size: 16px;
}

.dialog-footer-container {
  display: flex;
}

.dialog-footer-container.transaction {
  padding: 0 30px 0 30px;
}

.quiz-result-users-merit-image {
  margin-top: 20px;
  width: 100%;
  height: 40px;
}

.quiz-result-users-merit-message {
  font-size: 15px;
  margin-top: 20px;
  width: 100%;
  text-align: center;
}


.quiz-result-users-merit-image img{
  display: block;
  width: 20px;
  margin-left: auto;
  margin-right: auto;
}

.coin-detail-markets-container {
  margin-top: 30px;
}

.encyclopedia-search-word-image-text-container {
  position: absolute;
  top: 25px;
  left: 60px;
}

.encyclopedia-search-word-image-text-container.search {
  bottom: 110px;
  left: 45px;
}

.encyclopedia-word-image-text-container {
  position: absolute;
  top: 35px;
  right: 90px;
  z-index: 1;
}

.encyclopedia-word-image-text {
  text-align: right;
  width: 160px;
  max-height: 90px;
  overflow-y: hidden;
  overflow-x: hidden;
  padding-bottom: 5px;
  font-size: larger;
}

.encyclopedia-word-image-text-definition {
  text-align: right;
  width: 85px;
  margin-left: 75px;
  padding-top: 5px;
  font-size: 15px;
}

.info-article-content-step-container {
  display: flex;
  padding: 50px 20px 40px 0;
}

.info-article-content-small-step-container {
  display: flex;
  padding: 10px 20px 40px 0;
}

.info-article-content-fake-step-container {
  display: flex;
  padding: 30px 20px 0 0;
}

.info-article-content-step-image-container {
  margin-left: 10px;
  margin-right: 10px;
}

.info-article-content-step-text-container {
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

.info-article-content-step-full-text-container,
.info-article-content-step-text-container p {
  font-size: 14px;
}

.info-article-content-step-paragraph img {
  width: 100% !important;
}

.info-article-content-step-title {
  padding-top: 30px !important;
}

.info-article-content-step-full-text-container {
  width: 100%;
  margin-top: 20px;
}

.course-overview-about-left {
  margin-right: 50px;
}

.back-to-top-button-container {
  width: 35%;
  margin-left: 40%;
  margin-bottom: 30px;
}

.back-to-top-button {
  text-align: center;
  cursor: pointer;
}

.youtube-container {
  height: 165px;
  width: 286px;
}

.quiz-results-page-card-buttons {
  display: flex;
  width: fit-content;
  margin: 30px auto 50px auto;
  padding-bottom: 40px;
}

.quiz-back-to-course-button-container,
.quiz-retake-button-container,
.quiz-continue-course-button-container,
.quiz-back-to-course-button-container-center,
.quiz-continue-course-button-container-center{
  margin: 10px 30px;
}

.chapter-page-navbar-container {
  display: flex;
}

.chapter-page-navbar-container .page-submenu-title {
  margin-right: 20px;
}

.crypto-dashboard-profile-picture-mask-background {
  position: absolute;
  width: 90px;
  height: 78px;
  -webkit-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
  -moz-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
}

.how-to-get-started-modal {
  position: fixed;
  z-index: 100;
  aspect-ratio: 16 / 9;
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
  top: 20%;
}

.close-modal {
  position: absolute;
  top: 2px;
  right: 10px;
  font-size: 20px;
  width: 15px;
  height: 20px;
}

.certification-image-container img {
  margin-top: 30px;
  width: 100%;
}

.course-section-video {
  height: 400px;
  padding-bottom: 20px;
}

.course-section-video-large {
  height: 535px;
  padding-bottom: 20px;
}

.news-story-source {
  width: 100%;
  font-size: 13px;
  opacity: 70%;
  padding: 5px 10px;
}

.loading-container {
  width: fit-content;
  margin: auto;
  padding-bottom: 30px;
}

.loading {
  font-size: 16px;
}

.newsletter {
  display: flex;
}

.newsletter .login-input {
  height: 16px;
  width: 30px;
  margin: auto;
}

.profile-form {
  z-index: 1;
}

.profile-form-container {
  width: calc(100% - 250px);
}

.search-page-nothing-found-message {
  margin-bottom: 20px;
}

.cse-embedded-tweet-container {
  margin-bottom: 20px;
}

.embedded-tweet-alt-container {
  position: absolute;
  z-index: 0;
  margin-left: 5px;
  margin-top: 5px;
  max-width: 520px;
  max-height: 340px;
}

.twitter-tweet-rendered {
  z-index: 10;
  position: sticky;
}

.trending-card-title,
.loser-card-title,
.gainer-card-title {
  margin: 10px 0 10px 8px;
  font-size: 12px;
}

.news-story-details .mobile-field,
.mobile-search-icon {
  display: none;
}

.burger-menu {
  transform: translateX(1500px);
}

.burger-menu-item-link {
  text-decoration: none;
}

.burger-menu {
  width: 100vw;
  height: 100vh;
  z-index: 999;
  min-width: 100vw;
  max-height: 100vh;
  opacity: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  overflow: scroll;
  top: 0;
  left: 0;
  overscroll-behavior-y: contain;
  -webkit-transition: transform 0.4s ease-in-out;
  -moz-transition: transform 0.4s ease-in-out;
  -ms-transition: transform 0.4s ease-in-out;
  -o-transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out;
}

.affiliate-link-container {
  margin-top: 12px;
  z-index: 999;
}

.affiliate-link {
  font-size: 12px;
}

.affiliate-link u {
  text-underline-offset: 2px;
}

.affiliate-popup-container {
  position: absolute;
  right: 0;
  top: -220px;
  width: 300px;
  border-radius: 10px;
  z-index: 999;
}

.affiliate-close {
  padding: 10px 15px;
  text-align: right;
  font-size: 12px;
  text-underline-offset: 2px;
}

.affiliate-popup-text {
  padding: 0 20px 20px 20px;
  font-size: 12px;
}

ul.affiliate-popup-text {
  margin-left: 20px;
  margin-bottom: 0;
}

.affiliate-nav {
  font-weight: 600;
  text-underline-offset: 2px;
}

.affiliate-triangle {
  border-style: solid;
  border-width: 0 10px 10px;
  height: 0;
  position: absolute;
  right: 20px;
  bottom: -10px;
  width: 0;
  rotate: 180deg;
}


.affiliate-triangle-outer {
  border-style: solid;
  border-width: 0 11px 11px;
  height: 0;
  position: absolute;
  right: 19px;
  bottom: -11px;
  width: 0;
  rotate: 180deg;
}

.newsletter-signup-heading-container {
  display: flex;
  font-size: 24px;
  justify-content: center;
  padding-bottom: 70px;
  padding-top: 50px;
  text-align: center;
}

.newsletter-signup-heading.blue {
  color: #3866F2;
}

.newsletter-signup-body-container {
  display: flex;
  height: 100%;
  margin: 0 20px;
  justify-content: space-between;
}

.newsletter-signup-form-container {
  width: 55%;
  padding: 10px 20px 10px 0;
}

.newsletter-signup-image-container {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.newsletter-signup-image-container img {
  width: 90%;
}

.newsletter-signup-form-text {
  padding-bottom: 20px;
}

.newsletter-signup-form-email-input {
  padding-bottom: 20px;
}

.newsletter-signup-form-checkbox {
  padding-bottom: 5px;
  display: flex;
}

.newsletter-signup-form-checkbox-label {
  margin-left: 10px;
}

.newsletter-signup-form-button {
  width: 161px !important;
  margin-top: 15px;
}

.invalid {
  color: #C20000;
}

.newsletter-signup-form-submitted-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70%;
}

.newsletter-signup-form-submitted-subcontainer  {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.newsletter-signup-form-submitted-image-text {
  display: flex;
  padding-bottom: 20px;
}

.newsletter-signup-form-submitted-image-container {
  width: 38px;
}

.newsletter-signup-form-submitted-image-container img {
  width: 100%;
}

.newsletter-signup-form-submitted-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 0 0 10px;
}

.newsletter-email-input {
  width: 100%;
  height: 48px;
  padding-left: 15px;
  border-radius: 10px;
  border: solid 1px #FF9017
}

.newsletter-signup-page-container {
  padding-bottom: 80px;
}

.newsletter-invalid-email {
  padding-top: 10px;
}

.newsletter-consent-input {
  align-self: baseline;
  width: 14px;
  height: 14px;
}

.page-not-found-container {
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  overflow-y: hidden;
}

.blue-background {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.stars-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70%;
  object-fit: cover;
}

.rock-planet {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200px;
  object-fit: cover;
  opacity: 40%;
}

.crashed-rocket {
  position: absolute;
  bottom: 50px;
  height: 600px;
  left: 50%;
}

.page-not-found-text-container {
  position: absolute;
  width: 60%;
  left: 20%;
  top: 7%;
  height: 40%;
}

.page-not-found-image-container {
  padding-top: 20px;
  margin: auto;
  width: 140px;
}

.page-not-found-title-container,
.page-not-found-message-container,
.page-not-found-description-container {
  text-align: center;
}

.page-not-found-home-button {
  width: 100px;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  padding-top: 12px;
}

.page-not-found-home-button-container {
  margin-left: calc((100% - 100px) / 2);
}

.page-not-found-message-container {
  display: flex;
  font-size: 40px;
  text-align: center;
  width: fit-content;
  margin: 30px auto;
}

.page-not-found-message-container div {
  padding-left: 5px;
  padding-right: 5px;
}

.page-not-found-description-container {
  font-size: 20px;
  padding-bottom: 50px;
}

.page-not-found-title-container {
  margin-top: 50px;
  font-size: 24px;
}

.checkbox-container {
  width: fit-content;
  height: fit-content;
}

.large-image-container {
  position: fixed;
  width: 95vw;
  height: 95vh;
  left: calc(50% - 95vw/2);
  top: calc(50% - 95vh/2);
  z-index: 100;
}

.large-image {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.large-image-close-button {
  position: absolute;
  top: calc(35px + 76px);
  right: 50px;
  z-index: 101;
}

.profile-referrals-title-section {
  display: flex;
  padding-bottom: 40px;
  height: 100%;
}

.profile-referrals-title-section-text-container {
  width: 90%;
}

.profile-referrals-main-progress-container {
  margin: 15px 0 0 15px;
  padding: 0 0 0 15px;
  width: 31%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-referrals-title {
  font-size: 35px;
}

.profile-referrals-description {
  margin-top: 15px;
  font-size: 16px;
}

.profile-referrals-link-container {
  display: flex;
  margin-top: 20px;
  height: 40px;
  border-radius: 5px;
  max-width: 95%;
}

.profile-referrals-link {
  margin: auto 0;
  font-size: 14px;
}

.referral-link-icon {
  width: 30px;
  margin: 0 12px 1px;
}

.referral-copy-icon {
  height: 25px;
  margin: 7px 20px 7px auto;
}

.referral-reward-heading {
  font-size: 24px;
  margin-bottom: 30px;
  padding-bottom: 12px;
  width: 100%;
  border-bottom: 1px solid #FF9017;
}

.referral-reward-progress-bar-background {
  height: 11px;
  border-radius: 5px;
  position: relative;
}

.referral-reward-progress-bar {
  max-width: 100%;
  width: 0;
  height: 100%;
  border-radius: 5px;
}

.referral-reward-hex-row-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  left: 15px;
  top: -24px;
}

.profile-referrals-hex-number {
  position: absolute;
  font-size: 14px;
  top: 11px;
}

.profile-referrals-hex-container {
  display: flex;
  justify-content: center;
  text-align: center;
  width: fit-content;
  position: relative;
}

.hex-connector {
  position: relative;
  height: 15px;
  width: 1px;
  top: -9px;
  left: 39px;
}

.hex-reward-container {
  position: absolute;
  top: 45px;
}

.hex-reward-text {
  font-size: 14px;
  width: 80px;
}

.referral-progress-wheel-heading {
  font-size: 24px;
  text-align: center;
  padding-bottom: 15px;
}

.referral-progress-wheel-outer {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  border-radius: 50%;
}

.referral-progress-wheel-inner {
  width: 180px;
  height: 180px;
  margin: auto;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.referral-progress-wheel-inner-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.referral-progress-wheel-inner-text-heading {
  font-size: 12px;
  padding-bottom: 5px;
}

.referral-progress-wheel-inner-text-count {
  font-size: 40px;
}

.profile-page-account-referrals {
  height: 100%
}

.profile-referrals-invite-heading {
  padding: 130px 0 20px 0;
  font-size: 24px;
}

.profile-referrals-invite-buttons-container {
  display: flex;
  margin: 0 0 150px 0;
  justify-content: space-between;
}

.profile-referrals-invite-button {
  display: flex;
  justify-content: center;
  width: 16%;
  height: 30px;
  border-radius: 3px;
  align-items: center;
}

.profile-referrals-invite-button img {
  width: 12px;
}

.profile-referrals-invite-button-label {
  font-size: 10px;
}

.user-react-result-container {
  display: flex;
  width: 100%;
}

.user-react-result-container.coin-react.good {
  flex: 1;
  width:fit-content;
  margin-right: 20px !important;
}

.user-react-result {
  margin-right: 20px;
  margin-left: 11px;
}

.coin-portfolio-star.coin{
  position: absolute;
  width: 16px;
  height: 16px;
  left: 69px;
  bottom: 23px;
}

.portfolio-link {
  display: block;
  background-color: transparent;
  border: transparent;
  width: 90px;
  height: 25px;
  position: absolute;
  left: 90px;
  z-index:10;
}

.coin-portfolio-title {
  font-size: 30px;
  display: flex;
  min-width: 170px;
}

.coin-portfolio-title-button-container {
  width: 100%;
  text-align: right;
}

.coin-portfolio-title-button {
  width: 130px;
  padding: 7px 0 !important;
}

.coin-portfolio-all-total-container {
  width: fit-content;
  margin-bottom: 15px;
}

.coin-portfolio-title-container {
  margin-bottom: 15px;
}

.coin-portfolio-star {
  width: 23px;
  height: 23px;
}

.coin-portfolio-total-balance-symbol,
.coin-portfolio-total-balance-title {
  font-size: 12px;
  width: fit-content !important;
  color: #9EAAC6;
}

.coin-portfolio-total-balance-symbol {
  padding: 0 0 0 2px !important;
}

.coin-portfolio-balance {
  font-size: 40px;
  margin-bottom: 20px;
}

.coin-portfolio-one-container {
  margin-top: 80px;
  min-height: 200px;
}

.coin-portfolio-card {
  width: 200px;
  height: 80px;
  border-radius: 10px !important;
  padding: 15px;
}

.coin-portfolio-card-container {
  width: 192px;
  height: 72px;
  border-radius: 10px;
  margin-right: 35px;
}

.coin-portfolio-card-percent {
  margin-top: 0;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.hour-portfolio,
.day-portfolio {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.coin-portfolio-card-percent.large {
  padding: unset;
}

.coin-portfolio-card-percent.large img {
  width: 12px;
  height: 7px;
}

.coin-portfolio-card-title{
  margin-bottom: 15px;
  font-size: 12px;
  opacity: 50%;
  display: flex;
}

.coin-portfolio-card-img {
  width: 38px;
  height: 38px;
  margin-top: 3px;
}

.coin-portfolio-card-img img{
  width: 38px;
  height: 38px;
}

.coin-portfolio-edit-button {
  border-radius: 3px;
  width: 75px;
  height: 27px;
  border: none;
  font-size: 10px;
  margin-right: 16px;
}

.coin-portfolio-edit-button img {
  padding-right: 7px;
  margin-top: -5px;
}

.coin-portfolio-card-values-container {
  display: flex;
}

.coin-portfolio-card-values-container .col {
  padding: 0 !important;
}

.coin-portfolio-card-value {
  font-size: 20px;
}

.PokoappIframe iframe {
  margin-left: 10%;
  width: 80%;
  height: 650px;
}

.portfolio-popup-card {
  width: 541px;
  position: fixed;
  border-radius: 10px;
  top: 200px;
  left: calc((100% - 541px) / 2);
  right: calc((100% - 541px) / 2);
  z-index: 1000;
}

.thin-menu {
  width: 317px;
  left: calc((100% - 317px) / 2);
  right: calc((100% - 317px) / 2);
}

.medium-menu {
  width: 476px;
  left: calc((100% - 476px) / 2);
  right: calc((100% - 476px) / 2);
}

.wide-menu {
  width: 541px;
  left: calc((100% - 541px) / 2);
  right: calc((100% - 541px) / 2);
}

.portfolio-popup-card.dropdown {
  width: 317px;
}

.portfolio-popup-card.portfolioList{
  position: absolute;
  top: 24vh;
  left: 13vw;
}

.portfolio-popup-card.addPortfolio,
.portfolio-popup-card.addcoin {
  left: 39%;
  top: 250px;
}

.search-search-box.addcoin {
  width: 100%;
  padding: 10px 0 10px 10px !important;
  color: #9EAAC6;
  border-radius: 10px !important;
}

.portfolio-popup-cross-container {
  text-align: right !important;
}

.portfolio-popup-cross {
  border: none;
  background-color: transparent;
}
.portfolio-popup-cross.create {
  position: absolute;
  top: 15px;
  right: 10px;
}

.portfolio-popup-button {
  width: 234px;
  height: 51px;
  border-radius: 3px;
  border: none;
  font-size: 14px;
}

.small-button {
  width: 189px;
}

.portfolio-popup-button-container .cancel {
  text-align: end;
}

.portfolio-popup-button-container.create {
  justify-content: flex-start;
}

.portfolio-popup-button-container.cancel {
  justify-content: flex-end;
}

.portfolio-popup-button.cancel {
  text-align: center;
}

.portfolio-popup-button-container {
  margin: auto;
  display: flex;
  justify-content: center;
  width: 100%;
}

.portfolio-popup-card-title-container.selectedCoin {
  text-align: center;
  margin: auto;
  width: fit-content;
  align-items: center;
}

.portfolio-popup-coin-container {
  margin: 10px auto;
  height: 70px;
  padding: 10px;
}

.portfolio-popup-holdings-menu-container {
  display: flex;
  margin: 10px 0 20px 0;
}

.portfolio-popup-holdings-menu-button-container{
  width: 33%;
}

.portfolio-popup-holdings-menu-button {
  width: 164px;
  height: 45px;
  background-color: transparent;
  font-size: 15px;
}

.portfolio-popup-holdings-menu-button.buy {
  border-radius: 4px 0 0 4px;
}

.portfolio-popup-holdings-menu-button.transfer {
  border-radius: 0 4px 4px 0;
}

.portfolio-popup-tab-input.price-per {
  padding-left: 33px;
}

.portfolio-popup-tab-input {
  width: 100%;
  height: 49px;
  border-radius: 4px;
  padding: 20px;
  font-size: 17px;
}

.portfolio-popup-tab-input::-webkit-outer-spin-button,
.portfolio-popup-tab-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.portfolio-popup-tab-container {
  padding: 0 25px;
  font-size: 14px;
}

.portfolio-popup-title-container.portfolio-name {
  padding: 0 25px;
  font-size: 12px;
  height: fit-content;
}

.portfolio-popup-title-container {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  align-items: center;
  padding: 8px 25px;
  margin-top: 10px;
  height: 45px;
}

.portfolio-popup-title {
  font-size: 20px;
}

.portfolio-popup-tab-input-coin-container {
  display: flex;
  position: absolute;
  right: 12px;
  top: 10px;
}

.portfolio-popup-tab-subtitle-container {
  margin-bottom: 7px;
  display: flex;
  justify-content: space-between;
}

.portfolio-popup-tab-subtitle.quantity {
  width: 170px;
}

.portfolio-popup-tab-subtitle.balance{
  width: fit-content;
}

.portfolio-popup-tab-subtitle.balance-value {
  padding: 0 20px 0 5px;
  text-align: end;
}

.portfolio-popup-tab-subtitle.symbol{
  width: 30px;
}

.portfolio-popup-tab-input-container.transfer {
  display: flex;
}

.portfolio-popup-tab-input-container {
  margin-bottom: 15px;
  position: relative;
}

.portfolio-popup-tab-input-result-card {
  width: 100%;
  border-radius: 4px;
  height: unset;
  margin-bottom: 10px;
}

.portfolio-popup-tab-date {
  width: 54px;
  height: 40px;
  padding: 10px 0 10px 20px;
  -webkit-appearance: none;
}

.portfolio-popup-tab-date-display {
  -webkit-appearance: none;
  width: 100%;
  background-color: unset;
}

.portfolio-popup-tab-input-result-title {
  font-size: 14px;
  padding: 15px 0 10px 20px;
}

.portfolio-popup-tab-input-result {
  font-size: 30px;
  padding: 0 0 15px 20px;
  overflow-x: auto;
}

.portfolio-popup-name-input-container {
  font-size: 17px;
  padding: 10px 25px 0 25px;
}

.portfolio-popup-card-title-item .buttons {
  padding: 20px 20px 20px 20px;
  text-align: end;
}

.portfolio-popup-card-title-item {
  width: 100%;
}

.portfolio-popup-button-container.transactions {
  text-align: end;
}

.portfolio-popup-radio-card {
  width: calc(100% - 6px);
  height: 40px;
  border-radius: 4px;
  margin-left: 6px;
  display: flex;
  padding: 8px 2px;
  font-size: 17px;
  align-items: center;
  justify-content: space-around;
}

.portfolio-popup-radio-card.out {
  margin-left: 0;
}

.portfolio-popup-radio-text {
  width: 70%;
  padding: 5px 7px;
}

.portfolio-popup-tab-date-container {
  margin-top: 10px;
  border-radius: 4px;
  display: flex;
}

.portfolio-popup-tab-date-container input {
  border: none;
}

.portfolio-popup-tab-input-max {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 10px;
  padding-right: 10px;
}

.portfolio-popup-card-title-container.transactionButtons {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.portfolio-popup-card-title-container.editPortfolio {
  padding: 20px 20px 20px 20px
}

.portfolio-popup-name-input {
  border-radius: 8px;
  height: 36px;
  width: 100%;
  padding: 17px;
}

.portfolio-popup-characters-container {
  padding: 10px 20px 0 25px;
  font-size: 12px;
  opacity: 50%;
}

.portfolio-title-dropdown-button {
  width: 21px;
  height: 21px;
  border-radius: 10px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.portfolio-title-dropdown-button-container {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.portfolio-menu-list-divider{
  border-bottom: solid 1px rgba(112, 112, 112, 0.21);
  margin: 10px 20px;
}

.portfolio-coin-grid-row-button {
  background-color: transparent;
  border: none;
  text-decoration: none;
}

.portfolio-coin-grid-row-button.addCoin {
  width: 380px !important;
}

.col .portfolio-card-img {
  margin-right: -80px;
}

.portfolio-card-img {
  display: flex;
  align-items: center;
}

.coin-portfolio-number-container {
  width: fit-content;
  margin-right: 10px;
}

.coin-portfolio img{
  width: 22px;
  height: 22px;
}

.coin-portfolio {
  display: flex;
  width: 180px;
  padding-left: 10px;
}

.price-portfolio {
  width: 160px;
  text-align: end;
}

.day-portfolio,
.hour-portfolio {
  width: 105px;
  text-align: end;
}

.holdings-portfolio {
  width: 190px;
  text-align: end;
}

.holdings-count-container {
  display: flex;
  justify-content: flex-end;
}

.holdings-count-portfolio{
  padding-left: 5px;
  text-align: end;
  font-size: 12px;
}

.profitLoss-portfolio {
  width: 151px;
  text-align: end;
}

.portfolio-grid-button-container {
  display: flex;
  text-align: end;
  padding-left: 50px;
}

.portfolio-grid-addition-cross,
.portfolio-grid-transfer-arrows,
.portfolio-grid-delete-bin {
  height: 16px;
  width: 16px;
}

.addition-button,
.transfer-button,
.delete-button {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: none;
  margin-right: 15px;
}

.coin-portfolio-all-cards-container {
  display: flex;
}

.coin-grid-footer-container.portfolio {
  padding-bottom: 20px;
  margin-top: 40px;
}

.portfolio-subpage-contents {
  margin-top: 30px;
  min-height: 1px;
}

.portfolio-grid-container {
  max-width: 1100px;
  margin: auto;
  padding-bottom: 40px;
}

.portfolio-grid-container.transaction {
  padding-left: 25px;
  padding-right: 15px;
}

.portfolio-menu-list-item {
  padding: 15px 0 15px 25px;
  font-size: 14px;
}

.portfolio-popup-card-title-container {
  display: flex;
}

.portfolio-popup-card-title-container.buttons {
  padding: 30px 25px;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.coin-portfolio-title-button-container.portfolio-menu {
  padding: 10px 25px 18px 25px;
  text-align: left !important;
}

.portfolio-coin-grid-row-container {
  padding-top: 10px;
  padding-bottom: 15px;
}
.encyclopedia-page-container,
.newsletter-signup-page-container,
.learn-page-container,
.course-page-container,
.learn-module-container,
.info-article-container,
.story-page-container,
.coin-page-container,
.coin-detail-container,
.info-page-container,
.news-page-container,
.deals-page-container {
  min-height: 950px;
}

.cookies-pop-up-container {
  width: 100vw;
  height: 12vh;
  position: fixed;
  bottom: 0;
  z-index: 200;
  font-size: 30px;
  padding: 10px 20px;
}

.cookies-pop-up-container button{
  height: 30px;
  font-size: 16px;
  border: none;
  border-radius: 6px;
  margin: 0 20px;
  width: 200px;
  padding-top: 5px;
}

.cookies-pop-up-content-container {
  display: flex;
  padding: 30px 0;
  width: fit-content;
  margin: auto;
}

.cookies-pop-up-text {
  padding-right: 60px;
}

.toggle-cookie-container,
.footer-links-container {
  display: flex;
}

.toggle-cookie-container,
.cookie-page-toggle-cookie-container,
.tou-paragraph-container table {
  width: 100%;
}

.tou-paragraph-container td,
.tou-paragraph-container th {
  padding: 5px;
}

.toggle-cookie-message {
  padding: 10px 0 14px 0;
}

.slider-toggle-background {
  border-radius: 10px;
  height: 21px;
  width: 50px;
  margin: 0 15px;
}


.portfolio-page-chart-section-container {
  margin-top: 30px;
  display: flex;
  position: relative;
  z-index: 100;
}

.portfolio-chart-coins-container {
  width: 33%;
  padding: 0 100px 0 0;
}

.portfolio-chart-coin-row {
  display: flex;
  padding: 10px 0;
}

.portfolio-chart-coin-colour {
  width: 26px !important;
  height: 26px !important;
  border-radius: 50%;
}

.portfolio-chart-coin-row .col-sm-4 {
  padding: unset;
  margin: 0 0 0 20px;
}

.portfolio-chart-coin-name,
.portfolio-chart-coin-percentage {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.portfolio-chart-coin-percentage {
  text-align: end;
  flex-direction: row-reverse;
}

.portfolio-chart-chart-container {
  height: 360px;
  width: 360px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.portfolio-chart-chart-inner {
  width: 92%;
  height: 92%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cookie-page-toggle-cookie-container {
  padding-top: 40px;
}

.cookies-pop-up-container {
  width: 100vw;
  height: 10vh;
  position: fixed;
  bottom: 0;
  z-index: 200;
  font-size: 30px;
  padding: 10px 20px;
}

.cookies-pop-up-container button{
  height: 30px;
  font-size: 16px;
  border: none;
  border-radius: 6px;
  margin: 0 20px;
  width: 200px;
  padding-top: 5px;
}

.cookies-pop-up-content-container {
  display: flex;
  padding: 20px 0;
  width: fit-content;
  margin: auto;
}

.cookies-pop-up-text {
  padding-right: 60px;
}

.toggle-cookie-container,
.footer-links-container {
  display: flex;
}

.toggle-cookie-container,
.cookie-page-toggle-cookie-container,
.tou-paragraph-container table {
  width: 100%;
}

.tou-paragraph-container td,
.tou-paragraph-container th {
  padding: 5px;
}

.toggle-cookie-message {
  padding: 10px 0;
}

.slider-toggle-background {
  border-radius: 10px;
  height: 21px;
  width: 50px;
  margin: 0 15px;
}

.slider-toggle-selector {
  width: 16px;
  height: 16px;
  margin: 3px;
  border-radius: 10px;
  transition: marginLeft 1s ease;
}

.portfolio-transactions-title,
.portfolio-transactions-balance,
.portfolio-transactions-details-container,
.portfolio-transactions-profit-loss-container,
.portfolio-transaction-percentage {
  display: flex;
}

.portfolio-transactions-coin-name {
  font-size: 30px;
  padding-left: 10px;
}

.portfolio-transactions-title img {
  width: 30px;
}

.portfolio-transactions-title {
  margin-bottom: 20px;
}

.portfolio-transactions-title-container {
  padding: 0 15px 100px 15px;
}

.portfolio-transactions-balance {
  margin-bottom: 10px;
}

.portfolio-transactions-balance img {
  margin-left: 10px;
}

.portfolio-transactions-subheading {
  font-size: 12px;
  margin-bottom: 10px;
}

.portfolio-transactions-subheading.no-margin {
  margin: unset;
  display: flex;
  align-items: center;
}

.portfolio-transaction-total-balance {
  font-size: 40px;
  margin-bottom: 20px;
}

.portfolio-transactions-details-section {
  border-radius: 10px;
  padding: 15px;
  margin-right: 25px;
}

.portfolio-transactions-details-section.extended {
  min-width: 190px;
}

.portfolio-transaction-value {
  font-size: 20px;
}

.portfolio-transaction-percentage {
  margin-left: 20px;
  font-size: 14px;
  align-items: center;
  padding-top: 3px;
}

.dialog-header-flex-container {
  display: flex;
  justify-content: space-between;
  padding: 25px 30px 0
}

.portfolio-transactions-details-container {
  margin-bottom: 30px;
}

.transaction-grid-header {
  font-size: 12px;
}

.transaction-grid-header .price,
.transaction-grid-header .quantity,
.transaction-grid-header .amount,
.transaction-grid-header .fees,
.transaction-grid-header .profit-loss,
.transaction-grid-row .price,
.transaction-grid-row .quantity,
.transaction-grid-row .amount,
.transaction-grid-row .fees,
.transaction-grid-row .profit-loss {
  text-align: right;
}

.transaction-grid-header .actions,
.transaction-grid-row .actions {
  text-align: end;
  margin-right: 5px;
}

.transaction-grid-row {
  font-size: 14px;
  padding: 10px 0;
  align-items: center;
}

.transaction-grid-row .date {
  font-size: 12px;
}

.transaction-grid-row .actions {
  display: flex;
  justify-content: flex-end;
}

.portfolio-transactions-actions-button img {
  width: 15px;
  height: 15px;
  margin-left: 10px;
}

.portfolio-transaction-grid-percentage {
  display: flex;
  font-size: 12px;
  justify-content: flex-end;
  margin-top: 3px;
}

.portfolio-transaction-grid {
  margin-top: -90px;
}

.coin-subpage-menu.portfolio-transaction {
  padding-left: 15px;
  padding-right: 15px;
}

.portfolio-search-popup-card-image img{
  width: 44px;
  height: 44px;
}

.portfolio-popup-addcoin-title {
  margin: 0 20px;
  font-size: 18px;
}

.portfolio-popup-addcoin-symbol {
  font-size: 18px;
}

.portfolio-popup-tab-input-coin-symbol {
  display: flex;
  align-items: center;
}

.portfolio-popup-tab-input-coin-logo img {
  width: 29px;
  height: 29px;
  margin-left: 10px;
}

.portfolio-show-assets-toggle{
  display: flex;
  padding-left: 10px;
  padding-top: 40px;
}

.portfolio-show-toggle-text {
  font-size: 12px;
  display: flex;
  align-items: center;
}

.coin-portfolio-separator  {
  opacity: 50%;
  padding-bottom: 40px;
  margin-top: -100px;
}

.coin-portfolio-all-container {
  padding-bottom: 60px;
}

.coin-portfolio-text-container {
  display: flex;
  padding-left: 10px;
  width: 100%;
}

.coin-portfolio-text-container .name {
  text-align: center;
  display: flex;
  align-items: center;
}

.portfolio-nav {
  color: unset !important;
}

.portfolio-popup-radio-circle {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: solid 1px #9EAAC6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.portfolio-chart-outer-outline,
.portfolio-chart-inner-outline {
  border-radius: 50%;
  border: solid 1px #424D67;
}

.portfolio-chart-outer-outline {
  width: 400px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.portfolio-chart-inner-outline {
  width: 85%;
  height: 85%;
}

.portfolio-chart-wheel-shadow {
  border-radius: 50%;
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coin-text-up.large,
.coin-text-down.large {
  font-size: 20px;
}

.actions-portfolio {
  width: 150px;
  text-align: end;
  padding-right: 10px;
}

.coin-grid-row.portfolio {
  margin-left: 0 !important;
  padding-top: 0;
  align-items: center;
}

.coin-grid-row.headings {
  font-size: 12px;
}

.coin-portfolio-text-container .ticker {
  padding-left: 15px;
  display: flex;
  align-items: center;
}

.chevron-container-dark.portfolio {
  margin-top: -100px;
}

.portfolio-popup-currency-symbol-label {
  right: unset !important;
  left: 22px;
  font-size: 18px;
  top: 15px !important;
}

.portfolio-popup-currency-symbol-label,
.portfolio-popup-currency-label {
  position: absolute;
  top: 16px;
  right: 12px;
  display: flex;
  align-items: center;
  padding-top: 1px;
}

.portfolio-menu-list-item-container {
  display: flex;
  justify-content: space-between;
  height: 45px;
}

.portfolio-menu-list-star-container img {
  width: 16px;
  height: 16px;
  margin: 0 0 3px 10px
}

.portfolio-menu-list-star-container {
  display: flex;
  align-items: center;
}

.portfolio .page-submenu-title {
  font-size: 18px;
}

.portfolio .submenu-item {
  font-size: 12px;
}

.portfolio .page-submenu-container {
  padding-bottom: 10px;
}

.small-text {
  font-size: 12px;
}

.portfolio-popup-title.list {
  font-size: 14px;
}

.portfolio-menu-list-item-container:hover,
.portfolioList .portfolio-popup-title-container:hover {
  color: #FF9017;
}

.search-search-box:focus-visible,
.portfolio-popup-name-input:focus-visible {
  outline: unset;
}

.coin-portfolio-title-container .col {
  padding-left: 0 !important;
}

.coin-portfolio-title-container .row {
  margin-left: 0 !important;
}

.code-embed-container {
  width: 95%;
  margin: auto;
}

.portfolio-popup-tab-input:focus-visible,
.portfolio-popup-tab-date:focus-visible {
  outline: none;
}

.portfolio-popup-button.watchlist {
  max-width: 234px;
  height: 51px;
}

.coin-portfolio-star-container {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.news-page-market-news-submenu .page-submenu-container .market-news-submenu-item,
.news-page-market-news-submenu .page-submenu-container .submenu-item {
  margin-right: 14px !important;
  font-size: 13px !important;
}

.news-page-market-news-submenu .page-submenu-container {
  padding-left: 25px;
}

.cse-plus-stars {
  width: 95%;
  height: 35vh;
  margin-left: 5%;
  object-fit: cover;
}

.cse-plus-planet-container {
  width: 100%;
}

.cse-plus-planet-container img {
  width: 100%;
  height: 180px;
  opacity: 40%;
  object-fit: cover;
  margin-top: -1px;
}

.cse-plus-title-logo {
  width: 193px;
  height: 96px;
  position: absolute;
  top: 10px;
  left: calc((100% - 193px) / 2 );
}

.cse-plus-title-logo img {
  width: 100%;
  height: 100%;
}

.cse-plus-your-membership-container {
  width: fit-content;
  display: flex;
  position: absolute;
  top: 10px;
  right: calc((100% - 1100px) / 2);
  padding-right: 20px;
}

.cse-plus-your-membership {
  padding-right: 5px;
}

.cse-plus-rocket-landing-container {
  position: relative;
}

.cse-plus-title-container {
  text-align: center;
  position: absolute;
  top: 120px;
  width: 100%;
  left: 0;
}

.cse-plus-welcome-message {
  font-size: 20px;
}

.cse-plus-rocket-success {
  font-size: 65px;
  padding-top: 30px;
}

.cse-plus-description {
  max-width: 700px;
  margin: auto;
  padding: 20px 20px 0px 20px;
}

.cse-plus-page-quick-links-container {
  position: relative;
  width: 100%;
  left: 0;
  margin-top: -275px;
}

.cse-plus-page-discord-trouble-container {
  text-align: center;
  padding-top: 40px;
}

.cse-plus-page-discord-trouble-text a {
  text-decoration: none;

}

.cse-plus-quick-links-container {
  max-width: 1100px;
  margin: auto;
}

.cse-plus-subscriptions-tickbox-container {
  margin-top: 30px;
  text-align: center;
}

.cse-quick-links-title {
  text-align: center;
  width: 100%;
  font-size: 30px;
  display: block;
}

.cse-quick-links-cards-container {
  width: fit-content;
  margin: 20px auto;
  display: flex;
}

.cse-plus-quick-link-card {
  width: 260px;
  height: 240px;
  margin: 10px;
  border-radius: 0 15px 15px 15px;
  position: relative;
}

.cse-plus-quick-link-card-logo {
  margin: 20px auto 10px auto;
  width: 40px;
  height: 40px;
}



.cse-plus-quick-link-card-title {
  width: 100%;
  text-align: center;
  font-size: 17px;
}

.cse-plus-quick-link-card-description {
  width: 100%;
  height: 80px;
  padding: 20px 12%;
  text-align: center;
  font-size: 16px;
}

.cse-plus-access-button {
  text-decoration: none;
  width: 141px;
  font-size: 14px;
  padding: 10px 0 10px 0;
  text-align: center;
  margin-left: calc((100% - 141px) / 2);
  border-radius: 5px;
  border: none;
}

.cse-plus-access-button:disabled {
  cursor: default !important;
  opacity: 50%;
}

.cse-plus-quick-link-locked {
  position: absolute;
  top: 10px;
  right: 10px;
}

.cse-plus-quick-link-locked img {
  width: 19px;
  height: 28px;
}

.cse-plus-quick-link-card-cover-container {
  position: absolute;
  top: 25px;
  left: 25px;
  height: 103px;
  width: 204px;
  display: none;
  border-radius: 20px;
}

.cse-plus-quick-link-card:hover .cse-plus-quick-link-card-cover-container {
  display: block;
}

.cse-plus-quick-link-card-cover {
  position: relative;
  width: 200px;
}

.cse-plus-quick-link-card-cover-image {
  position: absolute;
  top: 0;
  left: 0;
}

.cse-plus-quick-link-card-cover-text {
  text-align: center;
  width: 100%;
  margin-top: 20px;
}

.cse-plus-quick-link-card-link-text {
  text-align: center;
  display: block;
  width: fit-content;
  margin: 20px auto;
  padding-bottom: 3px;
}

.cse-plus-upgrade-container {
  margin-top: 64px;
}

.cse-plus-upgrade-title {
  font-size: 50px;
  text-align: center;
}

.cse-plus-upgrade-description {
  margin-top: 20px;
  text-align: center;
  width: 80%;
  margin-left: 10%;
}

.toggle-plus-period-container {
  display: flex;
}

.toggle-plus-period-container.disabled {
  pointer-events: none;
  opacity: 30%;
}

.cse-plus-monthly-toggle-container {
  width: fit-content;
  margin: 30px auto;
}

.cse-plus-current-plan-container {
  width: 350px;
  height: 42px;
  border-radius: 15px 15px 0 0;
  text-align: center;
  padding-top: 14px;
  font-size: 16px;
  margin-left: 8px;
}

.cse-plus-current-plan-container.plus {
  margin-left: calc(350px + 16px + 8px);
}

.cse-plus-current-plan-container.premium {
  margin-left: calc(700px + 32px + 8px);
}

.cse-plus-membership-cards-container {
  display: flex;
}

.cse-plus-membership-card-container {
  width: 350px;
  height: 1040px;
  margin: 0 8px;
  border-radius: 15px;
  padding: 40px 20px;
  position: relative;
}

.upgrade-button-container {
  padding-top: 75px;
}

.cse-plus-membership-card-container.active {
  border-radius: 0 0 15px 15px;
}

.cse-plus-membership-type {
  font-size: 20px;
}

.cse-plus-membership-cost-container {
  margin-top: 40px;
  display: flex;
  position: relative;
  justify-content: space-between;
}

.cse-plus-membership-cost {
  font-size: 50px;
}

.cse-plus-membership-cost-month {
  font-size: 14px;
  margin-left: 20px;
  margin-top: 15px;
  width: 50px;
}

.cse-plus-membership-cost-savings {
  font-size: 12px;
  margin-top: 17px;
  width: 80px;
  margin-left: auto;
}

.cse-plus-membership-yearly {
  font-size: 16px;
  margin-top: 2px;
}

.cse-plus-membership-description {
  margin-top: 30px;
  font-size: 15px;
  height: 40px;
}

.cse-plus-membership-divider {
  margin: 30px 0;
}

.cse-plus-membership-includes {
  font-size: 16px;
  height: 30px;
}

.cse-plus-membership-feature {
  display: flex;
  margin: 20px 0;
  font-size: 16px;
}

.cse-plus-membership-feature img {
  height: 19px;
  width: 19px;
}

.cse-plus-membership-feature-text {
  padding: 2px 10px 0 10px;
}

.cse-plus-features-container {
  height: 540px;
}

.cse-plus-upgrade-button {
  width: 160px;
  height: 42px;
  border: none;
  border-radius: 5px;
  margin-left: calc((100% - 160px) /2);
  margin-top: 10px;
  font-size: 14px;
}

.cse-plus-upgrade-button.plus {
  text-align: center;
}

.cse-plus-current-membership {
  position: absolute;
  top: 40px;
  right: 30px;
}

.cse-plus-tiers-title {
  text-align: center;
  margin-top: 130px;
  margin-bottom: 40px;
  font-size: 50px;
}

.cse-plus-tiers-title.smaller {
  margin-top: 100px;
}

.cse-plus-memberships-features-background-image {
  position: absolute;
  bottom: 0;
  z-index: 1;
}

.cse-plus-memberships-features-background-image.starter,
.cse-plus-memberships-features-background-image.premium {
  right: 0;
}

.cse-plus-memberships-features-background-image.plus {
  left: 0;
}

.cse-plus-memberships-features-container {
  position: relative;
  min-height: 750px;
}

.cse-plus-memberships-features-container .home-contents {
  z-index: 10;
  position: relative;
}

.cse-plus-memberships-title-container {
  padding-top: 90px;
  font-size: 35px;
}

.cse-plus-memberships-description-container {
  font-size: 18px;
  max-width: 700px;
  margin: 50px auto 0 auto;
}

.cse-plus-memberships-description-container,
.cse-plus-memberships-title-container{
  text-align: center;
}

.cse-plus-memberships-features-cards-container {
  margin-top: 70px;
  padding-bottom: 120px;
}

.cse-plus-membership-feature-card {
  height: 280px;
  border-radius: 0 15px 15px 15px;

}

.cse-plus-membership-feature-card.starter {
  height: 240px;
}

.cse-plus-membership-feature-card.plus {
  height: 280px;
}

.cse-plus-membership-feature-card-sub-description-container,
.cse-plus-membership-feature-card-description-container {
  width: 90%;
  padding: 20px 10px 0px 10px;
  margin: auto;
}

.home-contents.cse-plus {
  width: unset;
  margin-top: 0 !important;
}

.cse-plus-memberships-title-container.starter {
  padding-top: 25px;
}

.cse-plus-membership-feature-card-image-container {
  margin: 20px auto 0 auto;
  width: 60px;
  height: 60px;
}

.cse-plus-membership-feature-card-image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.cse-plus-membership-feature-card-title-container {
  margin-top: 25px;
}

.cse-plus-membership-feature-card-sub-description-container,
.cse-plus-membership-feature-card-description-container,
.cse-plus-membership-feature-card-title-container {
  text-align: center;
}

.cse-plus-membership-feature-card-sub-description-container {
  font-size: 12px;
}

.cse-plus-subscriptions-container {
  margin-top: 40px;
}

.cse-plus-upgrade-button.features {
  text-align: center;
  padding-top: 14px;
}

.cse-plus-memberships-features-upgrade-container{
  padding-bottom: 60px;
}

.cse-premium-one-to-one-find-out-more {
  margin: -60px auto 60px;
  text-align: center;
  font-size: 13px;
}

.cse-plus-login-container {
  display: flex;
  width: fit-content;
  margin: 20px auto 0 auto;
}

.cse-plus-sign-in-button {
 margin-right: 20px;
}

.cse-plus-register-button,
.cse-plus-sign-in-button {
  width: 145px;
  height: 42px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
}

.cse-plus-new-user-video-container {
  width: 510px;
  height: 300px;
  margin: 50px auto 50px auto;
}

.cse-plus-already-have-an-account{
  text-align: center;
  margin-top: 50px;
}

.cse-plus-already-have-an-account-log-in {
  margin: 20px auto 0 auto;
  border-bottom: 1px solid ;
  text-align: center;
  width: fit-content;
  padding-bottom: 5px;
}

.cse-plus-bottom-padding {
  height: 20px;
  width: 100%;
}

.profile-discord-container {
  display: flex;
  height: fit-content;
  align-items: center;
  border-radius: 13px;
  padding: 0 20px
}

.profile-discord-input-container {
  display: flex;
  align-items: center;
}

.profile-discord-container .profile-element-container {
  height: 100px;
}

.membership-and-billing-container {
  width: 100%;
  height: 243px;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  padding: 30px;
}

.membership-and-billing-separator {
  margin-top: 50px;
  width: 70%;
  margin-left: 5%;
}

.membership-and-billing-parent-container {
  margin-top: 40px;
}

.membership-and-billing-image-container {
  position: absolute;
  top: -220px;
  right: -40px;
  pointer-events: none;
  z-index: 5;
}

.membership-and-billing-membership-container {
  display: flex;
  margin-top: 30px;
}

.membership-and-billing-membership-container.period {
  margin-top: 10px;
}

.membership-and-billing-title {
  font-size: 20px;
  margin-top: 20px;
}

.membership-and-billing-membership-label {
  padding-right: 20px;
  font-size: 14px;
  margin-top: 2px;
}

.membership-and-billing-type {
  font-size: 18px;
}

.membership-and-billing-buttons-container {
  width: 100%;
  height: 35px;
  margin-top: 40px;
  display: flex;
}

.membership-and-billing-payment-button,
.membership-and-billing-upgrade-button {
  border: none;
  height: 35px;
  padding: 0 30px;
  border-radius: 5px;
  display: unset;
  width: fit-content;
  z-index: 20;
  position: relative;
  font-size: 12px;
}

.membership-manage-buttons-container {
  margin-left: auto;
  width: fit-content;
}

.membership-and-billing-payment-button {
  margin-right: 20px;
}

.quiz-question-options-button-selected,
.quiz-question-options-button {
  display: flex;
  margin-top: 10px;
  padding: 10px 10px 10px 10px;
  margin-left: 60px;
  width: 85%;
}

.quiz-question-option-label {
  font-size: 14px;
  text-align: center;
  padding-right: 10px;
}

.quiz-question-option-value {
  width: 100%;
  font-size: 14px;
  text-align: left;
  padding-right: 10px;
}

.quiz-question-options-button-selected,
.quiz-question-options-button {
  border: none;
  border-radius: 0 10px 10px 10px;
  display:flex;
  overflow: clip;
  overflow-x:hidden;
}

.quiz-question-options-button-selected,
.quiz-question-options-button {
  margin-bottom: 10px;
  padding: 20px 40px 20px 20px;
  position: relative;
}

.quiz-question-options-button:hover {
  border-radius: 0 10px 10px 10px;
  overflow: clip;
  overflow-x:hidden;
  transition: all 0.3s ease-in-out;
  transition-property: box-shadow, border;
}


.quiz-question-option-label-selected {
  font-size: 14px;
  text-align: center;
  padding-right: 10px;
}

.quiz-question-option-value-selected  {
  font-size: 14px;
  text-align: left;
  width: 100%;
  padding-right: 10px;
}

.quiz-submit-message-container {
  padding-top: 200px;
}

.quiz-message {
  text-align: center;
  font-size: 22px;
  width: 400px;
  margin-left: 200px;
}

.quiz-results-page-brush-up-on {
  position:relative;
  margin: 10px 15px auto;
  display:inline-block;
  padding: 10px 30px 10px 30px;
  border-radius: 25px;
}

.quiz-results-page-brush-up-on {
  font-size: 12px;
  color: #213055;
}

.learn-category-free-label {
  position: absolute;
  top: 17px;
  right: 3px;
  transform: rotate(45deg);
  font-size: 15px;
}


.learn-category-free-triangle {
  width: 135px !important;
  height: 115px !important;
  position: absolute;
  background-color: #FF9017;
  right: -77px;
  top: -58px;
  transform: rotate(45deg);
}

.learn-category-cse-plus-logo-container {
  margin-top: 20px;
  height: 80px;
  width: fit-content;
}

.module-page-cse-plus-logo-container {
  position: absolute;
  top: 0;
  right: 10px;
  height: 82px;
  width: 165px;
}

.learn-category-cse-plus-logo-container img,
.module-page-cse-plus-logo-container img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}


.portfolio-edit-menu-container {
  width: 640px;
  height: 360px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100;
  left: calc(50% - 640px/2);
  top: calc(50% - 360px/2);
  font-size: 14px;
}

.portfolio-edit-menu-row {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40%;
  padding: 0 5%;
}

.portfolio-edit-menu-row.spaced {
  justify-content: space-between;
}

.portfolio-edit-menu-divider {
  border-top: solid 1px #9EAAC6;
  opacity: 50%;
  width: 90%;
}

.portfolio-edit-menu-image-container {
  width: 18px;
  height: 18px;
  margin-right: 20px;
}

.portfolio-edit-menu-default-container {
  display: flex;
}

.portfolio-menu a,
.portfolio-popup-card-title-item a {
  color: unset;
  text-decoration: none;
}

.portfolio-popup-cross.portfolio-list img {
  width: 26px;
  height: 26px;
}

.portfolio-popup-cross.portfolio-list {
  display: flex;
  align-items: center;
}

.portfolio-popup-card.portfolioList.dropdown {
  z-index: 100;
}

.portfolio-single.home-contents,
.portfolio.home-contents {
  padding: 0 10px
}

.addcoin .portfolio-popup-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 25px 0;
}

.addcoin .portfolio-popup-cross {
  position: relative;
  left: 11px;
}

.addcoin .search-search-box-container {
  margin: 10px 25px;
}

.portfolio-coin-grid-row-button .coin-search-grid-single-row-container {
  padding: 10px 25px;
}

.portfolio-popup-card-cross-button-container {
  display: flex;
  justify-content: flex-end;
}

.portfolio-popup-card-cross-button-container .portfolio-popup-cross {
  right: 15px;
  top: 10px;
  left: unset;
}

.portfolio-popup-tab-input-container.quantity {
  margin-bottom: 0;
}

.portfolio-popup-tab-subtitle-container.price-per {
  margin: 10px 0;
}

.portfolio-popup-button-container.submit {
  justify-content: flex-start;
}

.portfolio-popup-tab-date-container input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.portfolio-popup-tab-date-container input[type="datetime-local"]::-webkit-datetime-edit-fields-wrapper{
  display: none;
}

.portfolio-popup-tab-date-container input[type="datetime-local"] {
  flex-direction: row-reverse;
}

.portfolio-popup-holdings-menu-button.sell {
  position: relative;
  z-index: 0;
}

.portfolio-popup-tab-date-display,
.portfolio-popup-tab-date,
.portfolio-popup-radio-card {
  height: 50px;
}

.portfolio-popup-tab-subtitle-container.transfer {
  margin: 10px 0;
}

.discord-popup-container {
  position: fixed;
  width: 730px;
  height: 500px;
  left: calc(50% - 730px/2);
  top: calc(50% - 500px/2);
  z-index: 101;
  border-radius: 21px;
  padding: 50px 40px;
}

.discord-popup-container.submitted {
  height: 360px;
  top: calc(50% - 360px/2);
}

.discord-popup-header-container {
  height: 20%;
  display: flex;
  justify-content: space-between;
}

.discord-popup-body-container {
  height: 90%;
  position: relative;
  top: -10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.discord-popup-header-logo-container img {
  width: 165px;
  height: 80px;
}

.discord-popup-body-discord-logo img {
  width: 60px;
  height: 45px;
}

.discord-popup-body-heading {
  font-size: 25px;
  text-align: center;
  padding: 0 15%;
}

.discord-popup-body-input {
  width: 400px;
  height: 42px;
  border-radius: 8px;
  border: none;
  outline: none;
  padding: 0 20px;
}

.discord-popup-body-submit-button {
  width: 145px;
  height: 42px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.discord-popup-body-later-text {
  font-size: 15px;
}

.home-plan-section-container {
  width: 100%;
  display: flex;

}

.home-plan-content {
  padding: 50px 0 120px 0;
}

.home-plan-image-container,
.home-plan-text-container {
  width: 50%;
  height: 282px;
}

.home-plan-image-container img {
  object-fit: cover;
  width: 100%;
  height: 282px;
}

.home-plan-banner-container .chevron-container-dark {
  margin-top: -30px;
}

.home-plan-banner-container .chevron-container-light {
  margin-top: -30px;
}

.home-plan-text-container {
  padding: 40px 0 40px 160px;
}

.home-plan-upgrade-your-plan {
  font-size: 18px;
}

.home-plan-title {
  font-size: 30px;
  margin-top: 20px;
}

.home-plan-description {
  font-size: 16px;
  margin-top: 15px;
}

.home-plan-cse-plus-upgrade-button {
  width: 125px;
  height: 29px;
  border: none;
  border-radius: 5px;
  font-size: 10px;
  margin-top: 30px;
}

.upgrade-options-popup-container {
  width: 825px;
  height: 400px;
  position: fixed;
  left: calc(50% - 825px/2);
  top: calc(50% - 400px/2);
  z-index: 1000;
  border-radius: 21px;
  padding: 30px;
}

.upgrade-options-popup-header {
  display: flex;
  justify-content: flex-end;
}

.upgrade-options-popup-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}

.upgrade-options-popup-body-logo-container img {
  width: 165px;
  height: 82px;
}

.upgrade-options-popup-body-logo-container,
.upgrade-options-popup-body-main-text {
  padding-bottom: 30px;
}

.upgrade-options-popup-body-main-text {
  font-size: 25px;
  text-align: center;
}

.upgrade-options-popup-body-small-text {
  padding-bottom: 45px;
  font-size: 15px;
  text-align: center;
}

.upgrade-options-popup-body-button {
  width: 182px;
  height: 42px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.upgrade-options-popup-background-container {
  position: absolute;
  bottom: 5px;
  left: 5px;
}

.upgrade-options-popup-background-container img {
  width: 340px;
  height: 340px;
}

.portfolio-edit-menu-row.top {
  border-radius: 10px 10px 0 0;
}

.portfolio-edit-menu-row.bottom {
  border-radius: 0 0 10px 10px;
}

.profile-form-container {
  display: block;
  z-index: 10;
}

.change-membership-pop-up-container {
  width: 870px;
  height: 650px;
  position: fixed;
  top: 20%;
  left: calc(50vw - 435px);
  margin: auto;
  z-index: 100;
  border-radius: 10px;
}

.change-membership-pop-up-container.confirmation {
  height: 460px;
}

.change-membership-pop-up-container.complete {
  height: 250px;
  width: 600px;
  left: calc(50vw - 300px);
}

.change-membership-pop-up-top-container {
  position: relative;
  width: 100%;
  height: 110px;
}

.change-membership-pop-up-image-container {
  height: 100px;
  position: absolute;
  top: 20px;
  left: 20px;
}

.change-membership-pop-up-image-container img {
  height: 100%;
  object-fit: contain;
}

.change-membership-pop-up-title-container {
  width: 100%;
  text-align: center;
  padding-top: 40px;
  font-size: 20px;
}

.change-membership-body-container-toggle-container {
  width: fit-content;
  margin: 10px auto;
}

.change-membership-cards-container {
  display: flex;
  width: 100%;
  height: 350px;
  padding: 10px 40px;
}

.change-membership-card-container {
  width: 33%;
  height: 100%;
  margin: 10px;
}

.change-membership-card {
  width: 100%;
  height: calc(100% - 30px);
  border-radius: 20px;
  margin-top: 30px;
  position: relative;
}

.change-membership-card.starter.active {
  height: 100%;
  margin-top: 0;
}

.change-membership-card.plus.active {
  height: 100%;
  margin-top: 0;
}

.change-membership-card.premium.active {
  height: 100%;
  margin-top: 0;
}

.change-membership-card-current-plan {
  width: 100%;
  text-align: center;
  margin-top: -20px;
  font-size: 13px;
}

.change-membership-card-content-container {
  padding: 20px;
}

.change-membership-card-title {
  margin-top: 20px;
  font-size: 16px;
}

.change-membership-card-price-container {
  display: flex;
  margin-top: 10px;
  margin-left: -2px;
}

.change-membership-card-price {
  font-size: 39px;
}

.change-membership-card-price {
  width: 60%;
}
.change-membership-card-period-savings-container {
  width: 40%;
}

.change-membership-card-period {
  margin-top: 5px;
  font-size: 14px;
}

.change-membership-card-savings {
  font-size: 12px;
  margin-top: 5px;
  margin-left: 15px;
}

.change-membership-card-price-period {
  margin-top: 10px;
  font-size: 14px;
}

.change-membership-card-description-container {
  margin-top: 20px;
  height: 52px;
  font-size: 13px;
}

.change-membership-card-divider {
  margin: 20px 0;
}

.change-membership-selector-container {
  display: flex;
  width: fit-content;
  margin: auto;
}

.change-membership-label {
  margin-top: 5px;
  font-size: 13px;
}

.change-membership-selector-radio-circle {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}

.change-membership-selector-radio-circle img {
  width: 50%;
  margin-top: 1px;
}

.change-membership-buttons-container {
  height: 42px;
  width: fit-content;
  margin: 40px auto;
  display: flex;
}

.change-membership-button {
  width: 145px;
  height: 100%;
  margin: 0 10px;
  border: none;
  font-size: 14px;
  border-radius: 2px;
}

.change-membership-button:disabled {
  opacity: 40%;
  cursor: default;
}

.change-membership-confirmation-container {
  width: 100%;
  height: 100%;
  padding: 70px;
}

.change-membership-confirmation-title {
  font-size: 25px;
}

.change-membership-confirmation-memberships-container {
  height: 120px;
  margin-top: 40px;
  width: 100%;
  display: flex;
}

.change-membership-confirmation-membership {
  width: calc(50% - 30px);
  height: 100%;
  border-radius: 20px;
  padding: 30px;
}

.change-membership-confirmation-membership.current {
  margin-right: 30px;
}


.change-membership-confirmation-membership.new {
  margin-left: 30px;
}

.change-membership-confirmation-card-title {
  font-size: 18px;
}

.change-membership-confirmation-card-plan {
  margin-top: 20px;
  font-size: 13px;
}

.change-membership-confirmation-plan-start{
  margin-top: 40px;
  font-size: 15px;
}

.change-membership-confirmation-plan-buttons-container {
  margin-top: 40px;
  height: 42px;
}

.change-membership-complete-title {
  text-align: center;
  width: 100%;
  margin-top: 70px;
  font-size: 25px;
}

.change-membership-complete-button-container {
  height: 42px;
  margin: 60px auto;
  width: fit-content;
}

.cse-plus-membership-cost-savings.plus {
  text-align: end;
}

.cse-plus-membership-early-bird {
  text-align: end;
  font-size: 12px;
  padding-top: 10px;
}

.cse-plus-membership-early-bird-container {
  display: flex;
  flex-direction: column;
  width: 100px;
  text-align: end;
}

.cse-plus-subscriptions-tab-container {
  position: relative;
  height: 42px;
}

.portfolio-transactions-coin-name-container {
  display: flex;
  text-decoration: none;
}

.portfolio-transactions-coin-name-container:link,
.portfolio-transactions-coin-name-container:visited,
.portfolio-transactions-coin-name-container:hover,
.portfolio-transactions-coin-name-container:active {
  color: unset;
}

.navbar-top-row {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  padding-bottom: 15px;
  min-height: 41px;
}

.navbar-top-row.fixed-nav {
  display: none;
}

.register-col {
  margin-left: 15px;
}

.translate-col {
  margin-left: 15px;
}

.deals-page-navigation-container {
  width: 100%;
  display: flex;
  z-index: 20;
}

.deals-page-tabs-container {
  width: 60%;
  margin-top: 10px;
  z-index: 20;
}

.deals-page-search-bar-container {
  width: 40%;
  height: 52px;
  z-index: 20;
}

.deals-page-title {
  font-size: 40px;
  z-index: 10;
  margin: 10px 0;
  position: relative;
}

.deals-search-box-container {
  z-index: 10;
  width: 100%;
}

.deals-page-card-container {
  width: 100%;
  height: 261px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 15px;
  padding: 20px;
  display: flex;
  position: relative;
}

.deals-page-card-image-container {
  width: 40%;
  height: 100%;
}

.deals-page-card-image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.deals-page-content-container {
  width: 60%;
  height: 100%;
  padding: 2px 0 64px 30px;
  position: relative;
}

.deals-page-card-type {
  font-size: 14px;
  margin: 10px 0;
  height: 15px;
}

.deals-page-card-title {
  font-size: 21px;
  margin: 15px 0;
  max-height: 44px;
}

.deals-page-card-description {
  font-size: 14px;
  margin: 15px 0;
  min-height: 30px;
  max-height: 50px;
}

.deals-page-card-button-container {
  margin-top: auto;
  height: 42px;
  width: 155px;
  position: absolute;
  bottom: 0;
}

.deals-page-card-button {
  width: 155px;
  height: 42px;
  border-radius: 5px;
  padding: 13px;
  text-align: center;
  font-size: 14px;
}

.deals-search-box-container input[type=text] {
  font-size: 12px;
  padding: 7px 5px 7px 40px;
}


.deals-page-cards-container {
  min-height: 600px;
}

.deals-cards-container {
  position: relative;
}

.nav-bar-external-link {
  width: 16px;
  height: 16px;
  margin-top: -3px;
  margin-left: 10px;
}

.header-right-container {
  margin-left: auto;
  display: flex;
  width: fit-content;
}

.profile-discord-image-container {
  padding: 10px 0
}

.profile-discord-input-container .profile-element-label .link {
  margin-left: 30px;
  width: 20px;
}

.discord-username-popup-container {
  position: fixed;
  top: calc((100% - 260px) / 2);
  left: calc((100% - 580px) / 2);
  width: 580px;
  height: 260px;
  border-radius: 13px;
  z-index: 100;
  padding: 20px;
}

.discord-username-popup {
  position: relative;
}

.discord-username-popup-container-close {
  position: absolute;
  top: 0;
  right: 0;
}

.discord-username-popup-content-container {
  display: flex;
}

.modal-open {
  overflow: hidden;
  height: 100vh !important;
}

.discord-username-popup-right-container {
  width: 55%;
}

.discord-username-popup-left-container {
  width: 45%;
}

.discord-username-popup-right-container,
.discord-username-popup-left-container {
  height: 138px;
  margin-top: 20px;
}

.discord-username-popup-right-container img {
  height: 100%;
  width: 110%;
  margin-left: -10%;
  object-fit: contain;
}

.discord-username-popup-description,
.discord-username-popup-save-message {
  font-size: 12px;
  margin-top: 10px;
}

.discord-username-popup-title {
  font-size: 20px;
  margin-bottom: 30px;
}

.discord-username-popup-description {
  margin-bottom: 20px;
  padding-right: 20px;
  position: relative;
}

.one-stop-container-mobile {
  padding: 0 30px;
  text-align: center;
}

.one-stop-container-mobile .one-stop-actions {
  margin: 40px 20px;
}

.one-stop-container-mobile .one-stop-head {
  width: 100%;
}

.latest-news-header {
  position: absolute;
  top: 30px;
  left: 25px;

  z-index: 50;
  width: 102px;
  height: 21px;
  padding: 6px 0;
  border-radius: 3px;
  font-size: 9px;
  text-align: center;
}

.news-selection-indicator-mob {
  display: flex;
  position: relative;
  top: -30px !important;
  left: 20px !important;
}

.card-button-mob {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 3px;
  font-size: 6px;
  padding: 2px 10px;
}

.news-read-more-button-mob {
  width: 70px;
  height: 16px;
  margin-right: 40px;
}

.home-page-news-information-container-mob {
  display: flex;
  justify-content: space-between;
}

.news-author-mob {
  font-size: 10px;
  margin-bottom: 6px;
  margin-top: 5px;
}

.home-page-news-info-subcontainer-mob {
  width: 40%;
  display: flex;
  justify-content: space-between;
}

.home-news-container {
  z-index: 10;
  position: relative;
}

.home-coins-container-mob {
  z-index: 2;
  position: relative;
}

.home-coins-container-mob .chevron-container-light {
  margin-top: 0 !important;
}

.home-coins-container-mob .home-coins-content {
  margin-top: -50px;
}

.home-coins-container-mob .home-section-coins-contents {
  padding-bottom: 0;
  padding-top: 20px;
  height: fit-content;
}

.home-coins-container-mob .home-section-coins-contents.card-container {
  justify-content: center;
}

.home-coins-container-mob .card-container {
  width: 100%;
}

.home-info-container-mobile {
  position: relative;
  z-index: 0;
  padding-bottom: 40px;
}

.home-info-container-mobile .chevron-container-light {
  z-index: 0;
  position: sticky;
  margin-top: -1px;
}

.home-section-heading-mobile {
  font-size: 16px;
  margin-left: 60px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.news-page-mobile-tabs {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
  border-radius: 0 15px 0 0;
}

.home-coins-row,
.home-section-coins-grid-container {
  width: 100%
}

.home-section-news-button-container {
  height: 30px;
}

.home-page-crypto-news-main-story-image {
  animation: fadeIn 1s;
}

.home-section-coins-grid-head-mob {
  font-size: 10px;
  padding-bottom: 0;
  width: 100%;
}

.home-section-coins-grid-body-mob {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 12px;
}

.home-section-coins-grid-head-mob.row,
.home-section-coins-grid-body-mob.row {
  margin: 0;
}

.home-coins-container-mob .home-section-coins-grid .row .col-sm-4 {
  width: unset;
}

.home-coins-container-mob .home-section-coins-grid-col-number {
  width: unset !important;
  padding: 5px 15px 5px 5px !important;
}

.home-coins-container-mob .home-section-coins-grid-body-text {
  font-size: 12pt;
  margin-top: 3px !important;
}

.home-coins-container-mob .home-section-coin-info {
  display: flex;
  flex-direction: row;
  margin: auto;
}

.home-coins-container-mob .home-section-coins-coins .col {
  padding: unset !important;
}

.home-coins-container-mob .home-section-coin-logo {
  height: 25px;
  margin-right: 5px;
}

.home-coins-container-mob .home-section-coin-name {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.home-coins-container-mob .home-section-coin-24h,
.home-coins-container-mob .home-section-coin-price {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: unset !important;
}

.home-coins-container-mob .home-section-coin-coin {
  margin-left: 5px !important;
}

.home-coins-container-mob .home-section-star {
  height: 20px !important;
  width: 20px !important;
  padding: unset !important;
  margin: auto !important;
}

.home-section-info-container .col-sm-7 {
  width: 58%;
}

.home-section-info-container .col-sm-5 {
  width: 42%;
}

.home-section-info-container .card-image img {
  top: 0;
  bottom: 0;
  right: -30px;
}

.news-page-trending-heading-mobile {
  padding: unset;
  margin: 60px 0 15px 10px;
  font-size: 10px;
  z-index: 20;
  position: relative;
}

.news-page-trending-heading.main {
  width: 100%;
  text-align: center;
  margin: unset;
  height: 27px;
  padding: 8px 0 0 0;
}

.news-page-trending-heading.latest.selected,
.most-read {
  border-radius: 0 15px 0 0;
}

.news-visible-recent {
  padding: 30px;
  height: 100%;
}

.news-visible-recent.trending {
  margin-left: 20px;
}

.news-border {
  border-bottom: solid 0.5px rgba(112, 112, 112, .27);
  margin: 2px 10px;
}

.most-read-details {
  display: flex;
  padding: 15px 5px;
  line-height: 1.2;
}

.most-read-number {
  font-size: 18px;
  padding: unset;
  margin-right: 10px;
  max-width: 50px;
}

.news-page-news-list.mobile {
  width: 100%;
  padding: 0 20px;
  position: relative;
  z-index: 1;
}

.home-section-course-text-mobile {
  max-width: 65% !important;
  padding: 12px 0 0 20px !important;
  z-index: 2;
}

.home-section-course-button-mobile {
  width: 65px !important;
  margin-top: 5px !important;
  margin-bottom: -3px;
  border: none;
  border-radius: 3px;
  padding: 3px 0;
  font-size: 6px
}

.home-section-course-title-mobile {
  margin-bottom: 10px;
  max-height: 20px;
  overflow-y: hidden;
  font-size: 13.5pt;
}

.mobile-search-icon img {
  width: 27px;
  margin-top: 4px;
  margin-right: 15px;
}

.news-page-container-mobile .news-page-main-story {
  margin-top:  0;
}

.home-navbar-background-container,
.home-navbar-container,
.navbar,
.header-content,
.navbar-top-row {
  transition: all .2s ease-in-out;
}

.stripe-success-page-container {
  min-height: 15vh;
}

.stripe-success-processing-message {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 60px;
  font-size: 25px;
}

.stripe-success-loading-wheel {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin-left: calc((100% - 80px) / 2);
  margin-top: 30px;
}

.stripe-success-loading-wheel div {
  transform-origin: 40px 40px;
  animation: stripe-success-loading-wheel 1.2s linear infinite;
}

.stripe-success-loading-wheel div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
}

.stripe-success-loading-wheel div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.stripe-success-loading-wheel div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.stripe-success-loading-wheel div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.stripe-success-loading-wheel div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.stripe-success-loading-wheel div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.stripe-success-loading-wheel div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.stripe-success-loading-wheel div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.stripe-success-loading-wheel div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.stripe-success-loading-wheel div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.stripe-success-loading-wheel div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.stripe-success-loading-wheel div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.stripe-success-loading-wheel div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes stripe-success-loading-wheel {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.profile-discord-outer-container {
  display: flex;
  margin-top: 25px;
  align-items: center;
}

.profile-discord-link-button {
  width: 114px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 5px;
  font-size: 12px;
  margin: 1px 0 0 25px;
}

.navbar-aligner {
  margin-bottom: 150px;
}

.hex-reward-text-claim-status {
  margin-top: 5px;
  font-size: 12px;
  color: #FF9017;
}

.referral-reward-container {
  width: 90%;
}

.rewards-grid-header {
  display: flex;
  width: 100%;
  font-size: 12px;
}

.rewards-grid-row-container {
  font-size: 13px;
}

.rewards-grid-header .id,
.rewards-grid-row-container .id {
  width: 20px;
  text-align: left;
  margin: 12px 1%;
}

.rewards-grid-header .email,
.rewards-grid-row-container .email {
  width: 200px;
  text-align: left;
  margin: 12px 1%;
}

.rewards-grid-header .type,
.rewards-grid-row-container .type {
  width: 150px;
  text-align: left;
  margin: 12px 1%;
}

.rewards-grid-header .cashback,
.rewards-grid-row-container .cashback {
  width: 100px;
  text-align: left;
  margin: 12px 1%;
}

.rewards-grid-header .commission,
.rewards-grid-row-container .commission {
  width: 100px;
  text-align: left;
  margin: 12px 1%;
}

.rewards-grid-header .status,
.rewards-grid-row-container .status {
  width: 120px;
  text-align: left;
  margin: 12px 1%;
}

.rewards-grid-header .claim,
.rewards-grid-row-container .claim {
  width: 20px;
  margin: 5px 0;
}

.rewards-grid-header .claim {
  margin: 12px 1%;
}

.rewards-grid-row-container .claim img {
  height: 20px;
  margin-left: 10px;
}

.rewards-grid-row-container {
  display: flex;
  padding: 5px 0;
}

.module-card-container {
  width: 255px;
  height: 326px;
  border-radius: 0 11px 11px;
  position: relative;
}

/*Selects all module-card-links that are preceded by another module-card-link*/
a.module-card-link ~ a.module-card-link {
  margin-left: 26px;
}

.module-card-image-container {
  position: relative;
  width: 100%;
  height: 116px;
  border-radius: 0 11px 0 0;
}

.module-card-image-container img {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.module-card-details-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 116px);
  border-radius: 0 0 11px 11px;
  padding: 15px;
}

.module-card-difficulty-time {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 10px;
}

.module-card-difficulty-container {
  display: flex;
  align-items: center;
}

.module-card-difficulty-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

.module-card-completion-bar-container {
  height: 10px;
  width: 100%;
  border-radius: 5px;
}

.module-card-completion-bar {
  height: 100%;
  border-radius: 5px;
}

.module-card-title {
  font-size: 17px;
  margin-bottom: 15px;
  max-height: 57px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.module-card-description {
  font-size: 14px;
  margin-bottom: 10px;
  height: 44px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.module-card-completion-percentage {
  font-size: 12px;
  margin-bottom: 5px;
}

.module-card-positional-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-between;
}

.module-card-progress-wheel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  transform: rotate(270deg);
}

.module-points-text {
  font-size: 12px;
}

.module-points {
  font-size: 16px;
}

.module-card-progress-wheel-points-container {
  margin: 4px 0 2px;
}

.module-card-progress-wheel {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  top: calc(50% - 100px/2);
}

.module-card-progress-wheel-text {
  position: absolute;
  font-size: 11px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
}

.top-ten-leaderboard-container {
  width: 50%;
  height: 486px;
  border-radius: 15px;
  z-index: 10;
}

.top-ten-leaderboard-pedestal-container {
  width: 100%;
  height: 40%;
  border-radius: 15px 15px 0 0;
  position: relative;
}

.top-ten-leaderboard-users-container {
  width: 100%;
  height: 60%;
  border-radius: 0 0 15px 15px;
  padding: 5px 40px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.top-ten-leaderboard-pedestal {
  position: absolute;
  width: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 0;
}

.top-ten-leaderboard-pedestal.gold {
  left: calc(50% - 140px/2);
}

.top-ten-leaderboard-pedestal.silver {
  left: calc(33% - 140px/2);
}

.top-ten-leaderboard-pedestal.bronze {
  left: calc(67% - 140px/2);
}

.top-ten-leaderboard-pedestal-platform {
  width: 100%;
}

.top-ten-leaderboard-pedestal-platform.gold {
  height: 90px;
}

.top-ten-leaderboard-pedestal-platform.silver {
  height: 43px;
}

.top-ten-leaderboard-pedestal-platform.bronze {
  height: 20px;
}

.top-ten-leaderboard-pedestal-name {
  font-size: 14px;
  margin: 10px auto;
  text-align: center;
}

.top-ten-leaderboard-pedestal-medal-container {
  position: relative;
}

.top-ten-leaderboard-pedestal-medal-number {
  position: absolute;
  font-size: 14px;
  top: 22%;
}

.top-ten-leaderboard-pedestal-medal-number.gold {
  left: 40%;
}

.top-ten-leaderboard-pedestal-medal-number.silver {
  left: 35%;
}

.top-ten-leaderboard-pedestal-medal-number.bronze {
  left: 38%;
}

.top-ten-leaderboard-top-row-container {
  display: flex;
  justify-content: space-between;
  padding: 30px 20px 0 40px;
}

.top-ten-leaderboard-title {
  font-size: 20px;
  margin-right: 30px;
}

.top-ten-leaderboard-selector-container {
  display: flex;
  font-size: 11px;
  text-align: center;
  align-items: center;
}

.slider-toggle-background.leaderboard {
  display: flex;
  align-items: center;
  width: 52px;
  height: 24px;
  margin: 0 8px;
}

.top-ten-leaderboard-user-segment-container {
  width: 261px;
  height: 43px;
  border-radius: 22px;
  display: flex;
  align-items: center;
  padding: 5px 13px;
}

.top-ten-leaderboard-user-segment-number {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.top-ten-leaderboard-user-segment-name {
  font-size: 14px;
  margin-left: 20px;
  width: calc(60% - 20px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.top-ten-leaderboard-user-segment-name:hover {
  overflow: visible;
  white-space: normal;
  text-overflow: unset;
}

.top-ten-leaderboard-user-segment-points {
  font-size: 14px;
  width: calc(40% - 34px);
  text-align: end;
}

.top-ten-leaderboard-users-column {
  padding: 0 8px;
}

.top-ten-leaderboard-users-column-wrapper {
  display: flex;
  justify-content: center;
}

.top-ten-leaderboard-link-button {
  width: 131px;
  height: 29px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}

.course-user-card-container {
  width: 439px;
  height: 226px;
  border-radius: 16px;
  padding: 15px 35px;
}

.course-user-card-container.slim {
  width: 536px;
  height: 113px;
  display: flex;
  align-items: center;
}

.course-user-card-section {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
}

.course-user-card-section.slim {
  margin-bottom: 20px;
}

.course-user-card-image-container {
  width: 63px;
  height: 63px;
  border-radius: 50%;
}

.course-user-card-image-container.slim {
  width: 40px;
  height: 40px;
}

.course-user-card-username {
  font-size: 20px;
  padding-bottom: 5px;
}

.course-user-card-username.slim {
  font-size: 17px;
}

.course-user-card-realname {
  font-size: 15px;
}

.course-user-card-realname.slim {
  font-size: 12px;
}

.course-user-card-name-container {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.course-user-card-detail-header {
  font-size: 15px;
  height: 20px;
}

.course-user-card-detail-header.slim {
  font-size: 13px;
}

.course-user-card-detail {
  font-size: 30px;
  padding-right: 30px;
}

.course-user-card-detail.slim,
.course-user-card-detail-value.slim {
  font-size: 20px;
}

.course-user-card-sub-detail {
  font-size: 13px;
  height: 15px;
  padding-top: 1px;
}

.course-user-card-sub-detail.slim {
  width: 70px;
}

.course-user-card-detail-value,
.course-user-card-detail-header.central {
  padding: 0 25px;
}

.course-user-card-sub-detail.central {
  padding: 1px 24px 0;
}

.course-user-card-detail-container.central {
  margin: 0 30px;
}

.course-user-card-detail-container.central.slim {
  margin: 0 20px;
}

.course-user-card-image-container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.course-user-card-image-container.default {
  display: flex;
  justify-content: center;
  align-items: center;
}

.course-user-card-image-container.default img {
  width: 18px;
  height: unset;
  border-radius: unset;
}

.new-course-banner-container {
  width: 100%;
  position: relative;
  margin-bottom: 50px;
}

.new-course-banner-text-container {
  width: 100%;
  height: 100%;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
}

.new-course-banner-image-container {
  width: 55%;
  height: 100%;
  position: absolute;
  right: 0;
  z-index: 0;
  clip-path: polygon(30% 0, 100% 0, 100% 100%, 0% 100%);
}

.new-course-banner-new-course-text {
  margin-bottom: 20px;
  font-size: 18px;
}

.new-course-banner-out-now-text {
  font-size: 30px;
  max-width: 50%;
  margin-bottom: 20px;
}

.new-course-banner-description {
  font-size: 16px;
  max-width: 50%;
  margin-bottom: 20px;
}

.new-course-banner-button {
  width: 115px;
  height: 29px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  cursor: pointer;
}

.new-course-banner-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.leaderboard-container {
  width: calc(50% - 8px);
  height: 997px;
  border-radius: 15px;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.leaderboard-title {
  font-size: 20px;
}

.leaderboard-table-headings {
  width: 100%;
  height: 43px;
  margin: 35px 0 15px;
  padding: 0 55px;
  display: flex;
  align-items: center;
}

.leaderboard-table-heading {
  font-size: 14px
}

.leaderboard-table-heading.leaderboard-rank,
.leaderboard-user-row-rank-container {
  width: 20%;
}

.leaderboard-table-heading.leaderboard-user,
.leaderboard-user-row-user-container {
  width: 60%;
  overflow: hidden;
}

.leaderboard-table-heading.leaderboard-points,
.leaderboard-user-row-points-container {
  width: 20%;
}

.leaderboard-user-row-points-container {
  padding-left: 30px;
}

.leaderboard-user-row-container {
  width: calc(100%);
  height: 38px;
  border-radius: 22px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.leaderboard-user-row-rank {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leaderboard-table-page-menu {
  width: 400px;
  height: 31px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px;
  margin: 15px 0 10px;
}

.leaderboard-page-nav-button {
  width: 51px;
  height: 21px;
  display: flex;
  align-items: center;
  font-size: 10px;
  border-radius: 5px;
  padding: 0 8px;
  justify-content: space-between;
  cursor: pointer;
}

.leaderboard-page-nav-button.next-page {
  flex-direction: row-reverse;
}

.leaderboard-page-nav-button img {
  height: 10px;
}

.leaderboard-page-number-button {
  width: 24px;
  height: 21px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  margin: 0 5px;
}

.leaderboard-page-number-button.hidden {
  display: none;
}

.leaderboard-table-page-menu-numbers-container {
  display: flex;
  width: 150px;
  justify-content: center;
}

.leaderboard-table-pages-showing {
  width: 200px;
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.leaderboard-table-users-container {
  height: 720px; /*(38px row height + 7px margin per row) * 16 rows*/
  min-height: 720px;
  width: calc(100% - 104px);
  margin: 0 57px;
}

.leaderboard-page-header-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  margin-top: 15px;
}

.leaderboard-page-body-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.leaderboard-page-title {
  font-size: 40px;
  margin-bottom: 30px;
}

.leaderboard-page-description {
  font-size: 14px;
}

.leaderboard-page-title-container {
  width: 47%;
}

.learn-page-title-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 100;
  margin-bottom: 40px;
}

.learn-page-title-content {
  width: 43%;
  padding-right: 30px;
}

.learn-page-title {
  font-size: 40px;
  margin: 32px 0 20px
}

.learn-page-welcome {
  font-size: 20px;
  margin-bottom: 30px;
}

.learn-page-description {
  font-size: 14px;
  margin-bottom: 25px;
}

.learn-page-get-started {
  font-size: 12px;
  margin-bottom: 50px;
}

.learn-page-container {
  position: relative;
}

.learn-page-dropdown-menu-container {
  display: flex;
  position: relative;
  top: -10px;
}

.learn-page-dropdown-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 31px;
  border-radius: 6px;
  margin-left: 15px;
  font-size: 12px;
  position: relative;
  cursor: pointer;
}

.learn-page-dropdown-reset-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 31px;
  width: 31px;
  border-radius: 6px;
  margin-left: 15px;
  cursor: pointer;
}

.learn-page-dropdown-button-container.large-button,
.large-button .learn-page-dropdown-menu {
  width: 142px;
}

.learn-page-dropdown-button-container.medium-button,
.medium-button .learn-page-dropdown-menu {
  width: 105px;
}

.learn-page-dropdown-button-container.small-button,
.small-button .learn-page-dropdown-menu {
  width: 82px;
}

.learn-page-dropdown-button {
  display: flex;
}

.learn-page-dropdown-button-title {
  margin-right: 5px;
  text-align: center;
  max-width: 100px;
}

.learn-page-dropdown-button .open-menu {
  transform: rotate(90deg);
}

.learn-page-dropdown-button .closed-menu {
  transform: rotate(-90deg)
}

.learn-page-dropdown-menu {
  border-radius: 0 0 6px 6px;
  position: absolute;
  top: 25px;
  z-index: 99999999;
}

.learn-page-dropdown-menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 30px;
  width: 100%;
}

.learn-page-dropdown-menu-item.last-item {
  border-radius: 0 0 6px 6px;
}

.learn-page-dropdown-menu-divider {
  width: 80%;
  margin: 4px auto 1px;
}

.learn-category-collection-container {
  padding: 20px 0 !important;
}

.learn-category-collection-title-container {
  display: flex;
}

.learn-category-collection-see-all-container {
  display: flex;
  margin-left: auto;
  width: fit-content;
  height: 18px;
}

.learn-category-collection-see-all-text {
  font-size: 15px;
  width: fit-content;
  padding-right: 10px;
  padding-top: 3px;
}

.see-all-arrow {
  width: 16px;
  height: 11px;
  object-fit: contain;
  margin-top: 3px;
}

.learn-category-collection-title {
  font-size: 18px;
  margin: 0 10px 35px;
}

.learn-category-collection-cards {
  display: flex;
  width: fit-content;
  position: relative;
  padding: 10px;
  transition: right 1s;
}

.hide-overflow {
  overflow-x: hidden;
  position: relative;
}

.collection-obscurer {
  position: absolute;
  width: 100px;
  height: 349px;
  top: 50px;
  z-index: 10;
  display: flex;
  align-items: center;
  padding: 15px;
}

.collection-obscurer.left-overflow {
  justify-content: flex-start;
  border-radius: 0 0 0 11px;
  left: 0;
}

.collection-obscurer.right-overflow {
  justify-content: flex-end;
  border-radius: 0 11px 11px 0;
  right: 0;
}

.collection-obscurer-button {
  width: 59px;
  height: 59px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.collection-obscurer-button img {
  height: 25px;
  width: 13px;
}

.module-card-link,
.module-card-link:hover,
.module-card-link:visited,
.leaderboard-link,
.leaderboard-link:hover,
.leaderboard-link:visited,
.new-course-banner-button,
.new-course-banner-button:hover,
.new-course-banner-button:visited {
  text-decoration: none;
  color: unset;
}

.home-contents-extended {
  max-width: 1120px;
  margin: auto;
}

.banner-wrapper {
  position: relative;
  display: flex;
  height: 100%;
}

.profile-referrals-mobile-button {
  width: fit-content;
  height: 40px;
  padding: 10px 20px;
  border-radius: 5px;
  margin-left: 20px;
  font-size: 14px;
}

.profile-referrals-mobile-button {
  display: none;
}

.learn-page-title-container,
.learn-page-intro {
  margin-top: -280px;
}

.learn-page-intro {
  z-index: 10;
  padding-bottom: 100px;
}

.chevron-container-dark.learn-page {
  margin-top: 200px;
}


.quiz-start-page-point-option-containers {
  display: flex;
  height: 55px;
  width: fit-content;
  margin: 18px auto;
}

.quiz-start-page-points-subtitle {
  text-align: center;
  margin-top: 60px;
  font-size: 13px;
}

.quiz-start-page-point-card {
  width: 132px;
  display: flex;
  margin: 0 6px;
  border-radius: 16px;
  padding: 3px;
}

.quiz-start-page-point-card-image {
  margin: 5px 1px 5px 5px;
  width: 45px;
  height: 45px;
  object-fit: scale-down;
}

.quiz-start-page-point-card-image.MERIT {
  margin: 10px 1px 10px 5px;
  width: 45px;
  height: 35px;
  object-fit: fill;
}

.quiz-start-page-point-card-text-container {
  margin: 12px 0;
}

.quiz-start-page-score-name {
  font-size: 11px;
}

.quiz-start-page-score-points {
  font-size: 16px;
}

.quiz-submit-button-locked,
.quiz-submit-button {
  border: none;
}

.quiz-results-points-subtitle {
  margin-top: 15px;
  text-align: center;
  font-size: 16px;
}

.quiz-results-points-card-container {
  margin: 10px auto;
  width: fit-content;
}

.quiz-result-page-course-complete-title-container {
  width: 80%;
  margin: 30px auto;
  padding-bottom: 25px;
}

.quiz-result-page-module-overview-container {
  width: 80%;
  margin: 30px auto;
}

.quiz-result-page-module-overview-title {
  text-align: center;
  font-size: 15px;
}

.quiz-result-page-course-complete-title {
  text-align: center;
  font-size: 35px;
  padding: 7px;
}

.quiz-result-page-course-complete-congratulations {
  text-align: center;
  font-size: 22px;
  padding-bottom: 10px;
}

.quiz-result-page-course-complete-message {
  text-align: center;
}

.quiz-result-page-module-overview-chapters-container {
  display: flex;
  width: fit-content;
  margin: 10px auto;
}

.quiz-result-page-module-overview-chapter {
  width: 43px;
  margin: 0 10px;
  text-align: center;
}

.module-overview-chapter-number {
  font-size: 12px;
}

.module-overview-chapter-merit {
  margin-top: 12px;
}

.module-overview-chapter-result {
  width: 17px;
  height: 17px;
  border-radius: 17px;
  margin: 7px 13px;
}

.quiz-result-page-stats-container {
  width: 90%;
  margin: 30px auto;
}

.quiz-result-stats-card {
  width: 100px;
  height: 100px;
  border-radius: 16px;
  margin: 9px auto;
}

.quiz-result-stats-card-large {
  width: 100%;
  height: 100px;
  border-radius: 16px;
  margin: 9px auto;
}

.quiz-result-stats-card-container {
  width: 100%;
}

.quiz-results-card-label.large {
  margin: 0 !important;
}

.quiz-results-card-label {
  font-size: 15px;
  text-align: center;
  margin: auto;
  width: fit-content;
  height: 16px;
  overflow: hidden;
  white-space: nowrap;
}

.quiz-result-stats-card-title {
  width: 50%;
  margin: 12px auto;
  text-align: center;
  height: 35px;
}

.quiz-result-stats-card-title.long {
  width: 90%;
}

.quiz-result-stats-card-title img {
  height: 35px;
}

.quiz-result-stats-card-value {
  font-size: 30px;
  width: fit-content;
  margin: 12px auto;
  text-align: center;
  display: flex;
}

.quiz-result-stats-card-value.large {
  margin-top: 30px;
  font-size: 50px;
}

.quiz-result-stats-card-value.large span {
  font-size: 25px;
  margin-top: 20px;
  width: fit-content;
}

.quiz-result-stats-card-value span {
  font-size: 15px;
  margin-top: 15px;
  width: fit-content;
}

.quiz-results-passed-container,
.quiz-results-merit-container {
  width: 100%;
  display: flex;
}

.quiz-results-merit-left-container,
.quiz-results-merit-right-container {
  width: 50%;
}

.quiz-results-passed-right-container {
  width: 60%;
  display: flex;
  justify-content: space-evenly;
}

.quiz-results-passed-left-container {
  width: 40%;
  padding-right: 40px;
}

.quiz-results-merit-left-container {
  display: flex;
  justify-content: space-evenly;
}

.quiz-results-merit-right-container {
  padding-left: 40px;
}

.quiz-finished-module-button-text {
  padding: 7px 30px;
  font-size: 12px;
}

.quiz-results-finished-module-button-container {
  width: fit-content;
  margin: 60px auto 20px;
}

.module-card-col {
  margin-bottom: 35px;
}

.learn-page-nav-menu-section {
  width: 100%;
}

.learn-page-nav-menu-section.page-menu-section {
  overflow-x: auto;
}

.module-card-membership-tier-banner {
  position: absolute;
  top: -11px;
  left: -13px;
  z-index: 10;
}

.module-card-membership-tier-banner-text {
  position: absolute;
  font-size: 17px;
  transform: rotate(-42deg);
  top: 32px;
  left: 18px;
}

.checkout-acceptance-message {
  text-align: justify;
  margin-top: 30px;
  font-size: 12px;
}

.checkout-acceptance-message span {
  padding: 0 6px;
}

.checkout-acceptance-message span input {
  margin-left: -6px;
}


/*  TODO: Translate Dropdown */
body > .skiptranslate {
  display: none;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

@media print {
  #google_translate_element {
    display: none;
  }
}

.goog-te-gadget {
  font-size: 0 !important;
}

.goog-te-gadget span {
  display: none;
}

.goog-te-combo {
  width: fit-content;
  height: 100%;
  margin: 0 !important;
  padding: 5px 20px;
  border-radius: 18px;
}

.goog-te-combo option[value="af"],
.goog-te-combo option[value="sq"],
.goog-te-combo option[value="am"],
.goog-te-combo option[value="hy"],
.goog-te-combo option[value="as"],
.goog-te-combo option[value="ay"],
.goog-te-combo option[value="az"],
.goog-te-combo option[value="bm"],
.goog-te-combo option[value="eu"],
.goog-te-combo option[value="be"],
.goog-te-combo option[value="bho"],
.goog-te-combo option[value="bs"],
.goog-te-combo option[value="bg"],
.goog-te-combo option[value="ca"],
.goog-te-combo option[value="ceb"],
.goog-te-combo option[value="ny"],
.goog-te-combo option[value="co"],
.goog-te-combo option[value="hr"],
.goog-te-combo option[value="cs"],
.goog-te-combo option[value="da"],
.goog-te-combo option[value="dv"],
.goog-te-combo option[value="doi"],
.goog-te-combo option[value="nl"],
.goog-te-combo option[value="eo"],
.goog-te-combo option[value="et"],
.goog-te-combo option[value="ee"],
.goog-te-combo option[value="tl"],
.goog-te-combo option[value="fi"],
.goog-te-combo option[value="fy"],
.goog-te-combo option[value="gl"],
.goog-te-combo option[value="ka"],
.goog-te-combo option[value="el"],
.goog-te-combo option[value="gn"],
.goog-te-combo option[value="gu"],
.goog-te-combo option[value="ht"],
.goog-te-combo option[value="ha"],
.goog-te-combo option[value="haw"],
.goog-te-combo option[value="iw"],
.goog-te-combo option[value="hmn"],
.goog-te-combo option[value="hu"],
.goog-te-combo option[value="is"],
.goog-te-combo option[value="ig"],
.goog-te-combo option[value="ilo"],
.goog-te-combo option[value="ga"],
.goog-te-combo option[value="it"],
.goog-te-combo option[value="jw"],
.goog-te-combo option[value="kn"],
.goog-te-combo option[value="kk"],
.goog-te-combo option[value="km"],
.goog-te-combo option[value="rw"],
.goog-te-combo option[value="gom"],
.goog-te-combo option[value="ko"],
.goog-te-combo option[value="kri"],
.goog-te-combo option[value="ku"],
.goog-te-combo option[value="ckb"],
.goog-te-combo option[value="ky"],
.goog-te-combo option[value="lo"],
.goog-te-combo option[value="la"],
.goog-te-combo option[value="lv"],
.goog-te-combo option[value="ln"],
.goog-te-combo option[value="lt"],
.goog-te-combo option[value="lg"],
.goog-te-combo option[value="lb"],
.goog-te-combo option[value="mk"],
.goog-te-combo option[value="mai"],
.goog-te-combo option[value="mg"],
.goog-te-combo option[value="ms"],
.goog-te-combo option[value="ml"],
.goog-te-combo option[value="mt"],
.goog-te-combo option[value="mi"],
.goog-te-combo option[value="mr"],
.goog-te-combo option[value="mni-Mtei"],
.goog-te-combo option[value="lus"],
.goog-te-combo option[value="mn"],
.goog-te-combo option[value="my"],
.goog-te-combo option[value="ne"],
.goog-te-combo option[value="no"],
.goog-te-combo option[value="or"],
.goog-te-combo option[value="om"],
.goog-te-combo option[value="ps"],
.goog-te-combo option[value="fa"],
.goog-te-combo option[value="pl"],
.goog-te-combo option[value="qu"],
.goog-te-combo option[value="ro"],
.goog-te-combo option[value="sm"],
.goog-te-combo option[value="sa"],
.goog-te-combo option[value="gd"],
.goog-te-combo option[value="nso"],
.goog-te-combo option[value="sr"],
.goog-te-combo option[value="st"],
.goog-te-combo option[value="sn"],
.goog-te-combo option[value="sd"],
.goog-te-combo option[value="si"],
.goog-te-combo option[value="sk"],
.goog-te-combo option[value="sl"],
.goog-te-combo option[value="so"],
.goog-te-combo option[value="su"],
.goog-te-combo option[value="sw"],
.goog-te-combo option[value="sv"],
.goog-te-combo option[value="tg"],
.goog-te-combo option[value="ta"],
.goog-te-combo option[value="tt"],
.goog-te-combo option[value="te"],
.goog-te-combo option[value="th"],
.goog-te-combo option[value="ti"],
.goog-te-combo option[value="ts"],
.goog-te-combo option[value="tr"],
.goog-te-combo option[value="tk"],
.goog-te-combo option[value="ak"],
.goog-te-combo option[value="uk"],
.goog-te-combo option[value="ug"],
.goog-te-combo option[value="uz"],
.goog-te-combo option[value="vi"],
.goog-te-combo option[value="cy"],
.goog-te-combo option[value="xh"],
.goog-te-combo option[value="yi"],
.goog-te-combo option[value="yo"],
.goog-te-combo option[value="zu"]
{
  display: none;
}
/*  TRANSLATE STUFF */

.cse-plus-additional-features-title-container {
  padding-bottom: 16px;
  font-size: 16px;
  margin-top: 30px;
  border-bottom: 1px solid #5C6D96;
}

.cse-plus-membership-feature-description {
  padding-bottom: 40px;
}