body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.05 !important;
    letter-spacing: -1px;
}

button,
.newsletter-text,
.one-stop-text,
.news-headline,
.card-text,
.home-learn-2-earn-container,
.learn-2-earn-text,
.news-story-subheading,
.story-list-headline,
.news-story-content,
.exchange-subpage-text,
.wallet-subpage-text,
.learn-page-intro-text,
.learn-module-card-text,
.learn-category-card-text,
.course-section-block-text-large,
.course-section-block-text,
.coin-data-analysis-description,
.footer-topic-heading,
.hardware-wallet-intro-text li,
.messages,
.crypto-dashboard-intro-text,
.info-panel-container,
.learn-category-text,
.course-page-subtitle,
.learn-category-text,
.related-news-story-heading,
.encyclopedia-description,
.dialog-header,
.dialog-text,
.market-data-grid-header,
.info-article-content-heading h2,
.encyclopedia-word-image-text,
.learning-objective,
.learn-module-card-blurb,
.affiliate-link,
.affiliate-popup-text,
.affiliate-close,
.cse-plus-quick-link-card-description,
.cse-plus-membership-description,
.cse-plus-membership-feature-text,
.cse-plus-membership-feature-card-sub-description-container,
.cse-plus-membership-feature-card-description-container,
.cse-premium-one-to-one-find-out-more,
.change-membership-card-description-container,
.module-card-title,
.checkout-acceptance-message {
    line-height: 1.2;
}

.tou-publish-container,
.tou-section-number,
.tou-section-content,
.tou-section-number-title,
.how-funded-section-number,
.how-funded-section-content,
.how-funded-section-number-title,
.cse-plus-description,
.cse-plus-upgrade-description,
.cse-plus-memberships-description-container,
.home-plan-description,
.discord-username-popup-description,
.deals-page-card-description,
.new-course-banner-description {
    line-height: 1.4;
}
.news-story-paragraph-break,
.newsletter-signup-form-container,
.quiz-start-page-description,
.info-article-content-text,
.encyclopedia-body-content,
.hardware-wallet-intro-text {
    line-height: 1.6;
}

.home-section-info-text > div {
    font-size: 15px !important;
    font-family: cseGothamBook, serif;
    display: inline;
}

.home-section-info-text,
.info-article-offer-text,
.wallet-subpage-guide-text{
    line-height: 1.2 !important;
}

@font-face {
    font-family: cseGothamBlack;
    src: url("/public/fonts/Gotham-Black.otf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: cseGothamBook;
    src: url("/public/fonts/Gotham-Book.otf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: cseGothamBold;
    src: url("/public/fonts/Gotham-Bold.otf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: cseGothamMedium;
    src: url("/public/fonts/Gotham-Medium.otf") format("opentype");
    font-display: swap;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-menu-container li:hover,
.info-article-content-text b,
.tou-section-content b,
.how-funded-section-content b,
.info-article-content-text span {
    font-family: cseGothamBold, serif !important;
}

.header-link,
.home-settings,
.home-settings-register-button,
.login-button,
.one-stop-head,
.newsletter-heading,
.one-stop-subscribe-btn,
.home-section-news-button,
.coin-portfolio-title-button,
.newsletter-button,
.learn-2-earn-button,
.home-section-course-button,
.home-section-course-button-mobile,
.subscribe-button,
.news-page-card-button,
.news-page-card-carousel-button,
.market-news-button-mob,
.info-page-summary-button,
.learn-module-card-button,
.learn-category-card-button,
.course-section-block-button,
.learn-page-intro-button,
.news-overlay,
.news-author,
.news-page-story-author,
.news-story-paragraph-break h1,
.news-story-paragraph-break h2,
.news-headline,
.tab-heading,
.card-title,
.card-heading,
.card-button,
.info-article-offer-link,
.coin-text-heavy,
.home-section-heading,
.home-section-heading-mobile,
.home-section-trending-heading,
.home-section-recommend-heading,
.home-section-info-howto-heading,
.news-page-trending-heading,
.news-story-trending,
.wallet-subpage-howto-heading,
.home-section-tag,
.home-section-main-news-heading,
.home-section-news-headline,
.learn-2-earn-heading,
.home-section-course-title,
.home-section-course-title-mobile,
.home-section-course-value,
.home-section-coins-button,
.home-section-coins-grid-button,
.news-page-card-button,
.news-page-card-carousel-button,
.market-news-button-mob,
.info-page-summary-button,
.home-section-info-title,
.home-section-coins-title,
.learn-module-card-heading,
.home-section-info-guide-title,
.wallet-subpage-guide-title,
.home-section-coins-grid-head,
.home-section-coins-more,
.footer-subscribe-title,
.footer-topic-heading,
.legal-text,
.subscribe-x,
.news-page-heading,
.info-page-heading,
.coin-page-heading,
.encyclopedia-submenu-heading a.active,
.page-menu-container li.active,
.page-submenu-heading li.active,
.news-page-market-title,
.news-page-most-read-title,
.page-submenu-title,
.news-page-market-story-heading,
.news-page-market-story-tag,
.market-news-author,
.news-page-most-read-counter,
.news-page-most-read-story-headline,
.news-story-heading,
.info-article-heading,
.story-list-text .news-author,
.story-list-headline,
.story-social-heading,
.info-page-nav:active,
.info-subpage-heading,
.info-article-howto,
.info-article-content-heading h1,
.info-article-contents-list-heading,
.info-article-social-media-heading,
.content-section-menu-heading,
.ticker-24hr-label,
.ticker-section-top-gainer,
.ticker-section-top-loser,
.ticker-coin-name,
.ticker-coin-price,
.ticker-coin-change,
.learn-page-intro-heading,
.learn-page-intro-subheading,
.learn-modules-heading,
.learn-categories-heading,
.learn-category-card-heading,
.learn-module-completion-result,
.learn-category-heading,
.learn-module-heading,
.course-page-title,
.learn-category-subheading,
.course-page-about,
.learn-module-menu,
.course-content-menu,
.course-section-block-heading,
.course-section-block-text-large h2,
.course-section-block-text h2,
.trending-card-button,
.coin-grid-button,
.coin-grid-header,
.rewards-grid-header,
.coin-grid-row .coin .name,
.coin-grid-row .price,
.coin-detail-summary-name,
.coin-detail-stats-header,
.coin-detail-stats-text,
.coin-data-statistics-heading,
.coin-data-analysis-description h1,
.coin-data-analysis-vote .heading,
.coin-data-analysis-vote-choose button,
.login-heading,
.learning-objectives-title,
.quiz-page-module-bc,
.quiz-page-module-bc a:visited,
.quiz-page-module-bc a:hover,
.quiz-page-module-bc a:link,
.quiz-page-module-bc a:active,
.quiz-page-chapter-bc,
.quiz-page-chapter-bc a:visited,
.quiz-page-chapter-bc a:hover,
.quiz-page-chapter-bc a:link,
.quiz-page-chapter-bc a:active,
.quiz-page-bc-separator,
.quiz-exit-header-button-text,
.quiz-start-page-title,
.quiz-start-page-question-count,
.quiz-start-button,
.quiz-return-button,
.quiz-submit-button-locked,
.quiz-submit-button,
.quiz-previous-button,
.quiz-next-button,
.quiz-question-progress-fraction,
.quiz-result-users-score-message,
.quiz-result-users-score,
.quiz-back-to-course-button-text,
.quiz-retake-button-text,
.quiz-popup-title,
.encyclopedia-subpage-heading,
.encyclopedia-title,
.encyclopedia-article-heading,
.encyclopedia-body-content h1,
.crypto-dashboard-heading,
.crypto-dashboard-welcome-message,
.crypto-dashboard-welcome-user,
.crypto-dashboard-progress-wheel-level,
.crypto-dashboard-progress-container-merits-complete-score,
.crypto-dashboard-progress-completed-module-number,
.search-page-list-type,
.search-page-list-headline,
.search-page-coin-name,
.search-page-list-title,
.search-page-submenu-heading,
.search-page-title,
.profile-page-tab-title,
.newsletter-subscribe-form-title,
.newsletter-subscribe-form-sub-title,
.user-rocket-display-name,
.user-rocket-display-level,
.home-section-coins-grid-body-text.name,
.home-section-coins-grid-col-price.home-section-coins-grid-body-text,
.course-page-summary-level-value,
.course-page-summary-text,
.module-button,
.course-section-block-text h3,
.course-section-block-text-large h3,
.coin-price-change .coin-text-up,
.coin-price-change .coin-text-down,
.search-page-get-more,
.search-page-get-more-chev,
.sign-up-privacy-link-text.link,
.login-action-text,
.learn-category-completion-result,
.learn-category-modules-completed,
.hardware-wallet-intro-list-heading,
.quiz-popup-submit-button-text,
.quiz-popup-close,
.recovery-page-login-return-text,
.recovery-page-mailapp-button,
.tou-title,
.tou-section-title,
.tou-section-number,
.tou-section-number-title,
.how-funded-title,
.how-funded-section-title,
.how-funded-section-number,
.how-funded-section-number-title,
.related-news-title,
.related-news-story-heading,
.profile-image-upload-button,
.profile-details-save-button,
.profile-image-save-button,
.profile-page-tab-action,
.quiz-continue-course-button-text,
.profile-page-tab-action,
.dialog-header,
.dialog-button-text,
.market-data-grid-header,
.info-article-content-heading h2,
.info-article-content-text ol li::before,
.encyclopedia-word-image-text,
.encyclopedia-word-image-text-definition,
.back-to-top-button,
.close-modal,
.market-data-grid-row .price,
.market-data-grid-row .plus-depth,
.market-data-grid-row .minus-depth,
.market-data-grid-row .day-volume,
.loading,
.card-button-mob,
.portfolio-popup-button,
.latest-news-header,
.trending-card-title,
.loser-card-title,
.gainer-card-title,
.bold,
.coin-portfolio-title,
.news-title,
.news-title-trending,
.most-read-number,
.news-page-trending-heading-mobile,
.news-author-mob,
.burger-menu,
.encyclopedia-read-more-button,
.newsletter-signup-heading-container,
.coin-portfolio-edit-button,
.portfolio-popup-cross,
.newsletter-signup-form-button,
.page-not-found-message-container,
.page-not-found-title-container,
.profile-referrals-title,
.referral-reward-heading,
.profile-referrals-hex-number,
.referral-progress-wheel-heading,
.referral-progress-wheel-inner-text-count,
.profile-referrals-invite-heading,
.profile-referrals-invite-button-label,
.cookies-pop-up-text,
.coin-portfolio-card,
.tou-paragraph-container th,
.portfolio-transactions-coin-name,
.transaction-grid-header,
.portfolio-coin-search-name,
.portfoio-popup-title.portfolio-name,
.portfolio-popup-addcoin-title,
.portfolio-chart-coin-percentage,
.coin-grid-row.headings,
.portfolio-popup-title,
.portfolio-popup-holdings-menu-button,
.portfolio-popup-addcoin-title,
.cse-plus-rocket-success,
.cse-plus-your-membership,
.cse-plus-your-membership-type,
.cse-quick-links-title,
.cse-plus-access-button,
.cse-plus-quick-link-card-title,
.cse-plus-upgrade-title,
.cse-plus-upgrade-description span,
.cse-plus-current-plan-container,
.cse-plus-membership-type,
.cse-plus-membership-cost,
.cse-plus-membership-cost-month,
.cse-plus-membership-yearly span,
.cse-plus-upgrade-button,
.cse-plus-tiers-title,
.cse-plus-memberships-title-container,
.cse-plus-membership-feature-card-title-container,
.cse-premium-one-to-one-find-out-more a,
.cse-plus-register-button,
.cse-plus-sign-in-button,
.cse-plus-already-have-an-account-log-in,
.cse-plus-already-have-an-account,
.cse-plus-page-discord-trouble-text a,
.membership-and-billing-title,
.discord-popup-body-heading,
.discord-popup-body-submit-button,
.discord-popup-body-later-text,
.home-plan-upgrade-your-plan,
.home-plan-cse-plus-upgrade-button,
.upgrade-options-popup-body-main-text,
.upgrade-options-popup-body-button,
.change-membership-pop-up-title-container,
.change-membership-card-current-plan,
.change-membership-card-title,
.change-membership-card-price,
.change-membership-card-period,
.change-membership-card-price-period span,
.change-membership-label,
.change-membership-button,
.change-membership-confirmation-title,
.change-membership-complete-title,
.deals-page-title,
.deals-page-card-title,
.deals-page-card-button,
.discord-username-popup-title,
.profile-discord-link-button,
.module-card-title,
.module-card-completion-percentage,
.top-ten-leaderboard-pedestal-medal-number,
.top-ten-leaderboard-title,
.top-ten-leaderboard-link-button,
.new-course-banner-button,
.course-user-card-username,
.course-user-card-detail-header,
.course-user-card-detail,
.new-course-banner-new-course-text,
.leaderboard-title,
.leaderboard-page-title,
.learn-page-title,
.learn-page-welcome,
.learn-category-collection-title,
.learn-category-collection-see-all-text,
.profile-referrals-mobile-button,
.quiz-start-page-score-points,
.quiz-start-page-points-subtitle,
.quiz-result-page-course-complete-congratulations,
.quiz-result-page-course-complete-title,
.module-overview-chapter-number,
.quiz-result-stats-card-value,
.quiz-finished-module-button-text,
.module-card-membership-tier-banner-text {
    font-family: cseGothamBold, serif;
}

.one-stop-text,
.newsletter-text,
.news-time-expired,
.news-tab-time-expired,
.card-text,
.coin-text-light,
.learn-2-earn-text,
.home-section-course-label,
.home-section-info-text,
.wallet-subpage-guide-text,
.info-article-offer-text,
.footer-subscribe-text,
.footer-topic-text,
.subscribe-banner-text,
.encyclopedia-submenu-heading a,
.encyclopedia-breadcrumb,
.learn-chapter-breadcrumb,
.learn-category-breadcrumb,
.news-story-breadcrumb,
.info-article-breadcrumb,
.leaderboard-breadcrumb,
.coin-detail-breadcrumb,
.portfolio-breadcrumb,
.portfolio-transactions-breadcrumb,
.news-story-subheading,
.story-list-text .news-time-expired,
.news-story-content,
.encyclopedia-submenu-item li,
.info-page-nav,
.most-read-news-time-expired,
.exchange-subpage-text,
.wallet-subpage-text,
.info-article-content-text,
.info-article-like-text,
.learn-page-intro-text,
.learn-module-card-text,
.learn-category-card-text,
.chapter-progress,
.learn-module-card-module-score-label,
.learn-module-card-completion-value,
.course-page-subtitle,
.learn-category-text,
.learn-module-text,
.chapter-listen-button-text,
.course-section-block-text-large,
.course-section-block-text,
.coin-grid-footer-contents button,
.coin-grid-footer-showing,
.coin-detail-summary-pair,
.coin-data-statistics-subheading,
.coin-data-analysis-description,
.coin-data-analysis-vote,
.coin-data-analysis-voted-text,
.coin-data-analysis-voted-choice,
.coin-data-analysis-voted-result,
.login-input,
.learning-objective,
.quiz-start-page-description,
.quiz-question,
.quiz-results-generic-message,
.quiz-popup-description,
.encyclopedia-search-box-container input[type=text],
.encyclopedia-subpage-text,
.encyclopedia-description,
.encyclopedia-body-content,
.crypto-dashboard-intro-text,
.crypto-dashboard-edit-profile,
.crypto-dashboard-progress-modules-completed,
.search-page-coin-symbol,
.search-search-box,
.profile-element-text-box-container,
.profile-page-tab-description,
.newsletter-subscribe-form-required-text,
.crypto-dashboard-progress-container-merits-complete-label,
.user-rocket-display-x,
.course-page-summary-level,
.course-page-summary-quizzes,
.course-page-summary-chapters,
.course-section-block-text,
.course-section-block-text-large,
.course-section-opener,
.course-section-opener-large,
.user-menu-email-container,
.sign-up-edit-box,
.newsletter-subscribe-form-radio,
.profile-form-radio,
.newsletter-subscribe-form-consent-message,
.register-privacy-text,
.crypto-dashboard-progress-wheel-view_rockets,
.learn-category-score-label,
.hardware-wallet-intro-text,
.hardware-wallet-intro-text li,
.login-subheading,
.recovery-page-resend-container,
.messages,
.info-panel-container,
.tou-section-content,
.how-funded-section-content,
.profile-page-tab-text,
.profile-page-newsletter-consent-label,
.quiz-result-users-merit-message,
.profile-page-newsletter-consent-label,
.dialog-text,
.info-article-content-step-text-container p,
.learn-module-card-blurb,
.register-login,
.login-register,
.search-page-nothing-found-message,
.book,
.info-article-progress-table-title,
.affiliate-link,
.search-page-list-description,
.affiliate-popup-text,
.affiliate-close,
.newsletter-signup-form-container,
.affiliate-close,
.profile-referrals-description,
.portfolio-popup-card.addPortfolio,
.toggle-cookie-message,
.toggle-cookie-container,
.tou-publish-container,
.tou-paragraph-container table,
.tou-paragraph-container td,
.transaction-grid-row .date,
.portfolio-coin-search-symbol,
.portfolio-popup-addcoin-symbol,
.portfolio-popup-radio-text,
.portfolio-popup-tab-input.quantity,
.portfolio-popup-tab-input-max,
.portfolio-popup-tab-input-coin-symbol,
.portfolio-popup-tab-input,
.portfolio-chart-coin-name,
.portfolio-popup-currency-label,
.cse-plus-description,
.cse-plus-quick-link-card-description,
.cse-plus-upgrade-description,
.cse-plus-membership-yearly,
.cse-plus-membership-description,
.cse-plus-membership-feature,
.cse-plus-page-discord-trouble-text,
.cse-plus-memberships-description-container,
.cse-plus-membership-feature-card-description-container,
.cse-plus-membership-feature-card-sub-description-container,
.cse-premium-one-to-one-find-out-more,
.portfolio-popup-currency-label,
.portfolio-popup-tab-input-coin-symbol,
.home-plan-description,
.upgrade-options-popup-body-small-text,
.change-membership-card-price-period,
.change-membership-card-description-container,
.deals-page-card-description,
.discord-username-popup-save-message,
.discord-username-popup-description,
.module-card-difficulty-time,
.module-card-description,
.top-ten-leaderboard-user-segment-name,
.course-user-card-realname,
.course-user-card-sub-detail,
.new-course-banner-description,
.leaderboard-user-row-user-container,
.leaderboard-page-nav-button,
.leaderboard-table-pages-showing,
.rewards-grid-row-container,
.leaderboard-page-description,
.learn-page-description,
.quiz-start-page-score-name,
.quiz-result-page-course-complete-message,
.quiz-result-stats-card-title,
.quiz-result-stats-card-value span,
.checkout-acceptance-message,
.cse-plus-membership-feature-description{
    font-family: cseGothamBook, serif;
}

.encyclopedia-page-letters,
.newsletter-subscribe-form-close,
.learn-category-free-label,
.home-plan-title,
.new-course-banner-out-now-text {
    font-family: cseGothamBlack, serif;
}

.deals-search-box-container input[type=text],
.home-search-box-container input[type=text],
.news-date,
.page-submenu-heading li,
.page-menu-container li,
.learn-category-card-time-text,
.course-section-card-text,
.profile-section-card-text,
.course-section-take-quiz-button,
.coin-grid-footer-pages-container,
.coin-page-top100-text,
.coin-detail-summary-ticker,
.coin-detail-summary-price,
.coin-detail-summary-whitepaper,
.coin-detail-summary-web,
.coin-detail-summary-web a:visited,
.coin-detail-summary-web a:hover,
.coin-detail-summary-web a:link,
.coin-detail-summary-web a:active,
.coin-detail-summary-whitepaper a:visited,
.coin-detail-summary-whitepaper a:hover,
.coin-detail-summary-whitepaper a:link,
.coin-detail-summary-whitepaper a:active,
.coin-detail-summary-rank,
.coin-detail-stats-info,
.coin-data-statistics-text,
.coin-data-statistics-value,
.login-text,
.quiz-result-users-score-label,
.quiz-results-page-brush-up-message,
.crypto-dashboard-profile-name,
.search-page-number-results,
.profile-element-label,
.profile-page-theme-toggle-label,
.newsletter-subscribe-form-error-message,
.news-story-read-time,
.news-story-listen,
.home-section-coins-grid-body-text,
.course-section-title,
.coin-grid-row,
.user-menu-name-container,
.menu-user-menu-settings-button,
.menu-user-menu-preferences-button,
.menu-user-menu-logout-button,
.chapter-score-text,
.sign-up-invalid-email,
.crypto-dashboard-how-to-get-started,
.learn-category-modules-completed-label,
.learn-module-completion-percentage,
.learn-category-content-area,
.locked-view-certification-text,
.coin-page-show-rows-text,
.coin-page-show-rows,
.market-data-grid-row,
.medium,
.market-data-grid-row,
.more-guides-container,
.burger-menu-dropdown-submenu,
.info-article-progress-container,
.page-not-found-description-container,
.page-not-found-home-button,
.profile-referrals-link,
.hex-reward-text,
.referral-progress-wheel-inner-text-heading,
.portfolio-popup-tab-container,
.portfolio-popup-tab-date,
.page-not-found-home-button,
.portfolio-menu-list-item,
.portfolio-transactions-subheading,
.portfolio-transaction-total-balance,
.portfolio-transaction-value,
.portfolio-transaction-percentage,
.search-search-box.addcoin,
.coin-portfolio-balance,
.dialog-text.transaction,
.transaction-grid-row,
.coin-portfolio-card-percent.large,
.portfolio-popup-characters-container,
.portfolio-popup-name-input,
.coin-portfolio-total-balance-title,
.coin-portfolio-card-title,
.coin-portfolio-card-value,
.coin-portfolio-card-percent .coin-text-down,
.coin-portfolio-card-percent .coin-text-up,
.portfolio-show-toggle-text,
.portfolio .submenu-item,
.portfolio-popup-title.list,
.cse-plus-welcome-message,
.cse-plus-membership-cost-savings,
.cse-plus-membership-includes,
.membership-and-billing-type,
.membership-and-billing-membership-label,
.portfolio-popup-title.list,
.transaction-grid-row,
.portfolio-edit-menu-container,
.cse-plus-membership-early-bird,
.deals-page-card-type,
.top-ten-leaderboard-pedestal-name,
.top-ten-leaderboard-selector-container,
.top-ten-leaderboard-user-segment-number,
.top-ten-leaderboard-user-segment-points,
.leaderboard-table-heading,
.leaderboard-user-row-container,
.leaderboard-page-number-button,
.learn-page-get-started,
.learn-page-dropdown-button-container,
.quiz-results-points-subtitle,
.quiz-results-page-brush-up-on,
.quiz-result-page-module-overview-title,
.cse-plus-additional-features-title-container {
    font-family: cseGothamMedium, serif;
}

.header-link,
.one-stop-text,
.one-stop-subscribe-btn,
.home-section-news-button,
.coin-portfolio-title-button,
.newsletter-button,
.learn-2-earn-button,
.home-section-course-button,
.home-section-course-button-mobile,
.subscribe-button,
.news-page-card-button,
.news-page-card-carousel-button,
.market-news-button-mob,
.info-page-summary-button,
.learn-module-card-button,
.learn-category-card-button,
.course-section-block-button,
.learn-page-intro-button,
.news-overlay,
.news-author,
.news-author-mob,
.news-page-story-author,
.news-date,
.news-time-expired,
.news-tab-time-expired,
.news-headline,
.tab-heading,
.card-title,
.card-text,
.coin-text-heavy,
.coin-text-light,
.home-section-heading,
.home-section-heading-mobile,
.home-section-trending-heading,
.home-section-recommend-heading,
.home-section-info-howto-heading,
.news-page-trending-heading,
.news-story-trending,
.wallet-subpage-howto-heading,
.home-section-tag,
.learn-2-earn-text,
.home-section-course-label,
.home-section-course-value,
.home-section-info-text,
.wallet-subpage-guide-text,
.info-article-offer-text,
.home-section-coins-more,
.encyclopedia-submenu-heading,
.encyclopedia-submenu-heading a,
.page-submenu-heading li,
.page-menu-container li,
.encyclopedia-breadcrumb,
.learn-chapter-breadcrumb,
.learn-category-breadcrumb,
.news-story-breadcrumb,
.info-article-breadcrumb,
.leaderboard-breadcrumb,
.coin-detail-breadcrumb,
.portfolio-breadcrumb,
.portfolio-transactions-breadcrumb,
.news-story-subheading,
.news-story-content,
.story-social-heading,
.info-page-nav,
.info-page-nav:active,
.most-read-news-time-expired,
.exchange-subpage-text,
.wallet-subpage-text,
.info-article-content-text,
.newsletter-signup-form-container,
.news-story-paragraph-break h1,
.news-story-paragraph-break h2,
.content-section-menu-heading,
.ticker-24hr-label,
.ticker-section-top-gainer,
.ticker-section-top-loser,
.ticker-coin-name,
.ticker-coin-price,
.ticker-coin-change,
.learn-page-intro-text,
.learn-module-card-text,
.learn-category-card-text,
.learn-module-card-module-score-label,
.learn-module-card-level,
.learn-category-subheading,
.course-page-about,
.course-page-subtitle,
.course-section-card-text,
.profile-section-card-text,
.course-section-block-text-large,
.course-section-block-text,
.course-section-take-quiz-button,
.coin-grid-header,
.rewards-grid-row-container,
.rewards-grid-header,
.coin-grid-row,
.coin-grid-footer-showing,
.coin-page-top100-text,
.coin-detail-summary-whitepaper,
.coin-detail-summary-web,
.coin-detail-summary-web a:visited,
.coin-detail-summary-web a:hover,
.coin-detail-summary-web a:link,
.coin-detail-summary-web a:active,
.coin-detail-summary-whitepaper a:visited,
.coin-detail-summary-whitepaper a:hover,
.coin-detail-summary-whitepaper a:link,
.coin-detail-summary-whitepaper a:active,
.coin-detail-summary-rank,
.coin-detail-stats-info,
.coin-data-statistics-subheading,
.coin-data-analysis-description,
.coin-data-analysis-vote,
.quiz-page-module-bc,
.quiz-page-module-bc a:visited,
.quiz-page-module-bc a:hover,
.quiz-page-module-bc a:link,
.quiz-page-module-bc a:active,
.quiz-page-chapter-bc,
.quiz-page-chapter-bc a:visited,
.quiz-page-chapter-bc a:hover,
.quiz-page-chapter-bc a:link,
.quiz-page-chapter-bc a:active,
.quiz-page-bc-separator,
.quiz-start-page-title,
.quiz-start-page-question-count,
.quiz-popup-title,
.quiz-popup-description,
.footer-topic-heading,
.footer-topic-text,
.legal-text,
.market-news-author,
.home-section-coins-grid-head,
.home-section-coins-grid-body-text,
.course-section-opener,
.course-section-opener-large,
.course-section-title,
.user-menu-name-container,
.menu-user-menu-settings-button,
.menu-user-menu-preferences-button,
.menu-user-menu-logout-button,
.user-menu-email-container,
.news-story-read-time,
.news-story-listen,
.search-page-get-more,
.newsletter-subscribe-form-title,
.newsletter-subscribe-form-required-text,
.newsletter-subscribe-form-radio,
.profile-form-radio,
.newsletter-subscribe-form-consent-message,
.sign-up-privacy-link-text.link,
.chapter-score-text,
.register-privacy-text,
.crypto-dashboard-how-to-get-started,
.learn-category-text,
.crypto-dashboard-progress-wheel-level,
.crypto-dashboard-progress-container-merits-complete-label,
.crypto-dashboard-progress-container-merits-complete-score,
.crypto-dashboard-edit-profile,
.learn-category-completion-result,
.learn-category-score-label,
.learn-category-modules-completed-label,
.learn-category-modules-completed,
.hardware-wallet-intro-text,
.hardware-wallet-intro-text li,
.login-subheading,
.recovery-page-login-return-text,
.recovery-page-resend-container,
.messages,
.crypto-dashboard-progress-modules-completed,
.crypto-dashboard-intro-text,
.encyclopedia-subpage-text,
.learn-module-card-completion-value,
.learn-module-card-level,
.info-panel-container,
.tou-title,
.tou-section-title,
.tou-section-number,
.tou-section-content,
.tou-section-number-title,
.how-funded-title,
.how-funded-section-title,
.how-funded-section-number,
.how-funded-section-content,
.how-funded-section-number-title,
.learn-category-text,
.learn-category-content-area,
.locked-view-certification-text,
.related-news-story-heading,
.course-page-subtitle,
.encyclopedia-description,
.profile-element-label,
.profile-image-upload-button,
.profile-page-tab-title,
.profile-details-save-button,
.profile-image-save-button,
.profile-page-tab-text,
.profile-page-tab-description,
.profile-page-tab-action,
.quiz-result-users-merit-message,
.profile-page-newsletter-consent-label,
.dialog-header,
.dialog-text,
.market-data-grid-header,
.info-article-content-heading h2,
.encyclopedia-word-image-text,
.encyclopedia-word-image-text-definition,
.info-article-content-step-text-container p,
.encyclopedia-body-content,
.learning-objective,
.learn-module-card-blurb,
.back-to-top-button,
.register-login,
.login-register,
.coin-page-show-rows-text,
.coin-page-show-rows,
.market-data-grid-row,
.search-page-nothing-found-message,
.trending-card-title,
.loser-card-title,
.gainer-card-title,
.burger-menu,
.home-section-coins-grid-container,
.affiliate-link,
.affiliate-popup-text,
.affiliate-close,
.info-article-progress-container,
.encyclopedia-read-more-button,
.newsletter-signup-heading-container,
.newsletter-signup-form-container,
.newsletter-signup-form-button,
.encyclopedia-read-more-button,
.related-news-title,
.newsletter-text,
.page-not-found-home-button,
.portfolio-popup-button,
.page-not-found-description-container,
.page-not-found-message-container,
.page-not-found-title-container,
.profile-referrals-title,
.profile-referrals-description,
.portfolio-popup-tab-container,
.page-not-found-title-container,
.portfolio-menu-list-item,
.cookies-pop-up-text,
.toggle-cookie-message,
.toggle-cookie-container,
.tou-publish-container,
.tou-paragraph-container table,
.tou-paragraph-container td,
.tou-paragraph-container th,
.coin-portfolio-title,
.coin-portfolio-total-balance-title,
.coin-portfolio-balance,
.coin-portfolio-card-title,
.coin-portfolio-card-percent,
.coin-portfolio-card-value,
.portfolio-show-toggle-text,
.portfolio-coin-search-name,
.portfolio-coin-search-symbol,
.portfolio-popup-addcoin-symbol,
.portfolio-popup-addcoin-title,
.portfolio-transactions-coin-name,
.portfolio-transactions-subheading,
.portfolio-transaction-total-balance,
.portfolio-transaction-value,
.transaction-grid-header,
.coin-grid-row-container,
.portfolio-popup-radio-text,
.portfolio-popup-tab-input.quantity,
.portfolio-popup-tab-input-max,
.portfolio-popup-tab-input-coin-symbol,
.portfolio-popup-tab-date,
.portfolio-popup-title,
.portfoio-popup-title.portfolio-name,
.portfolio-chart-coin-name,
.portfolio-chart-coin-percentage,
.cse-plus-welcome-message,
.cse-plus-rocket-success,
.cse-plus-description,
.cse-plus-your-membership,
.cse-plus-your-membership-type,
.cse-quick-links-title,
.cse-plus-access-button,
.cse-plus-quick-link-card-description,
.cse-plus-quick-link-card-title,
.cse-plus-upgrade-description,
.cse-plus-upgrade-title,
.cse-plus-current-plan-container,
.cse-plus-membership-type,
.cse-plus-membership-cost,
.cse-plus-membership-cost-month,
.cse-plus-membership-cost-savings,
.cse-plus-membership-yearly,
.cse-plus-membership-description,
.cse-plus-membership-includes,
.cse-plus-membership-feature,
.cse-plus-membership-feature-text,
.cse-plus-upgrade-button,
.cse-plus-tiers-title,
.cse-plus-memberships-title-container,
.cse-plus-memberships-description-container,
.cse-plus-membership-feature-card-title-container,
.cse-plus-membership-feature-card-description-container,
.cse-plus-membership-feature-card-sub-description-container,
.cse-premium-one-to-one-find-out-more,
.cse-plus-register-button,
.cse-plus-sign-in-button,
.cse-plus-already-have-an-account,
.cse-plus-already-have-an-account-log-in,
.membership-and-billing-type,
.membership-and-billing-membership-label,
.membership-and-billing-title,
.portfolio-edit-menu-container,
.discord-popup-body-heading,
.discord-popup-body-submit-button,
.discord-popup-body-later-text,
.home-plan-upgrade-your-plan,
.home-plan-title,
.home-plan-description,
.home-plan-cse-plus-upgrade-button,
.upgrade-options-popup-body-main-text,
.upgrade-options-popup-body-small-text,
.upgrade-options-popup-body-button,
.change-membership-pop-up-title-container,
.change-membership-card-current-plan,
.change-membership-card-title,
.change-membership-card-price,
.change-membership-card-period,
.change-membership-card-price-period,
.change-membership-card-description-container,
.change-membership-label,
.change-membership-button,
.change-membership-confirmation-title,
.change-membership-complete-title,
.deals-page-card-description,
.deals-page-card-title,
.deals-page-card-type,
.deals-page-card-button,
.discord-username-popup-save-message,
.discord-username-popup-description,
.discord-username-popup-title,
.profile-discord-link-button,
.module-card-difficulty-time,
.module-card-title,
.module-card-description,
.module-card-completion-percentage,
.top-ten-leaderboard-pedestal-name,
.top-ten-leaderboard-selector-container,
.top-ten-leaderboard-user-segment-number,
.top-ten-leaderboard-user-segment-name,
.top-ten-leaderboard-user-segment-points,
.top-ten-leaderboard-link-button,
.new-course-banner-button,
.course-user-card-username,
.course-user-card-realname,
.course-user-card-detail-header,
.course-user-card-detail,
.course-user-card-sub-detail,
.new-course-banner-new-course-text,
.new-course-banner-out-now-text,
.new-course-banner-description,
.leaderboard-title,
.leaderboard-table-heading,
.leaderboard-user-row-container,
.leaderboard-page-nav-button,
.leaderboard-page-number-button,
.leaderboard-table-pages-showing,
.leaderboard-page-title,
.leaderboard-page-description,
.learn-page-title,
.learn-page-welcome,
.learn-page-description,
.learn-page-get-started,
.learn-page-dropdown-button-container,
.learn-category-collection-title,
.learn-category-collection-see-all-text,
.profile-referrals-mobile-button,
.quiz-start-page-score-name,
.quiz-start-page-score-points,
.quiz-start-page-points-subtitle,
.quiz-results-points-subtitle,
.quiz-results-page-brush-up-message,
.quiz-results-generic-message,
.quiz-results-page-brush-up-on,
.quiz-result-page-course-complete-title-container,
.quiz-result-page-module-overview-title,
.module-card-membership-tier-banner-text,
.cse-plus-additional-features-title-container,
.cse-plus-membership-feature-description {
    letter-spacing: -0.18pt;
}

.news-page-market-story-tag,
.quiz-start-page-description {
    letter-spacing: -0.3pt;
}

.crypto-dashboard-progress-wheel-view_rockets {
    letter-spacing: 0.1pt;
}

.learn-module-completion-percentage {
    letter-spacing: -0.1pt;
}

.quiz-result-users-score-label {
    letter-spacing: 0.05px;
}

.header-link,
.home-settings,
.home-settings-register-button,
.login-button,
.ticker-24hr-label,
.ticker-section-top-gainer,
.ticker-section-top-loser,
.ticker-coin-name,
.ticker-coin-price,
.ticker-coin-change,
.recovery-page-mailapp-button{
    font-weight: normal;
}

.learn-module-completion-result,
.crypto-dashboard-profile-name{
    font-weight: lighter;
}

.quiz-results-points-subtitle,
.quiz-result-users-score-label,
.quiz-results-generic-message,
.quiz-results-page-brush-up-message {
    font-weight: 100;
}

.encyclopedia-page-letters {
    -webkit-text-stroke-width: 2px;
}

/* TODO TRANSLATE DROPDOWN */
.goog-te-combo option,
.goog-te-combo {
    font-family: cseGothamMedium, serif !important;
}
