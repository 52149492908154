@media only screen and (max-width: 383px) {
    .coin-data-analysis-technical {
        display: none;
    }

    .market-data-grid-header .spread,
    .market-data-grid-row .spread{
        display: none;
    }

    .coin-detail-summary-block .home-contents {
        display: block;
    }

    .coin-grid-footer-container {
        width: fit-content;
        margin: 50px auto;
    }

    .coin-grid-footer-contents {
        width: fit-content;
        margin: auto;
    }

    .coin-grid-footer-pages-container{
        width: fit-content;
    }

    .encyclopedia-menu-container {
        padding-left: 0;
        max-width: 96vw;
        display: none;
    }

    .encyclopedia-small-menu-container {
        padding-top: 20px;
        max-width: 96vw;
        display: block;
    }

    .search-page-list .col {
        display: block;
    }

    .search-page-list-text {
        width: 350px !important;
    }

    .search-page-list img {
        display: none !important;
    }

    .learn-module-card-module-score-label {
        bottom: 0;
        right: 6px;
    }

    .search-page-encyclopedia-list.row {
        height: 140.5px;
    }

    .search-page-encyclopedia-list {
        height: 140px !important;
    }

    .encyclopedia-search-word-image-text-container.search {
        left: 45%;
        font-size: 15px;
    }

    .search-page-encyclopedia-list-text {
        display: none;
    }

    .encyclopedia-thumbnail-image.search {
        /*height: 140px;*/
    }

    .news-story-read-time {
        padding-left: 0 !important;
    }

    .coin-detail-stats-info-content {
        width: 180px !important;
    }

    .affiliate-link {
        position: absolute;
        left: calc(-1 * (100vw - (50px + 100%)));
        top: 100px;
    }

    .affiliate-popup-container {
        left: calc(-1 * (100vw - (40px + 100%)));
        top: -220px;
        width: 250px;
    }

    .coin-subpage-cards.home-contents {
        display: none;
    }

    .info-article-progress-container {
        display: flex;
        position: fixed;
        top: -10px;
        z-index: 1;
        margin: 10px 0;
        width: 100%;
        height: fit-content;
        font-size: 14px;
        flex-direction: column;
        justify-content: space-between;
        transform: translateY(-300px);
        -webkit-transition: transform 0.6s cubic-bezier(0.76, 0.15, 0.16, 1.01);
        -moz-transition: transform 0.6s cubic-bezier(0.76, 0.15, 0.16, 1.01);
        -ms-transition: transform 0.6s cubic-bezier(0.76, 0.15, 0.16, 1.01);
        -o-transition: transform 0.6s cubic-bezier(0.76, 0.15, 0.16, 1.01);
        transition: transform 0.6s cubic-bezier(0.76, 0.15, 0.16, 1.01);
    }

    .info-article-progress-title-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin: 0 20px;
        min-height: 95px;
        height: 100%;
    }

    .info-article-progress-headings {
        min-height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .info-article-progress-title {
        font-size: 14px;
        padding-top: 10px;
    }

    .info-article-table-of-contents-divider {
        border-bottom: solid 1px rgba(112, 112, 112, 0.21);
        padding-top: 2px;
    }

    .info-article-progress-table-container {
        display: flex;
        padding-bottom: 5px;
    }

    .info-article-progress-table-title {
        font-size: 10px;
    }

    .info-article-progress-table-open {
        width: 10px;
        margin-left: 10px;
    }

    .info-article-progress-bar-container {
        height: 5px;
    }

    .info-article-progress-bar {
        background-color: #FF9017;
        width: 50%;
        height: 100%;
    }

    .info-article-progress-table-headings {
        display: flex;
    }

    .learn-categories-container .home-contents {
        z-index: 1;
        position: relative;
        top: -105px;
    }

    .page-submenu-container {
        padding-bottom: 10px;
        padding-left: 15px;
    }

    .learn-categories-heading-container {
        margin-bottom: 30px;
    }

    .crypto-dashboard-progress-container-left {
        border-bottom-left-radius: unset;
    }

    .page-submenu-title {
        padding-right: unset;
        font-size: 17px;
    }

    .page-submenu-heading li,
    .locked-view-certification-text {
        font-size: 9px;
    }

    .learn-module-card-container {
        margin-bottom: unset;
    }

    .module-cards-menu-container {
        margin-top: 30px;
    }

    .most-read-details {
        display: flex;
        padding: 15px 5px;
        line-height: 1.2;
    }

    .info-article-content-fake-step-container,
    .info-article-content-step-container{
        flex-direction: column;
        align-items: center;
    }

    .chapter-score-text {
        font-size: 12px;
    }

    .row.market-news-date-row {
        padding-top: 13px;
    }

    .course-section-card-text {
        padding-left: 10px;
    }

    .crashed-rocket {
        bottom: -40px;
    }

    .related-news-title {
        letter-spacing: 0.1px !important;
    }

    .news-title {
        font-size: 16px;
    }

    .news-title-trending {
        margin-left: 16px;
        padding-top: 9px;
        font-size: 14px;
        line-height: 1.2;
    }

    .affiliate-link-container {
        z-index: 0 !important;
    }

    .news-story-trending {
        letter-spacing: 0.1px !important;
    }

    .learn-categories-container {
        padding-top: 70px;
    }

    .checkbox-container {
        width: 4% !important;
        height: unset !important;
    }

    .newsletter-consent-input {
        width: 100% !important;
        height: 40% !important;
    }

    .newsletter-signup-form-checkbox-label {
        width: 90% !important;
        height: unset !important;
    }

    .cookies-pop-up-container {
        height: 24vh;
    }

    .cookies-pop-up-container button {
        margin-top: 10px;
    }

    .coin-portfolio-title {
        width: 100%;
    }

    .coin-portfolio-title-button-container{
        display: flex;
        width: 90vw;
        position: relative;
        justify-content: flex-end;
    }

    .portfolio-transaction .coin-portfolio-title-button-container {
        position: absolute;
    }

    .coin-portfolio-title-container{
        width: 100%;
    }

    .coin-portfolio-title-container .row {
        display: block;
    }

    .coin-portfolio-edit-button {
        margin-left: 0;
        top: 33px;
        left: 95px;
    }

    .coin-portfolio-card-values-top-container .coin-portfolio-card-percent {
        padding-left: 0;
    }

    .coin-portfolio-all-total-container{
        margin-top: 30px;
    }

    .portfolio-grid-button-container .transfer-button {
        display: none;
    }

    .portfolio-grid-button-container .addition-button {
        margin-right: 10px;
        margin-left: 5px;
    }

    .coin-portfolio-card-value,
    .coin-portfolio-card-percent {
        font-size: 17px;
    }

    .coin-portfolio-card-img {
        width: 30px;
        height: 30px;
        margin-top: unset;
    }

    .coin-portfolio-card-img img{
        width: 100%;
        height: 100%;
    }

    .portfolio-popup-radio-card{
        width: calc(100% - 4px);
        margin-left: 4px;
        font-size: 12px;
        padding: 6px 2px;
    }

    .portfolio-popup-radio-text{
        width: 60%;
        padding: 5px 3px;
    }

    .coin-portfolio-all-container .coin-portfolio-title {
        margin-bottom: -55px;
    }

    .coin-portfolio-all-container .coin-portfolio-title-button {
        width: 80px;
        top: 93px;
        left: 0;
        padding: 0 !important;
    }

    .coin-portfolio-card-percent {
        max-width: 50px;
    }

    .coin-portfolio-card-percent.large {
        max-width: unset;
        top: -1px;
        left: 25px;
    }

    .coin-portfolio-card-value {
        position: relative;
        top: 2px;
        right: 5px;
    }

    .coin-portfolio-card-title {
        margin-bottom: 0;
        position: relative;
        display: flex;
        align-items: center;
    }

    .coin-portfolio-card .row .col-sm-3 {
        top: 7px;
        left: 70px;
        width: fit-content;
    }

    .coin-portfolio-card {
        padding: 10px;
    }

    .coin-portfolio-card-values-top-container {
        justify-content: space-between;
    }

    .coin-portfolio-card-values-container {
        right: 0;
    }

    .coin-portfolio-number-container {
        position: relative;
        top: 5px;
        left: 8px;
    }

    .coin-portfolio-balance {
        font-size: 35px;
    }

    .coin-portfolio-one-container .coin-portfolio-title-button-container {
        margin-top: -70px;
        justify-content: flex-end;
    }

    .coin-portfolio-one-container .coin-portfolio-title-button {
        width: 80px;
        padding: 0 !important;
        left: -1px;
        top: 98px;
    }

    .coin-portfolio {
        padding-left: 10px;
        width: 45%;
    }

    .coin-grid-row .number {
        width: 5%;
    }

    .actions-portfolio {
        width: 25%;
    }

    .profitLoss-portfolio {
        width: 25%;
    }

    .coin-portfolio-text-container {
        padding-left: 5px;
    }

    .coin-portfolio img {
        width: 25px;
        height: 25px;
    }

    .coin-grid-row .name,
    .coin-grid-row .profitLoss-portfolio {
        font-size: 12px;
    }

    .coin-grid-footer-container.portfolio {
        margin: 20px auto -15px;
    }

    .portfolio-page-chart-section-container {
        flex-direction: column-reverse;
    }

    .portfolio-chart-container {
        width: 80vw;
        height: 80vw;
        margin: auto;
    }

    .portfolio-chart-coins-container {
        width: 100%;
        padding: 15px 0 0;
    }

    .portfolio-popup-name-input {
        font-size: 14px;
    }

    .portfolio-popup-card.portfolioList.dropdown {
        width: 95vw;
        left: calc((100% - 95vw) / 2) !important;
        right: calc((100% - 95vw) / 2) !important;
    }

    .thin-menu,
    .medium-menu,
    .wide-menu,
    .portfolio-popup-card.holdingsTab {
        top: 5% !important;
    }

    .portfolio-transactions-coin-name {
        padding-right: 1%;
    }

    .coin-portfolio-title-button.transaction {
        width: 100px;
        padding: 0 !important;
        left: -13px;
        top: 333px;
    }

    .portfolio-transactions-details-section {
        padding: 10px
    }

    .portfolio-transactions-subheading,
    .portfolio-transaction-value {
        margin: 0;
        display: flex;
        align-items: center;
    }

    .portfolio-transaction-percentage {
        margin-left: 5px;
        padding: 0;
    }

    .transaction .type {
        max-width: 17%;
        min-width: 17%;
    }

    .transaction .date {
        max-width: 30%;
        min-width: 30%;
    }

    .transaction .amount {
        max-width: 33%;
        min-width: 33%;
        font-size: 12px;
    }

    .transaction .actions {
        max-width: 20%;
        min-width: 20%;
    }

    .portfolio-transactions-actions-button.edit {
        margin: 0;
        padding: 0;
    }

    .portfolio-transactions-actions-button img,
    .portfolio-transactions-actions-button.edit img {
        margin-left: 0;
    }

    .transaction-grid-row .actions {
        justify-content: space-between;
    }

    .transaction-grid-header .amount,
    .transaction-grid-row .amount {
        text-align: unset;
    }

    .portfolio-popup-holdings-menu-button {
        font-size: 12px;
    }

    .membership-and-billing-buttons-container {
        height: 120px;
    }

    .membership-and-billing-container {
        height: 370px;
    }

    .footer-container {
        height: 840px;
    }

    .membership-and-billing-payment-button {
        margin-top: 20px;
        margin-left: 0;
    }

    .cse-plus-tiers-title {
        margin-top: 400px;
    }

    .change-membership-confirmation-container {
        padding: 50px;
    }

    .change-membership-confirmation-plan-start {
        text-align: center;
    }

    .change-membership-confirmation-card-title {
        font-size: 16px;
    }

    .change-membership-confirmation-memberships-container {
        margin-top: 0px;
    }

    .navbar-top-row,
    .home-settings {
        display: none;
    }

    .fixed-nav {
        top: -1px;
    }

    .quiz-start-page-point-option-containers .quiz-start-page-point-card-image {
        display: none;
    }

    .quiz-start-page-point-card-text-container,
    .quiz-start-page-score-points,
    .quiz-start-page-score-name {
        text-align: center;
        width: 100%;
    }

    .learn-page-menu-container {
        display: block;
    }

    .learn-page-nav-menu-section.dropdown-section {
        border-bottom: unset;
    }

    .learn-page-dropdown-menu-container {
        position: static;
        margin-top: 20px;
        flex-wrap: wrap;
    }

    .learn-page-dropdown-menu-container > div {
        margin: 0 20px 10px 0
    }
}