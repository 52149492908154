/* Some  bootstrap elements break at the wrong point for us - use this file to correctly handle these breaks */
@media only screen and (max-width: 575px) and (min-width: 481px) {
    .home-contents {
        max-width: 97% !important;
    }

    .ticker-container,
    .header-actions,
    .large-header,
    .nav-links {
        display: none;
    }

    .small-header {
        display: block;
    }


    .app-title img {
        height: 45px;
        width: 99px;
        margin-top: 10px;
    }


    .burger-line {
        display: block;
        height: 5px;
        width: 100%;
        margin-top: 5px;
        border-radius: 10px;
    }

    .burger-icon.active .line1,
    .burger-icon.active .line2,
    .burger-icon.active .line3 {
        display: none;
    }

    .burger-links-container {
        margin-bottom: 80px;
        margin-top: 30px;
    }

    .burger-icon {
        width: 30px;
        height: 25px;
        margin-right: 14px;
    }

    .page-action-small-menu-container {
        width: 60%;
    }

    .page-action-small-menu-container .nav-links {
        position: absolute;
        top: 0;
        right: -2vw;
        width: 100vw;
        z-index: 99;
    }

    .one-stop-container {
        text-align: center;
        margin: 0 0 40px 0;
    }

    .tab-heading {
        margin-top: 10px;
        margin-left: 1.5vw;
    }

    .home-summary-container .col-md-8 .tab-heading {
        margin-left: 0;
    }

    .news-container .container {
        padding: 0;
    }

    .news-container {
        height: 630px;
        max-width: 96vw;
    }

    .news-container .main-story {
        max-width: 96vw;
    }

    .news-container .container .row .col {
        flex: 1 0 100%;
    }

    .news-headline {
        max-width: 96%;
        overflow-y: hidden;
        height: 30px;
    }

    .news-story img {
        width: 100%;
        height: 350px;
        scale: none;
        object-fit: cover;
    }

    .col.news-date,
    .col.news-tab-time-expired {
        max-width: fit-content;
    }

    .news-tab-time-expired {
        margin-left: 10px;
    }

    .card-container,
    .trending-card-container,
    .gainer-card-container,
    .loser-card-container {
        padding-bottom: 20px;
        height: 223px;
        position: relative;
        width: 90vw;
        margin: 0 auto;
    }

    .card-contents-container {
        height: 220px;
        position: relative;
        padding: 0;
        max-width: 197px !important;
    }

    .card-image img {
        top: 50px;
        bottom: 50px;
        right: -10px;
    }

    .card-image.learn img {
        bottom: 50px;
    }

    .wallet-subpage-card-container .col-sm-7,
    .wallet-subpage-card-container .col-sm-5 {
        width: 50%;
    }

    .wallet-subpage-guide-text {
        width: unset;
    }

    .home-section-info-card {
        width: 92vw !important;
        margin-right: 30px;
    }

    .card-container .card-image img {
        right: 0;
    }

    .crypto-info-card-container,
    .crypto-learn-card-container,
    .crypto-coins-card-container {
        margin-top: 40px;
    }

    .home-contents {
        max-width: 96vw !important;
        margin: auto;
    }

    .home-news-container .col,
    .footer-container .col,
    .home-learn-container .col {
        flex: none;
    }

    .home-section-info-title {
        max-width: 86%;
    }

    .home-section-info-text {
        width: unset;
    }

    .col-md-7.card-contents-container {
        width: 58%;
    }

    .col-md-5.card-image {
        width: 42%;
    }

    .subscribe-banner-container {
        display: none !important;
    }

    .home-learn-content,
    .home-coins-content {
        margin-top: 30px;
    }

    .home-info-content {
        z-index: 1;
        position: relative;
        margin-top: -35px
    }

    .home-section-course {
        height: 106px;
    }

    .home-section-course-image {
        width: 140px !important;
        max-width: 100% !important;
    }

    .col.home-section-course-image {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .home-section-course-title {
        margin-bottom: 25px;
        max-height: 20px;
        overflow-y: hidden;
    }


    .home-page-news-information-container {
        padding-bottom: 30px;
    }

    .news-read-more-button {
        position: absolute;
        bottom: 0;
        right: 0;
        top: unset;
    }


    .news-page-content .row {
        display: block;
    }

    .news-page-content {
        padding-bottom: 15px;
    }

    .news-page-list-story .row,
    .home-section-news-list-story .row {
        display: flex;
    }

    .news-page-list-story img,
    .home-section-news-list-story img,
    .home-section-news-image {
        width: 140px !important;
        height: 75px;
        object-fit: fill;
    }

    .news-page-main-story {
        height: fit-content;
        margin: unset;
        border-radius: 0 0 10px 10px;
    }

    .news-overlay-details-container {
        margin-top: 0;
    }


    .news-overlay .title {
        max-width: 92%;
    }

    .news-page-news-information-container,
    .home-page-news-information-container {
        display: block;
        flex: unset !important;
        width: 100%;
    }

    .news-date {
        margin-top: 5px;
    }

    .news-page-trending-information-container {
        display: block;
        flex: unset !important;
    }

    .news-page-news-information-container .news-time-expired,
    .home-page-news-information-container .news-time-expired {
        margin-left: 0;
    }

    .news-time-expired {
        margin-left: 5px;
        margin-top: 5px;
    }

    .home-section-news-headline {
        height: 44px;
    }

    .news-page-most-read-counter {
        font-size: 14px;
        width: 14px !important;
        padding: 0 0 0 14px !important;
        margin-top: 10px !important;
    }

    .news-page-most-read-story-text {
        width: calc(100% - 170px) !important;
        margin-top: 8px !important;
        padding-left: 20px !important;
    }

    .news-page-most-read-story-headline {
        font-size: 20px;
        height: 65px;
        overflow: hidden;
        margin: 0 10px 0 0;
    }

    .news-page-most-read-image {
        width: 140px !important;
        height: 136px;
        padding: 0 0 0 0 !important;
    }

    .news-author {
        display: block;
        flex: unset !important;
        width: 100% !important;
        margin-top: 2px !important;
        margin-bottom: 5px;
    }

    .most-read-news-time-expired {
        margin-left: 0 !important;
        display: block;
        flex: unset !important;
    }

    .news-page-most-read-story-details {
        display: block !important;
    }

    .news-page-market-story {
        height: 300px !important;
    }

    .news-page-market-story img {
        height: 165px !important;
    }

    .home-section-news-list-story .home-section-news-text,
    .news-page-list-story .news-page-story-text {
        padding: 10px 10px 10px 20px !important;
    }

    .news-page-card-carousel-button {
        bottom: 0;
    }

    .news-page-card-button, .news-page-card-carousel-button {
        right: 0 !important;
    }

    .market-news-author {
        max-width: 33%;
    }

    .news-page-story-text,
    .home-section-news-text {
        display: block;
        width: calc(100% - 160px) !important;
    }

    .news-page-story-text {
        flex: unset !important;
    }

    .news-page-market-container .container .home-contents,
    .news-page-most-read-container .container .home-contents {
        padding: 0 !important;
    }

    .news-page-most-read-list .col-sm-6 {
        padding: 6px !important;
    }

    .news-story-read-time-container {
        position: absolute;
        right: 26px;
    }

    .news-story-read-time {
        padding-top: 0;
    }

    .news-story-listen-container {
        top: 20px;
    }

    .news-story-details-container {
        min-height: 70px;
    }

    .news-page-story-image {
        width: 120px !important;
        flex: unset !important;
    }

    .news-story-details-container .news-author {
        margin-top: 0 !important;
    }

    .news-story-details-container .news-date {
        margin-top: 0 !important;
        width: 100%;
        text-align: start;
    }

    .story-page-chevron {
        display: block;
    }

    .encyclopedia-article-block,
    .news-story-block {
        padding-top: 30px;
        padding-right: 10px !important;
    }

    .info-article-block {
        padding-right: 0 !important;
    }

    .info-article-howto {
        padding-top: 20px;
    }

    .info-article-heading {
        padding-top: unset;
    }

    .info-article-image {
        padding-top: 5px;
    }

    .info-article-content-heading h1 {
        padding-top: 25px;
    }

    .info-article-content-text {
        padding-top: unset;
    }

    .news-story-content-container {
        display: block;
        margin-top: 30px;
    }

    .story-social-container {
        margin-top: -50px;
        width: 200px;
    }

    .story-social-container button {
        padding-right: 10px !important;
    }

    .news-story-content {
        width: 92vw !important;
        padding-top: 30px;
    }

    .news-story-block .news-story-content {
        padding-left: 0 !important;
    }

    .related-news-container .col-sm-4 {
        padding-left: 0 !important;
    }

    .story-page-news-list {
        width: 100%;
        max-width: 500px;
    }

    .news-story-trending-container {
        margin-right: 0 !important;
    }

    .story-list-text .news-author,
    .story-list-text .news-time-expired {
        padding: 0 0 0 10px !important;
        margin-left: 0;
    }

    .news-story-paragraph-break h1,
    .news-story-paragraph-break h2 {
        height: fit-content;
    }

    .news-story-paragraph-break iframe {
        width: 88vw;
    }

    .news-read-more-button .card-button {
        bottom: 8px;
        left: -120px;
        position: absolute;
    }

    .news-read-more-button .news-page-card-carousel-button {
        bottom: 0 !important;
        height: 20px;
        right: 5px !important;
    }

    .exchange-subpage-text,
    .wallet-subpage-text {
        width: 92vw;
    }

    .info-page-heading-container {
        display: block;
    }

    .info-page-heading {
        text-align: center;
        font-size: 20px;
    }

    .page-menu-container {
        display: flex;
        overflow-x: scroll;
        flex-wrap: nowrap;
    }

    .page-submenu-container {
        display: flex;
        overflow-x: scroll;
        flex-wrap: nowrap;
        padding-bottom: 10px;
        padding-left: 15px;
    }


    .page-submenu-heading ul,
    .page-menu-container ul {
        display: flex;
        margin-bottom: 0 !important;
    }

    .encyclopedia-submenu-heading ul {
        margin-bottom: 0 !important;
    }

    .info-page-nav-container {
        padding: 0 !important;
    }

    .info-page-nav-container {
        position: relative;
    }

    .menu-scroll-indicator {
        display: block;
        position: absolute;
        width: 20px;
    }

    .menu-scroll-indicator.left {
        left: 0;
        top: 3px;
    }

    .menu-scroll-indicator.right {
        right: 0;
        top: 3px;
    }

    .page-menu-container {
        margin-left: 20px;
        margin-right: 20px;
    }

    .info-article-nav-container {
        display: none;
    }

    .info-article-progress-container {
        display: flex;
        position: fixed;
        top: -10px;
        z-index: 1;
        margin: 10px 0;
        width: 100%;
        height: fit-content;
        font-size: 14px;
        flex-direction: column;
        justify-content: space-between;
        transform: translateY(-300px);
        -webkit-transition: transform 0.6s cubic-bezier(0.76, 0.15, 0.16, 1.01);
        -moz-transition: transform 0.6s cubic-bezier(0.76, 0.15, 0.16, 1.01);
        -ms-transition: transform 0.6s cubic-bezier(0.76, 0.15, 0.16, 1.01);
        -o-transition: transform 0.6s cubic-bezier(0.76, 0.15, 0.16, 1.01);
        transition: transform 0.6s cubic-bezier(0.76, 0.15, 0.16, 1.01);
    }

    .info-article-progress-title-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin: 0 20px;
        min-height: 95px;
        height: 100%;
    }

    .info-article-progress-headings {
        min-height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .info-article-progress-title {
        font-size: 14px;
        padding-top: 10px;
    }

    .info-article-table-of-contents-divider {
        border-bottom: solid 1px rgba(112, 112, 112, 0.21);
        padding-top: 2px;
    }

    .info-article-progress-table-container {
        display: flex;
        padding-bottom: 5px;
    }

    .info-article-progress-table-title {
        font-size: 10px;
    }

    .info-article-progress-table-open {
        width: 10px;
        margin-left: 10px;
    }

    .info-article-progress-bar-container {
        height: 5px;
    }

    .info-article-progress-bar {
        background-color: #FF9017;
        width: 50%;
        height: 100%;
    }

    .info-article-progress-table-headings {
        display: flex;
    }

    .nav-menu {
        display: block;
    }

    .nav-search {
        display: none;
    }

    .encyclopedia-submenu-heading {
        display: block;
    }

    .encyclopedia-menu-container {
        padding-left: 0;
        max-width: 96vw;
        display: none;
    }

    .encyclopedia-small-menu-container {
        padding-top: 20px;
        max-width: 96vw;
        padding-left: 0 !important;
        display: block;
    }

    .encyclopedia-search-box-container {
        max-width: 80vw;
    }

    .encyclopedia-page-image {
        display: none;
    }

    .page-submenu-title {
        z-index: 2;
        padding-right: unset;
        font-size: 18px;
    }

    .coins-subpage-contents {
        margin-top: 50px;
    }

    .coin-page-top100 {
        top: -26px;
        right: unset;
        left: 15px;
    }

    .coin-grid-header .chart,
    .coin-grid-header .day,
    .coin-grid-header .market-cap,
    .coin-grid-row .chart,
    .coin-grid-row .day,
    .coin-grid-row .market-cap {
        display: none;
    }

    .coin-grid-footer-showing {
        top: 50px;
        right: 0;
    }

    .coin-grid-row .star {
        width: 16px;
    }

    .coin-grid-header .number,
    .coin-grid-row .number {
        width: 10%;
    }

    .coin-grid-header .coin,
    .coin-grid-row .coin {
        width: 35%;
    }

    .coin-grid-header .price,
    .coin-grid-row .price {
        width: 30%;
    }

    .coin-grid-header .hour,
    .coin-grid-row .hour {
        width: 25%;
    }

    .coin-grid-row .ticker {
        padding-left: 5px;
    }

    .coin-grid-row .coin .name {
        display: none;
    }

    .learn-page-intro-heading {
        font-size: 40px;
    }

    .learn-page-intro-text {
        width: 90vw;
    }

    .learn-page-intro-button {
        width: 100px !important;
    }

    .login-container {
        height: fit-content;
        width: 90vw;
    }

    .login-action-container {
        width: 90vw;
    }

    .crypto-dashboard {
        display: block;
    }

    .crypto-dashboard-intro-container,
    .crypto-dashboard-progress-container {
        width: 100%;
        margin: 0;
    }

    .crypto-dashboard-progress-container {
        margin-top: 20px;
        height: fit-content;
        max-width: 400px;
    }

    .crypto-dashboard-progress-container-row {
        padding: 0 10px 0 10px;
    }

    .crypto-dashboard-progress-modules-completed {
        text-align: left;
    }

    .crypto-dashboard-progress-modules-complete-label {
        margin-top: 30px;
    }

    .user-crypto-dashboard {
        padding-bottom: 100px;
    }

    .crypto-dashboard-welcome-container,
    .crypto-dashboard-progress-container-row {
        display: block;
    }

    .crypto-dashboard-profile-picture-container {
        margin-left: 10px;
    }

    .crypto-dashboard-progress-container-merits-complete-container {
        margin-left: 0;
    }

    .crypto-dashboard-profile-name {
        margin-top: 10px;
    }

    .crypto-dashboard-progress-container-left {
        padding-bottom: 70px;
        width: 100%;
        border-top-right-radius: 15px;
        border-bottom-left-radius: unset;
    }

    .crypto-dashboard-progress-modules-complete-label {
        display: block;
        text-align: center;
        margin-left: 10px;
    }

    .crypto-dashboard-progress-completed-level-modules {
        margin-left: 8px;
    }

    .learn-category-summary-container {
        width: 90vw;
        height: fit-content;
    }

    .learn-category-column {
        display: block;
    }

    .learn-module-menu {
        margin-top: 20px;
    }

    .learn-category-description-column {
        width: 90vw;
    }

    .learn-category-learn-areas-column {
        width: 90vw;
        padding-left: 0;
    }

    .learn-category-content-areas, .learn-category-column {
        display: block;
    }

    .learn-category-content-area-container {
        width: 90px;
    }

    .course-page-summary {
        display: block;
        width: 90vw;
        margin: auto;
    }

    .course-page-block,
    .course-page-hero {
        width: 100%;
    }

    .course-content-container {
        width: 90vw;
        margin: auto;
    }

    .course-content-container .page-menu-container {
        width: unset;
        margin-right: unset;
        padding-bottom: 5px;
    }

    .learning-objectives-container {
        column-count: 1;
        padding-bottom: 50px;
    }

    .course-section-list {
        min-width: 90vw;
        max-width: 90vw;
        padding-bottom: 0;
    }

    .hide-chapters-menu-button {
        display: none;
    }

    .course-section-block {
        padding-left: 0;
        max-width: 90vw;
        min-width: 90vw;
        width: 90vw;
    }

    .course-content-block {
        display: block;
    }

    .chapter-progress-container {
        min-width: 100%;
        max-width: 100%;
    }

    .course-section-take-quiz {
        min-width: 90vw;
        padding-top: 60px;
    }

    .course-section-take-quiz-button {
        float: unset;
        margin-left: calc(50% - 90px);
    }

    .course-section-block-text-large,
    .course-section-block-text {
        min-width: 89vw;
    }

    .course-section-block-text iframe {
        width: 90vw;
    }

    .quiz-background-dark-square,
    .quiz-page-bc-separator,
    .quiz-background-dark-triangle {
        display: none;
    }

    .quiz-page-breadcrumbs-container {
        display: block;
        order: 2;
    }

    .quiz-exit-header-button-container {
        padding-left: calc(100% - 140px);
        order: 1;
    }

    .quiz-page-chapter-bc {
        margin-top: 10px;
    }

    .quiz-contents {
        min-width: unset;
        height: fit-content;
        max-width: 100vw;
        min-height: 100vh;
    }

    .quiz-page-body-area {
        width: 96vw;
        min-width: unset;
        margin-left: 2vw;
        margin-right: 2vw;
        height: unset;
        min-height: 70%;
        top: unset;
        position: unset;
        margin-top: 20px;
        padding-bottom: 20px;
    }

    .quiz-start-page-question-count {
        margin-top: 30px;
    }

    .quiz-start-page-description-container {
        padding-top: 20px;
    }

    .quiz-page-header-container {
        margin: 0;
        display: flex;
        flex-flow: row wrap;
    }

    .quiz-start-page-title {
        padding-top: 50px;
        width: 80%;
        margin: auto;
    }

    .quiz-page-cse-logo {
        margin-left: 10px;
        width: 150px;
        margin-right: calc(100vw - 160px);
    }

    .quiz-page-breadcrumbs-container {
        padding-left: 5px;
        padding-right: 5px;
        display: grid;
        text-align: center;
        width: 100vw;
    }

    .questions-progress-bar,
    .results-progress-bar {
        width: 10vw;
    }

    .quiz-question-progress-fraction,
    .quiz-question {
        padding-top: 10px;
    }

    .quiz-question-options-container {
        padding-top: 0;
    }

    .quiz-results-page-card-buttons {
        display: block;
    }

    .quiz-popup {
        width: 92%;
        left: 4%;
        height: fit-content;
    }

    .quiz-return-button-container {
        margin-bottom: 30px;
        height: 50px;
    }

    .quiz-questions-page-navigation-buttons {
        position: unset;
        height: 50px;
        width: fit-content;
        margin: 20px auto;
    }

    .quiz-previous-button-container,
    .quiz-next-button-container,
    .quiz-submit-button-container {
        position: unset;
        margin: 2vw;
    }

    .quiz-previous-button,
    .quiz-next-button,
    .quiz-submit-button {
        width: 115px;
        position: unset;
    }

    .quiz-previous-button img,
    .quiz-next-button img {
        display: none;
    }

    .quiz-previous-button .quiz-previous-button-text,
    .quiz-next-button .quiz-next-button-text,
    .quiz-submit-button .quiz-previous-button-text {
        width: fit-content;
        margin: 0 auto;
    }

    .quiz-next-button,
    .quiz-submit-button {
        padding-left: unset;
    }

    .quiz-popup-buttons {
        margin-bottom: 10px;
    }

    .quiz-continue-course-button-container {
        height: 75px;
    }

    .quiz-continue-course-button-container-center {
        height: 50px;
    }

    .result-image-container.merit {
        width: 240px;
        margin: auto;
    }

    .result-image-container.merit img {
        margin: 0;
    }

    .course-section-block-heading {
        margin-top: 20px;
    }

    .course-section-block-button {
        min-width: 100px;
        width: 100px;
        max-width: 100px;
    }

    .coin-detail-summary-block {
        display: block;
    }

    .coin-detail-stats {
        width: 90vw;
        margin: 30px auto;
    }

    .coin-detail-summary {
        width: 96vw;
        margin: 0 5px 20px 5px;
        padding-top: 20px;
    }

    .coin-price-change img {
        margin-top: 0;
        padding-left: 0 !important;
    }

    .coin-detail-summary-whitepaper img,
    .coin-detail-summary-web img {
        padding-left: 0 !important;
    }

    .coin-detail-summary-block .coin-text-down,
    .coin-detail-summary-block .coin-text-up {
        padding-top: 10px;
        display: inline-block;
        max-width: calc(100% - 10px)
    }

    .coin-detail-summary img,
    .coin-detail-summary-price-block {
        padding-left: 5px;
    }

    .coin-detail-summary-pair {
        padding-left: 10px;
        padding-top: 6px;
    }

    .coin-detail-summary-papers-block {
        display: block;
    }

    .coin-detail-summary-whitepaper,
    .coin-detail-summary-web {
        padding-left: 0;
    }

    .coin-detail-stats-info img {
        padding-left: 0;
    }

    .coin-detail-stats-block .separator {
        max-width: 60vw;
    }

    .coin-detail-contents {
        width: 90vw;
        margin: auto;
        padding-left: unset;
        padding-right: unset;
    }

    .coin-detail-summary-block .home-contents {
        display: block;
    }

    .coin-detail-stats-info-content {
        white-space: nowrap;
    }

    .info-panel-container {
        white-space: normal;
    }

    .coin-data-analysis-wide,
    .coin-data-analysis-narrow {
        width: 100%;
    }

    .coin-data-analysis-narrow {
        display: none;
    }

    .coin-data-analysis-narrow.mobile {
        display: block;
    }

    .coin-data-analysis-block {
        display: block;
    }

    .coin-data-analysis-vote {
        height: fit-content;
    }

    .coin-data-analysis-voted-choice,
    .coin-data-analysis-voted-text,
    .coin-data-analysis-voted-result {
        margin-right: 5px;
    }

    .coin-data-analysis-technical {
        margin-top: 20px;
    }

    .coin-data-statistics-container {
        margin-top: 20px;
    }

    .coin-data-analysis-vote-container {
        padding: unset;
    }

    .coin-data-analysis-vote {
        margin-top: 20px;
    }

    .tradingview-widget-container-dark-mobile,
    .tradingview-widget-container-light-mobile {
        display: block;
        width: 100%;
        height: 450px;
    }

    .tradingview-widget-container-dark,
    .tradingview-widget-container-light {
        display: none;
    }

    .home-section-info-list-container {
        max-width: 100vw !important;
        width: 100vw !important;
        display: flex;
        padding: 0 !important;
        margin-left: -12px;
    }

    .home-section-info-list {
        width: unset;
        height: unset;
    }

    .shift-margin {
        margin-left: 0 !important;
    }

    .coin-grid-row > * {
        flex-shrink: 1;
    }

    .related-news-cards-container {
        padding-left: 10px;
    }

    .info-page-summary {
        display: block;
        min-width: 96vw;
        max-width: 96vw;
        width: 96vw;
    }

    .info-subpage-summary-container,
    .info-subpage-summary {
        display: block;
        min-width: unset;
    }

    .info-subpage-summary-content {
        width: 96vw;
    }

    .info-subpage-summary-image {
        width: unset;
        margin-left: 0;
    }

    .info-subpage-cards {
        padding: 40px 4px 200px 4px;
    }

    .page-submenu-heading {
        margin-left: 5px;
        margin-right: 5px;
    }

    .encyclopedia-page-word-container {
        display: block;
    }

    .encyclopedia-page-word-container {
        height: unset;
    }

    .encyclopedia-word-description-container {
        width: unset;
        padding: 10px 10px 5px 0;
    }

    .encyclopedia-word-image-container {
        width: 286px;
    }

    .encyclopedia-word-image-text-container {
        top: 35px;
        left: 110px;
    }

    .encyclopedia-page-image,
    .encyclopedia-page-video {
        height: 180px;
        margin-top: 20px;
    }

    .info-article-content .container {
        padding: 10px;
    }

    .encyclopedia-article-heading {
        width: 90vw;
    }

    .coin-page-show-rows {
        top: -30px;
    }

    .coin-subpage-cards .col {
        margin-top: 20px;
    }

    .gainer-card-container .container,
    .trending-card-container .container,
    .loser-card-container .container {
        padding-left: 10px;
        padding-right: 10px;
    }

    .coin-subpage-cards .gainer-card-container,
    .coin-subpage-cards .trending-card-container,
    .coin-subpage-cards .loser-card-container {
        padding-top: 0;
    }

    .trending-card-button {
        left: 20px;
    }

    .coin-grid-footer-prev,
    .coin-grid-footer-next {
        display: flex;
    }

    .coin-grid-footer-contents {
        overflow-x: hidden;
        max-width: 100%;
    }

    .coin-grid-footer-contents button {
        margin-left: 2px;
        margin-right: 2px;
    }

    .coin-grid-footer-pages-container {
        width: calc(100% - 220px);
        overflow-x: hidden;
    }

    .market-data-grid-row {
        height: 52px;
        padding-top: 14px;
    }

    .market-data-grid-row div {
        margin-top: 3px;
    }

    .market-data-grid-row .number,
    .market-data-grid-row .exchange,
    .market-data-grid-row .pairs,
    .market-data-grid-row .plus-depth,
    .market-data-grid-row .minus-depth {
        padding-top: 1px;
    }

    .market-data-grid-header .number,
    .market-data-grid-row .number {
        width: 20px;
        min-width: 20px;
        overflow: hidden;
    }

    .market-data-grid-header .exchange,
    .market-data-grid-row .exchange {
        width: 120px;
        min-width: 100px;
        overflow-x: hidden;
    }

    .market-data-grid-header .price,
    .market-data-grid-row .price,
    .market-data-grid-header .pairs,
    .market-data-grid-row .pairs,
    .market-data-grid-header .plus-depth,
    .market-data-grid-row .plus-depth,
    .market-data-grid-header .minus-depth,
    .market-data-grid-row .minus-depth,
    .market-data-grid-header .day-volume,
    .market-data-grid-row .day-volume {
        width: 120px;
        overflow-x: hidden;
        height: 32px;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 0;
        margin-left: 10px;
    }

    .market-data-grid-header .perc-volume,
    .market-data-grid-row .perc-volume,
    .market-data-grid-header .market-cap,
    .market-data-grid-row .market-cap {
        display: none;
    }

    .profile-page-content {
        margin-left: 0;
    }

    .profile-page-navigation-buttons {
        display: none;
    }

    .profile-page-account-details {
        border: none;
    }

    .profile-image-input {
        margin: 16px 0 20px 20px;
    }

    .profile-page-account-preferences,
    .profile-page-account-details {
        margin: 5px 0 20px 0;
        border-left: none !important;
    }

    .profile-image-save-button {
        margin-left: 10px;
    }

    .profile-page-account-preferences {
        width: 96vw;
    }

    .dialog-container {
        width: 96%;
        left: 2%;
    }

    .course-overview-about-container,
    .course-page-summary-container {
        display: block;
    }

    .course-page-summary-container-section-container img {
        width: 30px;
    }

    .course-page-summary-container-section-container {
        padding-bottom: 10px;
        padding-left: 1px;
        margin-right: 20px;
    }

    .course-page-summary-start-button {
        margin: 20px 0;
    }

    .course-section-opener,
    .course-section-opener-large {
        min-width: unset;
        padding: 10px 0;
    }

    .chapter-overview-video-container .video {
        left: calc(50% - 29px);
        top: calc(50% - 29px);
    }

    .search-search-box-container input[type=text] {
        padding: 10px 20px 10px 40px;
        width: 100%;
    }

    .search-page-encyclopedia-list,
    .search-page-list {
        height: unset;
    }

    .search-page-encyclopedia-list img,
    .search-page-list img {
        width: 100%;
    }

    .search-page-results,
    .search-search-box-container,
    .search-page-get-more-container,
    .search-page-submenu-heading,
    .search-page-coins,
    .search-page-articles .col-sm-6,
    .search-page-learn .col-sm-4,
    .search-page-info-list .col-sm-4 {
        width: 92vw;
        margin: auto;
    }

    .search-search-box-container,
    .search-page-coins,
    .search-page-articles .col-sm-6,
    .search-page-learn .col-sm-4,
    .search-page-info-list .col-sm-4 {
        margin-top: 20px;
    }

    .search-page-articles .col-sm-6,
    .search-page-learn .col-sm-4,
    .search-page-info-list .col-sm-4 {
        padding-left: 0;
        padding-right: 0;
    }

    .search-page-number-results {
        margin-bottom: 10px;
    }

    .info-article-image {
        height: unset;
    }

    .learn-category-card-img-col {
        height: 105px;
    }

    .learn-category-card-img-col img {
        width: 100%;
        height: 105px;
        object-fit: cover;
    }

    .footer-topic-heading,
    .footer-topic-text {
        display: none;
    }

    .legal-content,
    .social-media-logo {
        display: block;
    }

    .legal-content {
        max-width: 90vw;
        margin: 0;
    }

    .legal-text {
        margin-top: 20px;
    }

    .legal-container {
        top: 100px;
    }

    .footer-container {
        height: 570px;
    }

    .footer-container .row {
        position: relative;
        width: 96vw;
        margin-left: 0;
    }

    .follow-us {
        position: absolute;
        right: 20px;
        top: 0;
        width: fit-content !important;
    }

    .follow-us .footer-topic-heading {
        display: block;
    }

    .social-media-logo img {
        margin-bottom: 10px;
    }

    .home-section-news-list-story {
        height: 106px;
    }

    .news-page-list-story {
        height: 71px;
    }

    .burger-menu.open {
        transform: translateX(0);
    }

    .burger-menu.closed {
        transform: translateX(1000px);
    }

    .burger-menu-header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 25px 0 25px;
        min-height: 100px;
        max-height: 100px;
        z-index: 102;
    }

    .burger-menu-body-container {
        padding: 0 25px 0 25px;
        z-index: 102;
    }

    .burger-menu-sections-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .burger-menu-button-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        z-index: 102;
    }

    .burger-menu-buttons {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 12px;
    }


    .burger-menu-buttons.social {
        margin: 0 60px;
    }

    .burger-menu-button-light {
        width: 100%;
        border-radius: 10px;
        padding: 7px 5px 7px 5px;
        font-size: 14px;
        border: solid 1px #FF9017;
        height: 45px;
    }

    .burger-menu-button-dark {
        width: 100%;
        border-radius: 10px;
        padding: 7px 5px 7px 5px;
        font-size: 14px;
        background-color: #3866F2;
        color: white;
        border: solid 1px #3866F2;
        height: 45px;
    }

    .burger-menu-button-theme {
        width: 35%;
        border-radius: 10px;
        font-size: 10px;
        border: none;
        height: 38px;
    }

    .burger-menu-button-theme img {
        padding: 5px;
        width: 30px;
    }

    .burger-menu-item,
    .burger-menu-dropdown {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: fit-content;
        padding: 30px 0 30px 0;
        font-size: 18px;
    }

    .burger-menu-dropdown-submenu {
        display: flex;
        padding-left: 5px;
        font-size: 15px;
        padding-bottom: 25px;
        will-change: display;
    }

    .burger-menu-dropdown-submenu.closed {
        display: none;
    }

    .burger-dropdown-options-block {
        padding-right: 150px;
    }

    .burger-menu-divider {
        border-bottom: solid 1px rgba(112, 112, 112, 0.21);
        padding-top: 2px;
    }

    .burger-menu-licences {
        font-size: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 20px 50px 20px 50px;
    }

    .burger-menu-footer {
        display: flex;
        justify-content: space-evenly;
        padding: 5px 0 5px 0;
    }

    .burger-menu-item a:link,
    .burger-menu-item a:visited,
    .burger-menu-item a:hover,
    .burger-menu-item a:active,
    .burger-footer-link {
        text-decoration: none;

    }

    .burger-dropdown-item {
        padding: 15px 0 15px 0;
    }

    .burger-menu-chevron-container {
        position: absolute;
        top: 0;
        left: 0;
        height: 300px;
        width: 100%;
        z-index: 101;
    }

    .burger-menu-header-container a img {
        margin-top: 14px;
        margin-left: -10px;
    }


    .burger-menu-close {
        margin-top: 0;
        margin-left: 25px;
        width: 20px;
    }

    .home-section-trending-news {
        display: none;
    }

    .home-user-container {
        display: none;
    }

    .mobile-search-icon {
        display: block;
    }

    .col-sm-4.book {
        padding-bottom: unset;
        margin: auto;
    }

    .col.right.bold,
    .col.right.medium {
        margin: auto;
    }

    .home-learn-container-mobile {
        position: relative;
        z-index: 1;
        padding-bottom: 10px;
    }

    .home-learn-container-mobile .chevron-container-dark {
        margin-top: -80px !important;
    }

    .footer-subscribe-container {
        margin: 0 2px;
    }

    .learn-2-earn-heading {
        font-size: 24px;
    }

    .learn-2-earn-text {
        font-size: 14px;
    }

    .home-learn-2-earn-container {
        margin-right: unset;
    }

    .one-stop-head {
        font-size: 30px;
    }

    .one-stop-text {
        font-size: 13px;
    }

    .home-section-course-details-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    .home-section-course-details {
        white-space: nowrap;
    }

    .home-section-course-label {
        font-size: 10px;
    }

    .home-section-course-value {
        font-size: 10px;
        padding-left: 3px;
        padding-right: 0;
    }


    .more-guides-container a {
        display: flex;
        justify-content: flex-end;
        padding-top: 15px;
        font-size: 10pt;
    }

    .more-guides-container a img {
        padding-left: 5px;
        padding-top: 3px;
    }

    .news-story-most-read-container,
    .related-news-container,
    .story-social-container {
        display: none !important;
    }

    .news-story-details-container {
        padding-bottom: 10px !important;
        min-height: 20px !important;
    }

    .news-story-details-author-info {
        border-right: none !important;
        display: flex;
        width: 280px;
    }

    .news-date {
        order: 1;
    }

    .news-author {
        order: 2;
        padding-right: 10px !important;
        padding-bottom: 0 !important;
    }

    .news-story-heading {
        font-size: 24px;
    }

    .news-story-read-time-container {
        position: unset !important;
    }

    .news-story-image {
        height: 175px;
        border-radius: 0 !important;
        overflow: hidden;
    }

    .news-story-content-container {
        margin-top: 5px !important;
        padding-top: 0 !important;
    }

    .story-list-text .news-story-details {
        display: flex !important;
        margin-top: 7px;
    }

    .news-story-details .mobile-field {
        display: block;
    }

    .news-story-details .news-date,
    .news-story-details .news-author {
        width: 50% !important;
    }

    .news-story-details .news-date {
        margin: 2px !important;
    }

    .story-list-headline {
        max-height: 38px;
    }

    .story-page-list {
        height: 80px;
    }

    .info-article-content-fake-step-container,
    .info-article-content-step-container {
        flex-direction: column;
        align-items: center;
    }

    .story-page-list img {
        position: relative;
        width: 113px;
        padding: 0 !important;
    }

    .story-page-list .story-list-text {
        width: calc(100% - 116px);
    }

    .home-learn-container-mobile .chevron-container-light {
        margin-top: -31px;
        position: absolute;
        z-index: 0;
    }

    .related-news-title {
        letter-spacing: 0.1px !important;
    }

    .news-story-trending {
        letter-spacing: 0.1px !important;
    }

    .home-section-news-button {
        z-index: 0 !important;
    }

    .affiliate-link-container {
        z-index: 0 !important;
    }

    .home-section-heading {
        z-index: 1;
        position: relative;
    }

    .row.home-section-course-content {
        justify-content: space-between;
    }

    .home-section-course-value.difficulty {
        margin-right: 15px;
    }

    .home-section-course-value.time {
        width: 64px;
    }

    .home-newsletter-container {
        padding-bottom: 60px !important;
    }

    .home-info-content.home-contents {
        padding-top: 30px;
    }

    .home-learn-content.home-contents {
        margin-top: -30px;
        padding-top: 60px;
    }

    .coin-detail-stats-block {
        display: block;
    }

    .coin-detail-stats-header {
        display: none;
    }

    .coin-detail-stats-info {
        margin-top: 10px;
    }

    .coin-detail-stats-block .col .col,
    .coin-detail-stats-block .separator {
        border-bottom: none !important;
    }

    .coin-detail-stats-block .col,
    .coin-detail-stats-block .separator {
        padding-right: 0 !important;
    }

    .coin-detail-stats-info-content {
        margin-top: 10px;
        width: 170px !important;
    }

    .coin-detail-stats-info-content img {
        margin-left: 10px;
        margin-top: -5px;
    }

    .coin-price-change {
        text-align: right;
    }

    .separator .coin-price-change {
        width: 100%;
    }

    .coin-text-up,
    .coin-text-down,
    .coin-detail-stats-text {
        font-family: cseGothamMedium, serif !important;
        font-size: 14px;
    }

    .coin-detail-stats-block {
        padding-top: 0;
    }

    .coin-detail-stats-text {
        width: 100%;
        text-align: right;
    }

    .coin-detail-summary-papers-block {
        display: flex;
    }

    .coin-detail-summary-web,
    .coin-detail-summary-whitepaper {
        margin-left: 20px;
    }

    .coin-detail-summary-rank {
        margin-top: 3px;
    }

    .coin-detail-summary-price-block .coin-price-change .coin-text-up,
    .coin-detail-summary-price-block .coin-price-change .coin-text-down {
        margin-top: 0 !important;
        font-size: 18px !important;
    }

    .coin-detail-stats-block .col {
        display: flex;
        border-bottom: 0.5px solid #9EAAC6;
    }


    .news-title {
        font-size: 16px;
    }

    .news-title-trending {
        margin-left: 16px;
        padding-top: 9px;
        font-size: 14px;
        line-height: 1.2;
    }

    .news-title.col {
        padding: unset;
        margin: auto;
    }

    .home-section-main-news-heading {
        font-size: 18px;
    }

    .home-section-main-news-heading {
        height: fit-content;
    }

    .news-story-paragraph-break {
        line-height: 1.7;
    }


    .news-visible-recent.trending {
        margin-left: 12px;
        padding-top: 5px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .chevron-container-light.market {
        position: relative;
        z-index: 0;
        margin-top: -25px;
    }

    .news-page-market-container-mobile .container {
        position: relative;
        margin-top: -40px;
    }

    .news-page-market-story {
        margin-top: 12px;
        height: unset;
    }

    .news-page-market-story-heading {
        height: unset;
    }

    .news-page-market-story img {
        height: 160px;
    }

    .news-page-market-contents .row .col-sm-4 {
        padding-bottom: 12px;
    }

    .news-page-market-container-mobile .home-section-news-button-container {
        margin-top: 15px !important;
        margin-bottom: 15px;
    }

    .news-page-container-mobile .chevron-container-light {
        position: relative;
        top: 24px;
    }

    .trending-news-container .chevron-container-dark {
        position: absolute;
        z-index: 0;
        margin-top: -1px;
    }

    .trending-news.col {
        margin: auto;
        max-width: 96vw !important;
        padding-bottom: 55px;
    }

    .coin-data-analysis-chart article div {
        height: 410px !important;
    }

    .coin-data-analysis-chart article div div {
        height: 410px !important;
    }

    .coin-data-analysis-chart {
        padding: unset !important;
    }

    .coin-data-analysis-vote-choose button {
        margin-bottom: 5px !important;
    }

    .coin-data-analysis-vote-text div {
        margin-bottom: 6px !important;
    }

    .market-data-grid-table-container {
        overflow-x: scroll;
    }

    .market-data-grid-container {
        overflow-x: visible !important;
    }

    .market-data-grid-table-container::-webkit-scrollbar {
        height: 5px;
        background-color: #EBEBFF;
    }

    .market-data-grid-table-container::-webkit-scrollbar-thumb {
        background-color: #FF9017;
    }

    .market-data-grid-header {
        padding-bottom: 0 !important;
    }

    .market-data-grid-header,
    .market-data-grid-row-container {
        width: 650px;
    }

    .separator .coin-price-change,
    .col .coin-detail-stats-text,
    .col .separator .coin-detail-stats-text {
        padding-right: 20px !important;
    }

    .row .home-section-info-list {
        display: flex;
        overflow-x: hidden;
        flex-wrap: nowrap !important;
    }

    .home-section-info-container {
        width: 92vw !important;
        margin-bottom: unset !important;
    }

    .app-title {
        padding-top: 20px;
    }

    .navbar.navbar-custom.navbar-fixed-top.home-contents {
        margin-bottom: -10px;
        height: fit-content;
    }

    .burger-menu-header-container .cse-logo {
        margin-top: 0 !important;
    }

    .home-section-info-pages-container {
        display: block;
        margin-top: 25px;
    }

    .home-section-info-pages-container.arrow {
        display: block;
        margin-top: 0 !important;
    }

    .info-subpage-heading {
        font-size: 20px;
        width: 100%;
        padding-right: 40%;
        margin-bottom: 40px;
        margin-top: 30px;
        line-height: 1.6;
    }

    .info-subpage-summary-image {
        position: absolute;
        top: 50px;
        right: 20px;
        width: 150px;
        height: 150px;
    }

    .info-subpage-summary-content {
        margin-left: 10px;
        padding-right: 30px;
    }

    .info-subpage-summary {
        position: relative;
    }

    .info-page-summary-button {
        margin-bottom: 30px;
    }

    .info-subpage-cards .row {
        max-width: 100vw !important;
        width: 100vw !important;
    }

    .info-subpage-cards .col-sm-4 {
        max-width: unset !important;
    }

    .home-mobile .chevron-container-dark {
        margin-top: -100px;
    }

    .home-section-news-button-container {
        margin-top: 30px !important;
    }

    .home-learn-container-mobile .home-section-news-button-container {
        margin-top: unset !important;
        top: 40px
    }

    .hardware-wallet-intro-content {
        display: block;
    }

    .hardware-wallet-intro-content .col {
        display: flex;
    }

    .affiliate-popup-container {
        left: -140px;
        top: -180px;
        width: 250px;
    }

    .coin-subpage-cards.home-contents {
        display: none;
    }

    .coin-data-analysis-description h1 {
        padding-bottom: 0;
        padding-top: 20px;
    }

    .coin-data-analysis-description {
        padding-top: 10px;
    }

    .coin-detail-contents {
        padding-top: 0;
        margin-top: -70px !important;
    }

    .coin-detail-summary-name {
        width: unset;
        max-width: 200px;
        padding-right: 10px;
    }

    .coin-detail-summary-ticker {
        width: unset;
        max-width: 50px;
        padding-right: 10px;
        padding-top: 12px;
    }

    .coin-detail-summary-price {
        font-size: 35px;
    }

    .coin-detail-summary-pair {
        margin-top: 8px;
        font-size: 13px;
    }

    .learn-module-card-button {
        left: unset !important;
    }

    .crypto-dashboard-heading {
        padding-top: 10px !important;
    }

    .crypto-dashboard-user-profile {
        margin-top: 18px;
    }

    .crypto-dashboard-progress-container {
        max-width: unset !important;
        flex-direction: column;
    }

    .crypto-dashboard-progress-container-right {
        width: 100%;
        border-top-right-radius: unset;
        border-bottom-left-radius: 15px;
        display: flex;
        justify-content: center;
    }

    .crypto-dashboard-progress-wheel-container {
        margin-left: unset !important;
        margin-top: -10%;
        width: 250px;
        height: 250px;
    }

    .crypto-dashboard-progress-wheel-rocket {
        left: 38%;
        top: 23%;
    }

    .crypto-dashboard-progress-wheel-view_rockets {
        top: 73%;
        left: 36%;
    }

    .crypto-dashboard-progress-wheel-level {
        left: 40%;
        top: 17%;
    }

    .crypto-dashboard-progress-wheel {
        margin-left: -30%;
        margin-top: -13%;
    }

    .crypto-dashboard-progress-container-row {
        display: flex;
    }

    .crypto-dashboard-heading,
    .learn-category-heading,
    .course-page-title {
        font-size: 35px;
    }

    .learn-categories-container .home-contents {
        z-index: 1;
        position: relative;
        top: -105px;
    }

    .learn-categories-heading-container {
        margin-bottom: 30px;
    }

    .page-submenu-heading li,
    .locked-view-certification-text {
        font-size: 11px;
    }

    .learning-objective-component {
        padding-bottom: 10px;
    }

    .course-page-mobile-container {
        display: flex;
    }

    .crypto-dashboard-how-to-get-started {
        display: flex;
        margin: 20px 0 25px 0;
    }

    .course-overview-about-container {
        border-bottom: unset !important;
    }

    .learning-objectives-title-container {
        padding: 10px 0 25px 0;
    }

    .course-section-block-text {
        line-height: 1.6 !important;
    }

    .course-section-card-text {
        padding-left: unset;
    }

    .learn-categories-container .chevron-container-dark {
        z-index: 0;
        position: relative;
    }

    .learn-module-card-container {
        margin-bottom: unset;
    }

    .module-cards-menu-container {
        margin-top: 30px;
    }

    .chapter-score-text {
        font-size: 12px;
    }

    .course-section-card-text {
        padding-left: 10px;
    }

    .page-not-found-text-container {
        width: 90%;
        left: 5%;
        top: 7%;
        height: 40%;
    }

    .page-not-found-title-container {
        font-size: 18px;
    }

    .page-not-found-message-container {
        font-size: 24px;
    }

    .page-not-found-text-container {
        width: 94%;
        left: 3%;
        top: 7%;
        height: 40%;
    }

    .crashed-rocket {
        position: absolute;
        bottom: 50px;
        width: 100%;
        left: 0;
    }

    .page-not-found-message-container {
        display: block;
    }

    .learn-categories-container {
        padding-top: 70px;
    }

    .search-page-list-text {
        width: 60% !important;
    }

    .search-page-encyclopedia-list-text {
        width: 60% !important;
    }

    .search-page-encyclopedia-list-text.search {
        width: 100% !important;
    }

    .encyclopedia-word-image-container.search {
        object-fit: fill;
        width: 100%;
        padding-right: 0 !important;
    }

    .encyclopedia-thumbnail-image.search {
        object-fit: fill;
    }

    .encyclopedia-search-word-image-text-container.search {
        position: absolute !important;
        left: 0;
    }

    .newsletter-signup-image-container {
        display: none;
    }

    .newsletter-signup-form-container {
        width: 100%;
        padding-right: 0 !important;
    }

    .newsletter-signup-heading {
        padding: 0 20px 0 20px;
        width: 330px;
        text-align: center;
    }

    .newsletter-signup-heading.blue {
        padding: 0 !important;
    }

    .checkbox-container {
        width: 4% !important;
        height: unset !important;
    }

    .newsletter-consent-input {
        width: 100% !important;
        height: 40% !important;
    }

    .newsletter-signup-form-checkbox-label {
        width: 90% !important;
        height: unset !important;
    }

    .cookies-pop-up-container {
        font-size: 20px;
    }

    .cookies-pop-up-text {
        padding-top: 0;
        padding-right: 20px;
    }

    .cookies-pop-up-container button {
        width: 150px;
        height: 40px;
        margin: 0 10px;
    }


    .coin-portfolio-title-button-container {
        display: block;
        margin-top: 10px;
    }

    .coin-portfolio-title-button {
        height: 29px;
        position: relative;
        top: 66px;
        right: 1px;
    }

    .coin-portfolio-title {
        width: 190px;
        font-size: 20px;
        margin-bottom: 40px;
    }

    .coin-portfolio-all-cards-container {
        display: block;
    }

    .coin-portfolio-card-container {
        margin-top: 5px;
        width: 100%;
        height: 58px;
    }

    .coin-portfolio-card {
        height: 47px;
        width: 100%;
        display: flex;
        position: relative;
        box-shadow: 0 10px 15px 0 rgb(0, 0, 0, 0.18);
    }

    .coin-portfolio-card-values-top-container {
        display: flex;
    }

    .coin-portfolio-card .row {
        width: 100%;
    }

    .coin-portfolio-card .row .col-sm-9 {
        order: 1;
        width: 100%;
    }

    .coin-portfolio-card .row .col-sm-3 {
        order: 2;
        position: absolute;
        top: 6px;
        left: 85px;
    }

    .coin-portfolio-card-values-top-container {
        width: 100%;
    }

    .coin-portfolio-card-values-top-container .coin-portfolio-card-percent {
        padding-left: 31%;
    }

    .coin-portfolio-card-percent {
        position: relative;
        top: -7px;
        left: 24px;
    }

    .coin-portfolio-card-percent {
        margin-top: 3px;
    }

    .coin-portfolio-card-percent.large img {
        margin-top: 0;
    }

    .coin-portfolio-card-values-container,
    .coin-portfolio-card-title {
        width: 50%;
    }

    .coin-portfolio-card-values-container {
        position: relative;
        right: 10px;
        top: -1px;
        justify-content: flex-end;
    }

    .coin-portfolio-number-container {
        margin: -1px 5px 0 0;
    }

    .coin-portfolio-card-img img {
        width: 32px;
        height: 32px;
    }

    .coin-portfolio-all-container {
        padding-bottom: 40px;
    }

    .transaction-grid-header .price,
    .transaction-grid-row .price,
    .transaction-grid-header .profit-loss,
    .transaction-grid-row .profit-loss,
    .transaction-grid-header .quantity,
    .transaction-grid-row .quantity,
    .coin-grid-header .holdings.portfolio,
    .coin-grid-header .hour.portfolio,
    .hour-portfolio,
    .day-portfolio,
    .holdings-portfolio,
    .price-portfolio {
        display: none;
    }

    .coin-portfolio img {
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
    }

    .coin-portfolio-text-container {
        flex-direction: column;
        justify-content: space-between;
    }

    .portfolio-grid-button-container {
        padding-left: 10px;
        justify-content: flex-end;
    }

    .portfolio-popup-card {
        top: 21vh;
        left: 3vw;
    }

    .coin-portfolio-title-button-container.portfolio-menu .coin-portfolio-title-button {
        margin-top: 0;
    }

    .search-search-box.addcoin {
        width: 90% !important;
        padding: 10px 0 10px 10px !important;
    }

    .portfolio-popup-card-title-container.editPortfolio,
    .portfolio-popup-card-title-container.buttons {
        display: block;
        width: 100%;
    }

    .portfolio-popup-card-title-item {
        width: 100%;
    }

    .portfolio-popup-button-container {
        width: 100%;
    }

    .portfolio-popup-card-title-item .portfolio-popup-title-container .portfolio-popup-title {
        padding-bottom: 10px;
    }

    .portfolio-transactions-details-container {
        display: block;
    }

    .portfolio-transactions-details-section {
        width: 100%;
        margin-top: 15px;
        height: 47px;
        display: flex;
        min-width: 100% !important;
    }

    .portfolio-transaction-value {
        padding-left: 15%;
        text-align: right;
    }

    .portfolio-transaction-percentage {
        margin-left: 10px;
    }

    .transaction-grid-row .amount {
        width: 100px;
    }

    .transaction-grid-row.row .col {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .transaction-grid-row.row {
        display: flex;
        flex-wrap: unset;
        margin: 0;
        padding: 5px 0;
    }

    .portfolio-transactions-actions-button.edit {
        padding-left: 10px;
        margin: 0 0 0 30px;
    }

    .portfolio-show-assets-toggle {
        padding-top: 20px;
    }

    .hour.portfolio {
        display: none;
    }

    .portfolio .coin-grid-container {
        margin-top: 0;
    }

    .coin-grid-row.portfolio.headings {
        height: 40px;
        padding-top: 5px;
        justify-content: space-between;
    }

    .coin-grid-row.portfolio {
        justify-content: space-between;
    }

    button.delete-button {
        margin-right: 0
    }

    .coin-portfolio-text-container .ticker {
        padding-left: 0;
    }

    .portfolio .coin-grid-footer-contents {
        justify-content: space-between;
    }

    .coin-portfolio-title-button-container {
        position: relative;
    }

    .coin-portfolio-separator {
        padding-bottom: 30px;
    }

    .coin-portfolio-edit-button {
        position: relative;
        left: 145px;
        top: -8px
    }

    .coin-portfolio-all-total-container {
        margin-top: -35px;
    }

    .coin-grid-footer-pages-container.portfolio {
        width: unset;
    }

    .coin-portfolio-star {
        width: 18px;
        height: 18px;
    }

    .portfolio-chart-outer-outline {
        width: 100%;
        height: 100%;
    }

    .portfolio-chart-chart-container {
        width: 90%;
        height: 90%;
    }

    .portfolio-chart-coins-container {
        width: 50%;
        padding: 0;
    }

    .portfolio-chart-coin-row .col-sm-4 {
        margin-left: 25px;
    }

    .portfolio-chart-coin-row.row {
        flex-wrap: unset;
    }

    .portfolio-chart-coin-row.row .col-sm-4 {
        max-width: 75px;
    }

    .portfolio-chart-coin-percentage {
        flex-direction: unset;
        text-align: unset;
    }

    .portfolio-chart-coin-name.col-sm-4 {
        margin-left: 10px;
        font-size: 12px;
    }

    .portfolio-chart-coin-percentage.col-sm-4 {
        margin-left: 10px;
        font-size: 12px;
    }

    .portfolio-chart-coin-row {
        padding: 5px 0;
        width: 100%;
    }

    .portfolio-chart-container {
        width: 45vw;
        height: 45vw;
    }

    .profitLoss-portfolio {
        width: 100px;
    }

    .coin-portfolio-all-container .coin-portfolio-title {
        margin-bottom: 44px;
    }

    .coin-portfolio-all-container .coin-portfolio-title-button {
        top: 69px;
    }

    .thin-menu,
    .medium-menu,
    .wide-menu {
        width: 95vw;
        left: calc((100% - 95vw) / 2) !important;
        right: calc((100% - 95vw) / 2) !important;
        top: 100px !important;
        height: 80vh;
    }

    .portfolio-popup-card.holdingsTab {
        width: 95vw;
        left: calc((100% - 95vw) / 2) !important;
        right: calc((100% - 95vw) / 2) !important;
        top: 100px !important;
    }

    .portfolio-popup-card-title-container.transactionButtons {
        padding: 20px 0 30px;
    }

    .portfolio-popup-button.watchlist {
        max-width: unset;
    }

    .portfolio-popup-button-container.create {
        padding-bottom: 20px;
    }

    .portfolio-popup-name-input {
        height: 42px;
    }

    .portfolio-popup-button {
        height: 51px;
        width: 95%;
    }

    .portfolio-popup-card-title-container {
        padding: 30px 25px 0;
    }

    .portfolio-popup-cross.create {
        top: 25px;
    }

    .coin-portfolio-title-button.transaction {
        left: 4px;
        top: 343px;
        z-index: 98;
    }

    .portfolio-transactions-subheading.transaction {
        width: unset;
    }

    .portfolio-transactions-subheading {
        width: 100%;
    }

    .portfolio-transactions-title img {
        width: 32px;
        height: 32px;
    }

    .page-submenu-title.transactions {
        padding-bottom: 15px;
    }

    .transaction .transaction-grid-header.row,
    .transaction .transaction-grid-row.row {
        padding: 15px 0;
    }

    .transaction .coin-grid-container {
        width: 100%;
    }

    .portfolio-grid-container.transaction {
        padding: 0 20px;
    }

    .transaction .transaction-grid-header.row,
    .transaction-grid-header .actions,
    .transaction-grid-row .actions {
        margin: 0;
    }

    .transaction-grid-header .col {
        padding: 0 !important;
    }

    .transaction .type,
    .transaction .date,
    .transaction .amount,
    .transaction .actions {
        width: 25%;
    }

    .portfolio-transactions-actions-button img {
        margin-left: 15px;
    }

    .coin-portfolio-total-balance-symbol {
        margin-top: -2px !important;
    }

    .portfolio-transactions-profit-loss-container {
        margin-right: 16px;
    }

    .portfolio-transaction-percentage {
        margin-top: 2px;
    }

    .portfolio-transaction-value {
        margin-top: -2px;
    }

    .coin-portfolio-card-values-container .coin-portfolio-card-percent {
        left: 10px;
        top: 0;
    }

    .portfolio-menu .coin-portfolio-title-button {
        top: -8px;
    }

    .portfolio-popup-card.portfolioList {
        top: 230px;
        left: 15px;
    }

    .membership-and-billing-buttons-container {
        display: block;
        height: 120px;
    }

    .membership-manage-buttons-container {
        margin-left: 0;
    }

    .membership-and-billing-container {
        height: 343px;
    }

    .membership-and-billing-payment-button {
        margin-top: 20px;
        margin-left: 0;
    }

    .quiz-question-options-button-selected,
    .quiz-question-options-button {
        padding: 10px;
        margin-left: 5%;
        margin-right: 5%;
        width: 90%;
    }

    .module-page-cse-plus-logo-container {
        display: none;
    }

    .portfolio-popup-holdings-menu-button {
        width: 100%;
    }

    .portfolio-popup-card .search-search-box-container {
        justify-content: flex-start;
    }

    .addcoin .portfolio-popup-title {
        margin: 10px 0 0;
    }

    .portfolioList .portfolio-popup-card-title-container {
        padding: 20px 25px 0;
    }

    .portfolioList .portfolio-popup-title-container {
        padding: unset;
        height: unset;
    }

    .portfolio-popup-card.addPortfolio.medium-menu {
        width: 95vw !important;
    }

    .cse-plus-current-plan-container {
        display: none;
    }

    .cse-plus-membership-cards-container {
        display: block;
    }

    .cse-plus-tiers-title {
        margin-top: 200px;
    }

    .cse-plus-membership-card-container {
        margin: 20px auto !important;
        height: fit-content;
        width: 100%;
    }

    .home-plan-image-container {
        display: none;
    }

    .home-plan-text-container {
        width: 100%;
        height: fit-content;
        padding: 40px;
    }

    .cse-plus-features-container {
        height: fit-content;
    }


    .upgrade-options-popup-container {
        width: 95vw;
        height: 95vh;
        top: calc(50% - 95vh / 2);
        left: calc(50% - 95vw / 2);
    }

    .cse-quick-links-cards-container {
        flex-direction: column;
    }

    .cse-plus-page-quick-links-container {
        position: static;
        margin-top: -140px;
    }

    .cse-plus-quick-link-card {
        width: 210px;
    }

    .cse-plus-quick-link-card-cover-container {
        left: 2px;
    }

    .cse-quick-links-cards-container {
        display: grid;
        grid-template-columns: auto auto;
    }

    .portfolio-popup-title-container.transaction-popup {
        padding: 0;
    }

    .portfolio-popup-title.add-transaction {
        padding-bottom: 0 !important;
    }

    .portfolio-edit-menu-container {
        width: 95vw;
        left: calc(50% - 95vw / 2);
    }

    .portfolio-popup-title-container.edit-portfolio {
        padding: 0;
        margin-top: 0;
    }

    .discord-popup-container {
        width: 95vw;
        height: 70vh;
        left: calc(50% - 95vw / 2);
        top: calc(50% - 70vh / 2);
    }

    .discord-popup-body-input {
        width: 100%;
    }

    .discord-popup-header-logo-container img {
        width: 100px;
        height: 50px;
    }

    .change-membership-pop-up-container {
        width: 95vw;
        height: 95vh;
        top: calc(50% - 95vh / 2);
        left: calc(50% - 95vw / 2);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .change-membership-cards-container {
        height: 60vh;
        padding: 10px;
        flex-direction: column;
        overflow-y: scroll;
    }

    .change-membership-pop-up-title-container {
        padding-top: 87px;
    }

    .change-membership-pop-up-image-container img {
        height: 66%;
    }

    .change-membership-card-container {
        width: unset;
    }

    .change-membership-card {
        margin-top: unset;
        height: unset;
    }

    .change-membership-card-divider {
        margin: 10px 0;
    }

    .change-membership-card-title {
        margin-top: 0;
    }

    .change-membership-card-description-container {
        display: none;
    }

    .change-membership-buttons-container {
        margin: 0 auto 40px;
    }

    .change-membership-confirmation-memberships-container {
        flex-direction: column;
    }

    .change-membership-confirmation-membership.current,
    .change-membership-confirmation-membership.new {
        margin: 20px 0px;
        width: 100%;
    }

    .change-membership-confirmation-plan-start {
        margin-top: 200px;
    }

    .change-membership-pop-up-container.confirmation {
        height: 95vh;
    }

    .cse-plus-new-user-video-container {
        width: 100vw;
        height: 30vh;
    }

    .change-membership-button {
        width: 137px;
    }

    .course-page-title {
        width: 100%;
    }

    .navbar-top-row,
    .home-settings {
        display: none;
    }

    .home-navbar-container {
        padding-right: 0;
    }

    .fixed-nav {
        top: -1px;
    }

    .deals-page-navigation-container {
        display: grid;
    }

    .deals-page-search-bar-container {
        order: 1;
        border-bottom: none !important;
        margin-top: 10px;
        width: 100%;
    }

    .deals-page-tabs-container {
        order: 2;
        width: 100%;
    }

    .deals-page-card-description {
        display: none;
    }

    .deals-page-card-title {
        max-height: 120px;
    }

    .discord-username-popup-container {
        left: 20px;
        top: calc((100% - 370px) / 2);
        width: calc(100% - 40px);
        height: 370px;
    }

    .discord-username-popup-content-container {
        display: block;
    }

    .discord-username-popup-right-container,
    .discord-username-popup-left-container {
        width: 100%;
    }

    .discord-username-popup-right-container img {
        width: 100%;
        margin-left: 0;
    }

    .discord-username-popup-left-container {
        height: 80px;
    }

    .discord-username-popup-right-container {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    .discord-username-popup-description img {
        display: none;
    }

    .news-page-main-story {
        height: fit-content;
        margin: 0;
        border-radius: 0 0 10px 10px;
    }

    .news-page-heading {
        font-size: 16px;
        text-align: center;
        padding: unset;
    }

    .home-section-course-image {
        width: 35% !important;
        max-width: 35% !important;
        margin-left: auto;
        margin-right: -20px;
    }

    .home-section-course a,
    .home-section-course,
    .home-section-course-content {
        width: 100%;
    }

    .home-section-news-main-story {
        height: fit-content;
    }

    .home-section-news-list-story {
        display: flex !important;
        width: 100% !important;
    }

    .home-section-news-list-story .row {
        width: 100%;
    }

    .home-section-news-image {
        width: 194px !important;
        margin-left: auto;
        margin-right: -20px;
    }

    .news-visible-recent {
        padding: 10px;
    }

    .deals-page-content-container {
        padding-bottom: 0;
    }

    .deals-page-card-button-container {
        position: relative;
    }

    #root .navbar {
        width: 100%;
    }

    .home-navbar-container {
        width: 90% !important;
        margin: 0 5%;
    }

    .coin-grid-row-star-container {
        top: 14px;
    }

    .profile-referrals-mobile-button {
        display: block !important;
    }

    .profile-details-save-button {
        margin-left: 0;
    }

    .profile-discord-container,
    .profile-discord-outer-container {
        display: block;
    }

    .profile-discord-link-button {
        margin: 25px 0 0 0;
    }

    .profile-form-container {
        width: 100%;
    }


    .leaderboard-page-body-container {
        display: block;
    }

    .leaderboard-container {
        width: 100%;
    }

    .profile-page-account-referrals {
        width: 100%;
        border: none !important;
        margin: 0 !important;
    }

    .home-contents-extended {
        max-width: 280px;
    }

    .collection-obscurer {
        width: 50px;
        padding: 0 !important;
    }

    .collection-obscurer.left-overflow {
        left: 0;
    }

    .collection-obscurer.right-overflow {
        right: 0;
    }

    .collection-obscurer-button {
        width: 30px;
        height: 30px;
    }

    .collection-obscurer-button img {
        height: 15px;
        width: 8px;
    }

    .new-course-banner-image-container {
        display: none;
    }

    .new-course-banner-out-now-text,
    .new-course-banner-description {
        max-width: 100%;
    }

    .new-course-banner-text-container {
        padding-left: 20px;
        padding-right: 20px;
    }

    .learn-page-intro-container {
        width: 100%;
    }

    .learn-page-hero-container {
        margin: auto;
    }

    .learn-page-intro {
        display: block
    }

    .new-course-banner-text-container {
        padding-left: 20px;
        padding-right: 20px;
    }

    .learn-page-intro-container {
        width: 100%;
    }

    .learn-page-hero-container {
        margin: 20px auto;
    }

    .learn-page-title-content {
        display: block;
        width: 100%;
        padding-bottom: 40px;
    }

    .learn-page-text-container {
        width: 100%;
    }

    .learn-page-title-container {
        display: block;
        margin-top: -280px !important;
    }

    .top-ten-leaderboard-container {
        width: 100%;
    }

    .top-ten-leaderboard-pedestal,
    .top-ten-leaderboard-users-column.second {
        display: none;
    }

    .top-ten-leaderboard-pedestal-container {
        height: 15%;
    }

    .top-ten-leaderboard-users-container {
        padding-top: 40px;
        height: 85%;
    }

    .quiz-results-passed-container,
    .quiz-results-merit-container {
        display: block;
    }

    .quiz-results-passed-right-container,
    .quiz-results-passed-left-container,
    .quiz-results-merit-left-container,
    .quiz-results-merit-right-container {
        width: 100%;
        margin-top: 20px;
        border: none !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .quiz-result-stats-card-container-large {
        max-width: 400px;
        margin: auto;
    }

    .course-user-card-container {
        display: block !important;
        width: fit-content !important;
        height: fit-content !important;;
    }

    .leaderboard-page-header-container {
        display: block !important;
    }

    .course-user-card-container {
        margin-top: 20px;
    }

    .leaderboard-page-title-container {
        width: 100%;
    }

    .learn-page-menu-container {
        display: block;
    }

    .learn-page-nav-menu-section.dropdown-section {
        border-bottom: unset;
    }

    .learn-page-dropdown-menu-container {
        position: static;
        margin-top: 20px;
        flex-wrap: wrap;
    }

    .learn-page-dropdown-menu-container > div {
        margin: 0 20px 10px 0
    }

    .home-news-container {
        margin-bottom: 60px;
    }

    .checkout-acceptance-message {
        margin-top: 20px;
    }
}