@media only screen and (max-width: 992px) and (min-width: 769px) {

    .home-contents {
        max-width: 95% !important;
    }

    .ticker-container,
    .home-section-info-pages-container,
    .home-section-coins-grid-col-chart {
        display: none;
    }

    .large-header {
        display: block;
        padding-top: 20px;
        margin-left: 150px;
    }

    .header-link {
        padding: 2px 0;
    }

    .home-search-container {
        display: flex;
        margin-left: calc(100% - 294px);
        margin-top: 0;
    }

    .nav-search {
        width: 100%;
    }

    .home-summary-container .col-md-4,
    .home-summary-container .col-md-8,
    .story-page-content .col-sm-8{
        width: 100%;
    }

    .story-page-content .col-sm-4 {
        width: 100%;
        display: flex;
        position: relative;
    }

    .related-news-container .col-sm-4 {
        width: 50%;
    }

    .info-article-container .col-sm-8 {
        width: calc(100% - 250px);
    }

    .info-article-container .col-sm-4 {
        width: 250px;
    }

    .news-story-trending {
        position: absolute;
        left: 50%;
    }

    .news-story-trending:first-child {
        position: absolute;
        left: 12px;
    }

    .story-page-news-list {
        width: 50%;
        margin-top: 10px;
        margin-right: 10px;
    }

    .one-stop-container {
        margin: 10px 30px 30px 0;
    }

    .home-summary-container .container,
    .home-news-container .container,
    .home-info-container .container,
    .home-coins-container .container,
    .news-page-content .container,
    .news-page-market-container .container,
    .news-page-most-read-container .container,
    .story-page-content .container,
    .info-page-container .container,
    .info-article-container .container,
    .coin-subpage-cards .container,
    .coins-subpage-contents .container,
    .learn-page-container .container,
    .learn-module-container .container,
    .learn-page-container .container{
        max-width: unset;
    }

    .coin-subpage-cards .col {
        padding: 0 !important;
    }

    .info-page-heading-container .container {
        max-width: calc(100% - 200px);
    }

    .home-section-info-card {
        width: 47vw !important;
    }

    .crypto-info-card-container .card-container,
    .crypto-learn-card-container .card-container,
    .crypto-coins-card-container .card-container,
    .trending-card-container,
    .gainer-card-container,
    .loser-card-container {
        width: 46vw;
    }

    .crypto-coins-card-container,
    .col.home-loser-card-container,
    .home-loser-card-container{
        margin-top: 30px;
    }

    .home-section-coins-container {
        width: 96vw;
    }

    .home-section-coins-grid-col-coin{
        width: 110px !important;
    }

    .home-section-coins-grid-col-ticker{
        width: 70px !important;
    }

    .home-section-coins-grid-col-price {
        width: 90px !important;
    }

    .home-section-coins-grid-col-market-cap {
        width: 120px !important;
    }

    .news-read-more-button,
    .home-section-main-news-block .news-time-expired{
        display: none;
    }

    .home-section-main-news-block .news-date{
        padding-top: 20px;
    }

    .one-stop-container .one-stop-subscribe-btn{
        width: 150px;
    }

    .subscribe-button-container {
        padding-left: 0 !important;
    }

    .subscribe-button {
        margin-right: 0;
    }

    .subscribe-x {
        right: 6px;
    }

    .news-page-market-story-heading {
        height: 76px;
        margin-bottom: 10px;
    }

    .news-page-most-read-story-text {
        width: calc(100% - 257px) !important;
    }

    .news-page-most-read-story-headline {
        margin-bottom: 10px;
    }

    .news-page-market-story-details-container {
        padding-top: 5px;
    }

    .news-story-block {
        padding-right: 10px !important;
    }

    .story-page-list .story-list-text {
        width: calc(100% - 128px);
    }



    .page-submenu-heading ul,
    .page-menu-container ul {
        display: flex;
        margin-bottom: 0 !important;
    }

    .info-page-nav-container {
        position: relative;
    }

    .menu-scroll-indicator {
        display: block;
        position: absolute;
        width: 20px;
    }

    .menu-scroll-indicator.left {
        left: 0;
        top: 6px;
    }

    .menu-scroll-indicator.right {
        right: 0;
        top: 6px;
    }

    .page-menu-container {
        display: flex;
        overflow-x: scroll;
        flex-wrap: nowrap;
    }

    .info-subpage-summary-content {
        width: unset;
        padding-right: 20px;
    }

    .info-subpage-summary-container,
    .info-subpage-summary {
        min-width: unset;
    }

    .info-subpage-summary-image {
        min-width: 226px;
        margin-left: 10px;
        margin-top: 6vw;
    }

    .info-subpage-cards .col-sm-4 {
        width: 50%;
    }

    .encyclopedia-submenu-heading {
        padding-bottom: 20px;
    }

    .search-page-encyclopedia-list img {
        height: 138px !important;
    }

    .home-section-info-container.search img{
        width: 80px;
    }

    .home-section-info-text.search {
        width: 120px;
    }
    .learn-module-card-button {
        width: 105px;
    }

    .learn-module-completion-percentage {
        width: 100px;
    }

    .encyclopedia-menu-container {
        padding-left: 0;
        max-width: 96vw;
        display: none;
    }

    .encyclopedia-small-menu-container {
        padding-left: 50px;
        max-width: 96vw;
        display: block;
    }

    .encyclopedia-search-box-container {
        min-width: 250px;
    }

    .encyclopedia-page-image {
        display: none;
    }

    .encyclopedia-page-word-container {
        display: block;
    }

    .encyclopedia-page-word-container {
        height: unset;
    }

    .encyclopedia-word-description-container {
        width: unset;
        padding: 10px 10px 5px 0;
    }

    .encyclopedia-word-image-text-container {
        top: 35px;
        left: 110px;
    }

    .encyclopedia-page-video {
        height: 180px;
        margin-top: 20px;
    }

    .info-article-container .container{
        padding: 10px;
    }

    .info-article-social-media-heading {
        padding-top: 60px;
    }

    .info-article-social-media img {
        padding-top: 20px;
    }

    .content-section-menu-heading-indicator{
        width: 25px;
    }

    .fixed .content-section-menu-heading-indicator,
    .bottom .content-section-menu-heading-indicator{
        width: 18px;
    }

    .info-article-image {
        height: unset;
    }

    .coin-page-show-rows {
        top: -30px;
    }

    .coin-data-analysis-block {
        display: block;
    }

    .coin-data-analysis-wide,
    .coin-data-analysis-narrow{
        width: 90%;
        margin: auto;
    }

    .coin-data-analysis-technical,
    .coin-data-statistics-container {
        margin-top: 20px;
    }

    .news-page-market-contents .col-sm-4,
    .learn-categories-container .col-sm-4,
    .module-cards-menu-container .col-sm-4{
        width: 50%;
    }

    .learn-page-hero-container {
        width: calc(50% - 20px);
        margin: auto 0 auto 50px;
    }

    .learn-page-intro-container {
        width: 50%;
    }

    .learn-page-hero-container .video {
        left: calc(50% - 29px);
        top: calc(50% - 29px);
    }

    .learn-module-summary-column {
        margin-bottom: 20px;
    }

    .learn-category-summary-container {
        height: unset;
    }

    .user-crypto-dashboard {
        margin-bottom: 80px;
    }

    .crypto-dashboard-how-to-get-started {
        margin-top: 20px;
    }

    .crypto-dashboard {
        display: block;
    }

    .crypto-dashboard-intro-container {
        width: 100%;
    }

    .crypto-dashboard-progress-container {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
    }

    .crypto-dashboard-progress-container-left {
        width: calc(100% - 204px);
    }

    .learn-categories-heading-container {
        margin-top: 0;
    }

    .course-overview-about-left,
    .course-overview-about-right{
        margin-right: 0;
        width: 49%;
    }

    .course-page-summary-container,
    .course-page-summary-container-section-container {
        width: 70vw;
    }

    .course-section-list {
        min-width: 50%;
        max-width: 50%;
    }

    .course-section-block {
        padding-left: 10px;
        max-width: unset;
        min-width: unset;
        width: unset;
    }

    .course-section-take-quiz,
    .course-section-block-text-large,
    .course-section-block-text{
        min-width: unset;
    }

    .course-section-title-large-container {
        width: calc(100vw - 100px);
    }

    .course-section-title-container,
    .course-section-opener{
        width: 48vw;
    }

    .course-section-opener {
        min-width: unset;
    }

    .course-page-subtitle {
        min-height: 200px;
    }

    .quiz-background-dark-square,
    .quiz-background-dark-triangle{
        display: none;
    }

    .quiz-contents {
        min-width: unset;
        height: fit-content;
        max-width: 100vw;
    }

    .quiz-page-body-area{
        width: 96vw;
        min-width: unset;
        top: unset;
        position: unset;
        margin: 20px 2vw 1vh;
        height: fit-content;
    }

    .quiz-start-page-title {
        padding-top: 30px;
    }

    .quiz-question-progress-fraction,
    .quiz-question{
        padding-top: 10px;
    }

    .quiz-question-options-container {
        padding-top: 0;
    }

    .quiz-page-header-container {
        margin-left: 5%;
        margin-right: 5%;
    }

    .quiz-exit-header-button-container {
        margin-top: 30px;
        z-index: 99;
    }

    .quiz-popup {
        width: 32%;
        left: 32%;
        height: fit-content;
    }

    .quiz-return-button-container {
        margin-bottom: 30px;
        height: 50px;
    }

    .quiz-questions-page-navigation-buttons {
        position: unset;
        height: 70px;
        width: fit-content;
        margin: 20px auto;
    }

    .quiz-previous-button-container,
    .quiz-next-button-container,
    .quiz-submit-button-container{
        position: unset;
        margin: 0 10vw;
    }

    .quiz-previous-button,
    .quiz-next-button,
    .quiz-submit-button,
    .quiz-submit-button-locked{
        width: 135px;
        position: unset;
    }



    .quiz-previous-button .quiz-previous-button-text,
    .quiz-next-button .quiz-next-button-text,
    .quiz-submit-button .quiz-previous-button-text {
        width: fit-content;
        margin: 0 auto;
    }

    .quiz-next-button,
    .quiz-submit-button{
        padding-left: unset;
    }

    .quiz-popup-buttons {
        margin-bottom: 10px;
    }

    .quiz-continue-course-button-container {
        height: 75px;
    }

    .coins-subpage-contents {
        margin-top: 50px;
    }

    .coin-page-top100 {
        top: -26px;
        right: unset;
        left: 15px;
    }

    .slider-button-off,
    .slider-button-on {
        top: 2px;
    }

    .affiliate-link {
        right: 10px;
        top: -125px;
    }

    .affiliate-popup-container {
        right: 20px;
        top: -240px;
        width: 250px;
    }

    .page-not-found-text-container {
        width: 94%;
        left: 3%;
        top: 7%;
        height: 40%;
    }

    .crashed-rocket {
        left: 20%;
    }

    .encyclopedia-search-word-image-text-container.search {
        top: 28px !important;
        right: -8px !important;
    }

    .cookies-pop-up-container {
        font-size: 20px;
    }

    .cookies-pop-up-text {
        padding-top: 5px;
        padding-right: 30px;
    }

    .cookies-pop-up-container button {
        width: 150px;
    }

    .membership-and-billing-buttons-container {
        display: block;
        height: 120px;
    }

    .membership-manage-buttons-container {
        margin-left: 0;
    }

    .membership-and-billing-container {
        height: 343px;
    }

    .membership-and-billing-payment-button {
        margin-top: 20px;
        margin-left: 0;
    }

    .quiz-question-options-button-selected,
    .quiz-question-options-button {
        padding: 10px;
    }

    .cse-plus-current-plan-container {
        display: none;
    }

    .cse-plus-membership-cards-container {
        display: block;
    }

    .cse-plus-membership-card-container {
        margin: 20px auto !important;
        height: fit-content;
        width: 100%;
    }

    .home-plan-image-container {
        display: none;
    }

    .home-plan-text-container {
        width: 100%;
        height: fit-content;
        padding: 40px;
    }

    .cse-plus-features-container {
        height: fit-content;
    }

    .cse-plus-tiers-title {
        margin-top: 200px;
    }


    .cse-plus-membership-feature-card-description-container {
        font-size: 14px;
    }

    .cse-quick-links-cards-container {
        display: grid;
        grid-template-columns: auto auto;
    }

    .deals-page-search-bar-container {
        height: 50px;
    }


    .deals-page-navigation-container {
        display: grid;
    }

    .deals-page-search-bar-container {
        order: 1;
        border-bottom: none !important;
        margin-top: 10px;
        width: 100%;
    }

    .deals-page-tabs-container {
        order: 2;
        width: 100%;
    }

    .deals-page-card-description {
        display: none;
    }

    .deals-page-card-title {
        max-height: 120px;
    }

    .ticker-container,
    .header-actions,
    .large-header,
    .nav-links {
        display: none;
    }

    .small-header {
        display: block;
    }

    .burger-line {
        display: block;
        height: 5px;
        width: 100%;
        margin-top: 5px;
        border-radius: 10px;
    }

    .burger-icon.active .line1,
    .burger-icon.active .line2,
    .burger-icon.active .line3 {
        display: none;
    }

    .burger-links-container {
        margin-bottom: 80px;
        margin-top: 30px;
    }

    .burger-icon {
        width: 30px;
        height: 25px;
        margin-right: 14px;
    }

    .page-action-small-menu-container {
        width: 60%;
    }

    .page-action-small-menu-container .nav-links {
        position: absolute;
        top: 0;
        right: -2vw;
        width: 100vw;
        z-index: 99;
    }

    .navbar-top-row,
    .home-settings {
        display: none;
    }

    .burger-menu.open {
        transform: translateX(0);
    }

    .burger-menu.closed {
        transform: translateX(1200px);
    }

    .burger-menu-header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 25px 0 25px;
        min-height: 100px;
        max-height: 100px;
        z-index: 102;
    }

    .burger-menu-body-container {
        padding: 0 25px 0 25px;
        z-index: 102;
    }

    .burger-menu-sections-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .burger-menu-button-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        z-index: 102;
    }

    .burger-menu-buttons {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 12px;
    }


    .burger-menu-buttons.social {
        margin: 0 60px;
    }

    .burger-menu-button-light {
        width: 100%;
        border-radius: 10px;
        padding: 7px 5px 7px 5px;
        font-size: 14px;
        border: solid 1px #FF9017;
        height: 45px;
    }

    .burger-menu-button-dark {
        width: 100%;
        border-radius: 10px;
        padding: 7px 5px 7px 5px;
        font-size: 14px;
        background-color: #3866F2;
        color: white;
        border: solid 1px #3866F2;
        height: 45px;
    }

    .burger-menu-button-theme {
        width: 35%;
        border-radius: 10px;
        font-size: 10px;
        border: none;
        height: 38px;
    }

    .burger-menu-button-theme img {
        padding: 5px;
        width: 30px;
    }

    .burger-menu-item,
    .burger-menu-dropdown {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: fit-content;
        padding: 30px 0 30px 0;
        font-size: 18px;
    }

    .burger-menu-dropdown-submenu {
        display: flex;
        padding-left: 5px;
        font-size: 15px;
        padding-bottom: 25px;
        will-change: display;
    }

    .burger-menu-dropdown-submenu.closed {
        display: none;
    }

    .burger-dropdown-options-block {
        padding-right: 150px;
    }

    .burger-menu-divider {
        border-bottom: solid 1px rgba(112, 112, 112, 0.21);
        padding-top: 2px;
    }

    .burger-menu-licences {
        font-size: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 20px 50px 20px 50px;
    }

    .burger-menu-footer {
        display: flex;
        justify-content: space-evenly;
        padding: 5px 0 5px 0;
    }

    .burger-menu-item a:link,
    .burger-menu-item a:visited,
    .burger-menu-item a:hover,
    .burger-menu-item a:active,
    .burger-footer-link {
        text-decoration: none;

    }

    .burger-dropdown-item {
        padding: 15px 0 15px 0;
    }

    .burger-menu-chevron-container {
        position: absolute;
        top: 0;
        left: 0;
        height: 300px;
        width: 100%;
        z-index: 101;
    }

    .burger-menu-header-container a img {
        margin-top: 14px;
        margin-left: -10px;
    }


    .burger-menu-close {
        margin-top: 0;
        margin-left: 25px;
        width: 20px;
    }

    .nav-menu {
        display: block;
    }

    .nav-search {
        display: block;
        margin-right: 20px;
    }

    .navbar-user-menu-triangle {
        top: 85px;
    }

    .navbar-user-menu {
        top: 100px;
    }

    .home-section-info-list-container {
        max-width: 100% !important;
        width: 100% !important;
        display: flex;
        height: fit-content;
        overflow: hidden;
    }

    .home-section-info-list {
        width: unset;
        height: unset;
    }


    .home-section-main-news-heading {
        font-size: 18px;
    }

    .home-section-main-news-heading {
        height: fit-content;
    }

    .news-page-main-story {
        height: fit-content;
        margin: 0;
        border-radius: 0 0 10px 10px;
    }

    .news-page-heading {
        font-size: 40px;
        text-align: center;
        padding: unset;
    }

    .home-section-course-image {
        width: 35% !important;
        max-width: 35% !important;
        margin-left: auto;
        margin-right: -20px;
    }

    .home-section-course a,
    .home-section-course,
    .home-section-course-content {
        width: 100%;
    }

    .deals-page-content-container {
        padding-bottom: 0;
    }

    .deals-page-card-button-container {
        position: relative;
    }

    #root .navbar {
        width: 100%;
    }

    .home-navbar-container {
        width: 90% !important;
        margin: 0 5%;
    }

    .profile-discord-container,
    .profile-discord-outer-container {
        display: block;
    }

    .profile-discord-link-button {
        margin: 25px 0 0 0;
    }

    .home-contents-extended {
        max-width: 560px;
    }

    .collection-obscurer {
        width: 50px;
        padding: 0 !important;
    }

    .collection-obscurer.left-overflow {
        left: 0;
    }

    .collection-obscurer.right-overflow {
        right: 0;
    }

    .collection-obscurer-button {
        width: 30px;
        height: 30px;
    }

    .collection-obscurer-button img {
        height: 15px;
        width: 8px;
    }

    .new-course-banner-image-container {
        display: none;
    }

    .new-course-banner-out-now-text,
    .new-course-banner-description {
        max-width: 100%;
    }

    .learn-page-title-content {
        display: flex;
        width: 100%;
    }

    .learn-page-text-container {
        width: 40%;
    }

    .learn-page-title-container {
        display: block;
    }

    .top-ten-leaderboard-container {
        width: 100%;
    }

    .learn-page-menu-container {
        display: block;
    }

    .learn-page-nav-menu-section.dropdown-section {
        border-bottom: unset;
    }

    .learn-page-dropdown-menu-container {
        position: static;
        margin-top: 20px;
    }

    .learn-page-dropdown-menu-container > div {
        margin: 0 30px 0 0;
    }

    .home-news-container {
        margin-bottom: 60px;
    }

    .checkout-acceptance-message {
        margin-top: 20px;
    }
}