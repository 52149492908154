@media only screen and (max-width: 768px) and (min-width: 576px) {

    .home-contents {
        max-width: 95% !important;
    }

    .home-search-container {
        display: flex;
        margin-left: calc(100% - 294px);
    }

    .ticker-container {
        display: none;
    }


    .container, .container-sm {
        max-width: 96vw !important;
    }

    .home-section-info-list-container {
        max-width: 100% !important;
        width: 100% !important;
        display: flex;
        height: fit-content;
        overflow: hidden;
    }

    .home-section-info-list {
        width: unset;
        height: unset;
    }

    .home-section-info-pages-container {
        display: none;
    }

    .shift-margin {
        margin-left: 0 !important;
    }

    .ticker-container {
        display: none;
    }

    .header-actions {
        width: 100%;
    }

    .home-settings-item.dropdown {
        display: none;
    }

    .home-settings-item {
        margin-top: 14px;
    }


    .large-header {
        display: block;
        padding-top: 20px;
    }

    .news-list {
        margin-top: 10px !important;
        height: 270px;
        margin-bottom: 0 !important;
    }

    .story-list-story-container {
        height: 65px;
        overflow-y: hidden;
    }

    .news-headline {
        height: 27px;
        overflow-y: hidden;
    }

    .news-story-detail {
        padding-top: 10px;
        height: 28px;
        overflow-y: hidden;
    }

    .news-detail-filler {
        display: none;
    }

    .crypto-info-card-container .row {
        width: unset;
    }

    .card-contents-container {
        max-width: 160px !important;
        width: 160px !important;
    }

    .card-container,
    .trending-card-container,
    .gainer-card-container{
        margin-bottom: 20px;
    }

    .home-section-news-main-story,
    .news-page-main-story,
    .news-page-market-story,
    .home-learn-2-earn-container {
        margin-right: 10px;
    }

    .home-info-content .col-sm-4,
    .learn-categories-container .col-sm-4,
    .learn-module-cards .col-sm-4{
        width: 96% !important;
        margin-right: 2%;
    }

    .home-section-coins-container {
        width: 94vw;
    }

    .card-image img {
        left: 0;
    }

    .coin-text-heavy.name {
        display: none;
    }

    .footer-container {
        width: 96vw;
        margin: auto;
        height: 555px;
    }

    .social-media-logo {
        display: block;
    }

    .social-media-logo img {
        margin-bottom: 10px;
    }

    .news-page-card-button {
        padding: 5px 10px;
    }

    .news-page-card-carousel-button {
        right: 10px;
        padding: 5px 10px;
    }

    .news-page-most-read-list .col-sm-6 {
        width: 100%;
    }

    .news-page-most-read-story-text {
        width: calc(100% - 257px) !important;
    }

    .story-page-content .col-sm-8,
    .story-page-content .col-sm-4{
        width: 100%;
    }

    .story-page-news-list {
        width: 100%;
        max-width: 400px;
    }

    .story-page-list .story-list-text {
        width: calc(100% - 128px) !important;
    }

    .home-contents {
        max-width: 96vw !important;
        margin: auto;
    }

    .info-page-heading-container {
        display: block;
    }

    .info-page-heading {
        text-align: center;
        font-size: 20px;
    }

    .page-menu-container {
        display: flex;
        overflow-x: scroll;
        flex-wrap: nowrap;
    }

    .page-submenu-container {
        display: flex;
        overflow-x: scroll;
        flex-wrap: nowrap;
    }

    .page-submenu-heading ul,
    .page-menu-container ul {
        display: flex;
        margin-bottom: 0 !important;
    }

    .info-page-nav-container {
        padding-left: 45px !important;
        padding-top: 0;
        position: relative;
    }

    .menu-scroll-indicator {
        display: block;
        position: absolute;
        width: 20px;
    }

    .menu-scroll-indicator.left {
        left: 0;
        top: 16px;
    }

    .menu-scroll-indicator.right {
        right: 0;
        top: 16px;
    }

    .exchange-subpage-text,
    .wallet-subpage-text {
        width: 92vw;
    }

    .exchange-subpage-cards .col-sm-4,
    .wallet-subpage-cards .col-sm-4,
    .info-subpage-cards .col-sm-4{
        width: 360px;
        min-width: 360px;
    }

    .content-section-menu-heading {
        width: 85%;
    }

    .subscribe-image-container, .subscribe-button-container {
        min-width: 150px;
    }

    .subscribe-banner-text {
        width: calc(100% - 300px) !important;
    }

    .crypto-dashboard {
        display: block;
    }

    .crypto-dashboard-intro-container,
    .crypto-dashboard-progress-container{
        width: 100%;
        margin: 0;
    }

    .crypto-dashboard-progress-container {
        margin-top: 20px;
        height: fit-content;
        max-width: 400px;
    }

    .crypto-dashboard-progress-container-row {
        padding: 0 10px 0 10px;
    }

    .crypto-dashboard-progress-modules-completed {
        text-align: left;
    }

    .crypto-dashboard-progress-wheel-container {
        display: none;
    }

    .crypto-dashboard-progress-modules-complete-label {
        margin-top: 30px;
    }

    .user-crypto-dashboard {
        padding-bottom: 100px;
    }

    .crypto-dashboard-welcome-container,
    .crypto-dashboard-progress-container-row{
        display: block;
    }

    .crypto-dashboard-profile-picture-container,
    .crypto-dashboard-progress-container-merits-complete-container {
        margin-left: 10px;
    }

    .crypto-dashboard-profile-name {
        margin-top: 10px;
    }

    .crypto-dashboard-progress-container-left {
        padding-bottom: 10px;
        width: 100%;
    }

    .crypto-dashboard-progress-modules-complete-label {
        display: block;
        text-align: center;
        margin-left: 0;
    }

    .crypto-dashboard-progress-completed-level-modules{
        margin-left: 0;
    }

    .crypto-dashboard-progress-container-right {
        display: none;
    }

    .user-crypto-dashboard {
        padding-bottom: 100px;
    }

    .learn-category-column {
        display: block;
    }

    .learn-module-summary {
        width: 96vw;
    }

    .learn-category-description-column {
        width: 100%;
    }

    .learn-module-menu {
        margin-top: 60px;
    }

    .learn-category-summary-container {
        height: unset;
        width: 92vw;
    }

    .learn-category-text {
        width: 96%;
    }

    .learn-category-learn-areas-column {
        padding-left: 0;
    }

    .learn-category-content-area-container {
        margin-left: 0;
    }

    .learn-module-card-container, .learn-category-card-container, .disabled-learn-category-card  {
        max-width: 650px;
    }

    .learn-module-cards .col-sm-4 {
        width: 100% !important;
    }

    .course-content-container {
        width: 92vw;
        margin: auto;
    }

    .course-page-summary {
        display: block;
        width: 90vw;
        margin: auto;
    }

    .course-page-block,
    .course-page-hero{
        width: 92vw;
    }

    .course-content-block {
        display: block;
    }

    .course-section-block {
        padding: 0;
        width: 92vw;
        min-width: unset;
        max-width: unset;
    }

    .chapter-progress-container,
    .course-section-block-text-large,
    .course-section-block-text,
    .course-section-take-quiz{
        min-width: unset;
        max-width: unset;
    }

    .course-content-container .page-menu-container {
        width: 60vw;
    }

    .page-menu-container {
        margin-left: 20px;
        margin-right: 20px;
    }

    .page-menu-container {
        display: flex;
        overflow-x: scroll;
        flex-wrap: nowrap;
    }

    .hide-chapters-menu-button {
        display: none;
    }

    .quiz-contents {
        min-width: 90vw;
        height: fit-content;
        max-width: 100vw;
    }

    .one-stop-container .one-stop-subscribe-btn {
        width: 150px;
    }

    .home-section-course {
        height: 132px;
    }

    .home-section-course-title {
        min-height: 38px;
    }

    .home-section-course-image {
        width: 194px !important;
    }

    .home-section-course-text {
        width: calc(100% - 194px) !important;
        min-width: calc(100% - 194px) !important;
        max-width: calc(100% - 194px) !important;
    }

    .home-section-coins-grid-col-market-cap,
    .home-section-coins-grid-col-chart{
        display: none;
    }

    .home-section-coins-grid-col-ticker {
        width: 80px !important;
    }

    .home-section-coins-grid-col-price {
        width: 105px !important;
    }

    .row.home-section-coins-grid-head,
    .home-section-coins-grid-col-favourite,
    .home-section-coins-grid-col-number,
    .home-section-coins-grid-col-coin,
    .home-section-coins-grid-col-ticker,
    .home-section-coins-grid-col-price,
    .home-section-coins-grid-col-day,
    .home-section-coins-grid-col-week,
    .row.home-section-coins-grid-body{
        flex-wrap: nowrap;
        flex-shrink: 1 !important;
    }

    .home-section-coins-grid-body,
    .home-section-coins-grid-head {
        margin-left: 5px!important;
        margin-right: 5px!important;
        width: calc(100% - 10px);
    }


    .home-news-container .col,
    .footer-container .col,
    .home-learn-content .col,
    .news-page-content .col {
        flex: none;
    }

    .home-section-news-list-story .home-section-news-text,
    .news-page-list-story .news-page-story-text {
        padding: 10px 10px 10px 20px !important;
    }

    .news-page-story-text,
    .home-section-news-text{
        display: block;
        width: calc(100% - 194px) !important;
    }


    .news-page-trending-heading {
        padding-top: 30px;
    }

    .page-submenu-title {
        z-index: 2;
        padding-right: 10px;
    }

    .info-subpage-summary-container,
    .info-subpage-summary {
        display: block;
        min-width: unset;
    }

    .info-subpage-cards .col-sm-4 {
        width: 96%;
        max-width: 300px;
    }

    .footer-topic-heading,
    .footer-topic-text{
        display: none;
    }

    .legal-content,
    .social-media-logo{
        display: block;
    }

    .legal-content {
        max-width: 90vw;
        margin: 0;
    }

    .legal-text{
        margin-top: 20px;
    }

    .legal-container {
        margin: 20px 0 0 20px !important;
    }

    .follow-us {
        display: block;
    }

    .learn-page-intro,
    .course-overview-about-container{
        display: block;
    }

    .learn-page-hero-container img {
        margin-top: 30px;
    }

    .learn-category-card {
        width: fit-content;
    }

    .course-section-list {
        padding-bottom: 0;
        max-width: unset;
    }

    .course-section-opener,
    .course-section-opener-large {
        min-width: unset;
        padding: 10px 0;
    }

    .course-overview-about-left {
        margin-right: unset;
    }

    .coins-subpage-contents {
        margin-top: 50px;
    }

    .coin-page-show-rows {
        top: -30px;
    }

    .coin-grid-container {
        width: 90vw;
    }

    .coin-grid-footer-prev,
    .coin-grid-footer-next{
        display: flex;
    }

    .coin-grid-footer-contents {
        overflow-x: hidden;
    }

    .coin-grid-footer-contents button {
        margin-left: 2px;
        margin-right: 2px;
    }

    .coin-grid-footer-pages-container{
        width: calc(100% - 220px);
        overflow-x: hidden;
    }

    .coin-grid-header .number {
        width: 40px;
    }

    .coin-grid-header .chart,
    .coin-grid-header .day,
    .coin-grid-header .market-cap,
    .coin-grid-row .chart,
    .coin-grid-row .day,
    .coin-grid-row .market-cap {
        display: none;
    }

    .coin-grid-footer-showing {
        top: 50px;
        right: 0;
    }

    .coin-detail-summary-block,
    .coin-data-analysis-block{
        display: block;
    }

    .coin-data-analysis-wide,
    .coin-data-analysis-narrow{
        width: 100%;
    }

    .coin-detail-stats {
        width: 96%;
        padding-left: 10px;
    }

    .coin-detail-contents {
        padding: 30px 10px;
    }

    .coin-data-analysis-chart,
    .coin-data-analysis-vote-container {
        padding-right: 0;
    }

    .coin-data-analysis-vote-choose {
        display: flex;
        width: 180px;
        align-items: center;
        margin-left: calc(50% - 160px);
    }

    .coin-data-analysis-vote-text {
        width: 50%;
    }

    .coin-data-statistics-container {
        margin-top: 40px;
    }

    .market-data-grid-row {
        height: 52px;
        padding-top: 14px;
    }

    .market-data-grid-row .number,
    .market-data-grid-row .exchange,
    .market-data-grid-row .pairs,
    .market-data-grid-row .plus-depth,
    .market-data-grid-row .minus-depth
    {
        padding-top: 4px;
    }

    .market-data-grid-header .number,
    .market-data-grid-row .number{
        width: 12px;
        min-width: 12px;
        overflow: hidden;
    }

    .market-data-grid-header .exchange,
    .market-data-grid-row .exchange{
        width: 120px;
        min-width: 100px;
        overflow-x: hidden;
    }

    .market-data-grid-header .price,
    .market-data-grid-row .price,
    .market-data-grid-header .pairs,
    .market-data-grid-row .pairs{
        width: fit-content;
        min-width: 80px;
        max-width: 100px;
        overflow-x: hidden;
        height: 32px;
    }


    .market-data-grid-header .day-volume,
    .market-data-grid-row .day-volume,
    .market-data-grid-header .perc-volume,
    .market-data-grid-row .perc-volume,
    .market-data-grid-header .market-cap,
    .market-data-grid-row .market-cap{
        display: none;
    }

    .news-page-market-contents .col-sm-4 {
        width: 100% !important;
    }

    .news-page-market-story {
        margin-right: 0;
    }

    .quiz-background-dark-square,
    .quiz-page-bc-separator,
    .quiz-background-dark-triangle{
        display: none;
    }

    .quiz-page-breadcrumbs-container {
        display: block;
    }

    .quiz-contents {
        min-width: unset;
        height: fit-content;
        max-width: 100vw;
        min-height: 100vh;

    }

    .quiz-page-body-area{
        width: 96vw;
        min-width: unset;
        height: unset;
        min-height: 70%;
        top: unset;
        position: unset;
        margin: 20px 2vw 1vh;
    }

    .quiz-page-header-container {
        margin: 0;
        display: block;
    }

    .quiz-start-page-title {
        padding-top: 30px;
    }

    .quiz-page-cse-logo {
        margin-left: 10px;
    }

    .quiz-page-breadcrumbs-container {
        padding-left: 5px;
        padding-right: 5px;
        display: grid;
        text-align: center;
        width: 100vw;
    }

    .quiz-exit-header-button {
        margin: auto;
    }

    .related-news-title {
        letter-spacing: 0.1px !important;
    }

    .questions-progress-bar,
    .results-progress-bar {
        width: 10vw;
    }

    .quiz-question-progress-fraction,
    .quiz-question{
        padding-top: 10px;
    }

    .quiz-question-options-container {
        padding-top: 0;
    }

    .quiz-results-page-card-buttons {
        display: block;
    }

    .quiz-popup {
        width: 92%;
        left: 4%;
        height: fit-content;
    }

    .quiz-return-button-container {
        margin-bottom: 30px;
        height: 50px;
    }

    .quiz-questions-page-navigation-buttons {
        position: unset;
        height: 50px;
        width: fit-content;
        margin: 20px auto;
    }

    .quiz-previous-button-container,
    .quiz-next-button-container,
    .quiz-submit-button-container{
        position: unset;
        margin: 2vw;
    }

    .quiz-previous-button,
    .quiz-next-button,
    .quiz-submit-button{
        width: 115px;
        position: unset;
    }

    .quiz-previous-button img,
    .quiz-next-button img {
        display: none;
    }

    .quiz-previous-button .quiz-previous-button-text,
    .quiz-next-button .quiz-next-button-text,
    .quiz-submit-button .quiz-previous-button-text {
        width: fit-content;
        margin: 0 auto;
    }

    .quiz-next-button,
    .quiz-submit-button{
        padding-left: unset;
    }

    .quiz-popup-buttons {
        margin-bottom: 10px;
    }

    .quiz-continue-course-button-container {
        height: 75px;
    }

    .quiz-continue-course-button-container-center  {
        height: 50px;
    }

    .result-image-container.merit  {
        width: 240px;
        margin: auto;
    }

    .result-image-container.merit img {
        margin: 0;
    }

    .search-page-articles .col-sm-6 {
        width: 100%;
    }

    .search-page-list .search-page-list-text{
        width: calc(100% - 230px);
    }

    .search-page-encyclopedia-list .search-page-encyclopedia-list-text{
        width: calc(100% - 230px);
    }

    .search-page-coins .col-sm-4 {
        width: 100%;
    }

    .search-page-learn .col-sm-4 {
        width: 50%;
        min-width: 340px;
    }

    .search-page-info-list .col-sm-4 {
        width: 50%;
        min-width: 400px;
        flex: 0 0 auto;
    }
    .search-search-box {
        width: 100%;
    }

    .encyclopedia-page-word-container {
        height: unset;
        display: block;
    }

    .encyclopedia-word-description-container {
        width: unset;
    }

    .info-article-content .col-sm-8 {
        width: 100%;
    }

    .info-article-menu-container {
        display: none;
    }

    .coin-page-top100 {
        top: -26px;
        right: unset;
        left: 15px;
    }

    .coin-detail-summary-block .home-contents {
        display: block;
    }

    .coin-detail-summary {
        margin-top: 0;
        padding-top: 30px;
    }

    .footer-container .row {
        position: relative;
        width: 96vw;
        margin-left: 0;
    }

    .follow-us {
        position: absolute;
        right: 20px;
        width: fit-content !important;
    }

    .follow-us .footer-topic-heading {
        display: block;
    }

    .encyclopedia-menu-container {
        padding-left: 0;
        max-width: 96vw;
        display: none;
    }

    .encyclopedia-small-menu-container {
        padding-left: 50px;
        max-width: 96vw;
        display: block;
    }

    .affiliate-link {
        right: 10px;
        top: 60px;
    }

    .affiliate-popup-container {
        right: 20px;
        top: -150px;
        width: 300px;
        z-index: 999 !important;
    }

    .page-not-found-text-container {
        width: 94%;
        left: 3%;
        top: 7%;
        height: 40%;
    }

    .crashed-rocket {
        left: 5%;
    }

    .page-not-found-message-container {
        display: block;
    }

    .learn-categories-container {
        padding-top: 70px;
    }

    .encyclopedia-search-word-image-text-container.search {
        width: fit-content;
        right: 13px !important;
    }

    .cookies-pop-up-container {
        font-size: 20px;
    }

    .cookies-pop-up-text {
        padding-top: 0;
        padding-right: 30px;
    }

    .cookies-pop-up-container button {
        width: 150px;
        height: 40px;
    }

    .membership-and-billing-buttons-container {
        display: block;
        height: 120px;
    }

    .membership-manage-buttons-container {
        margin-left: 0;
    }

    .membership-and-billing-container {
        height: 343px;
    }

    .membership-and-billing-payment-button {
        margin-top: 20px;
        margin-left: 0;
    }

    .quiz-question-options-button-selected,
    .quiz-question-options-button {
        padding: 10px;
        margin-left: 5%;
        margin-right: 5%;
        width: 90%;
    }

    .cse-plus-current-plan-container {
        display: none;
    }

    .cse-plus-membership-cards-container {
        display: block;
    }

    .cse-plus-membership-card-container {
        margin: 20px auto !important;
        height: fit-content;
        width: 100%;
    }

    .home-plan-image-container {
        display: none;
    }

    .home-plan-text-container {
        width: 100%;
        height: fit-content;
        padding: 40px;
    }

    .cse-plus-membership-feature-card {
        height: 290px;
    }

    .cse-plus-membership-feature-card-title-container {
        font-size: 14px;
    }

    .cse-plus-tiers-title {
        margin-top: 200px;
    }

    .cse-plus-membership-feature-card-description-container {
        font-size: 12px;
    }

    .cse-plus-features-container {
        height: fit-content;
    }

    .cse-quick-links-cards-container {
        display: grid;
        grid-template-columns: auto auto;
    }

    .deals-page-navigation-container {
        display: grid;
    }

    .deals-page-search-bar-container {
        order: 1;
        border-bottom: none !important;
        margin-top: 10px;
        width: 100%;
    }

    .deals-page-tabs-container {
        order: 2;
        width: 100%;
    }

    .deals-page-card-description {
        display: none;
    }

    .deals-page-card-title {
        max-height: 120px;
    }

    .ticker-container,
    .header-actions,
    .large-header,
    .nav-links {
        display: none;
    }

    .small-header {
        display: block;
    }

    .burger-line {
        display: block;
        height: 5px;
        width: 100%;
        margin-top: 5px;
        border-radius: 10px;
    }

    .burger-icon.active .line1,
    .burger-icon.active .line2,
    .burger-icon.active .line3 {
        display: none;
    }

    .burger-links-container {
        margin-bottom: 80px;
        margin-top: 30px;
    }

    .burger-icon {
        width: 30px;
        height: 25px;
        margin-right: 14px;
    }

    .page-action-small-menu-container {
        width: 60%;
    }

    .page-action-small-menu-container .nav-links {
        position: absolute;
        top: 0;
        right: -2vw;
        width: 100vw;
        z-index: 99;
    }

    .navbar-top-row,
    .home-settings {
        display: none;
    }

    .burger-menu.open {
        transform: translateX(0);
    }

    .burger-menu.closed {
        transform: translateX(1000px);
    }

    .burger-menu-header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 25px 0 25px;
        min-height: 100px;
        max-height: 100px;
        z-index: 102;
    }

    .burger-menu-body-container {
        padding: 0 25px 0 25px;
        z-index: 102;
    }

    .burger-menu-sections-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .burger-menu-button-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        z-index: 102;
    }

    .burger-menu-buttons {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 12px;
    }


    .burger-menu-buttons.social {
        margin: 0 60px;
    }

    .burger-menu-button-light {
        width: 100%;
        border-radius: 10px;
        padding: 7px 5px 7px 5px;
        font-size: 14px;
        border: solid 1px #FF9017;
        height: 45px;
    }

    .burger-menu-button-dark {
        width: 100%;
        border-radius: 10px;
        padding: 7px 5px 7px 5px;
        font-size: 14px;
        background-color: #3866F2;
        color: white;
        border: solid 1px #3866F2;
        height: 45px;
    }

    .burger-menu-button-theme {
        width: 35%;
        border-radius: 10px;
        font-size: 10px;
        border: none;
        height: 38px;
    }

    .burger-menu-button-theme img {
        padding: 5px;
        width: 30px;
    }

    .burger-menu-item,
    .burger-menu-dropdown {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: fit-content;
        padding: 30px 0 30px 0;
        font-size: 18px;
    }

    .burger-menu-dropdown-submenu {
        display: flex;
        padding-left: 5px;
        font-size: 15px;
        padding-bottom: 25px;
        will-change: display;
    }

    .burger-menu-dropdown-submenu.closed {
        display: none;
    }

    .burger-dropdown-options-block {
        padding-right: 150px;
    }

    .burger-menu-divider {
        border-bottom: solid 1px rgba(112, 112, 112, 0.21);
        padding-top: 2px;
    }

    .burger-menu-licences {
        font-size: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 20px 50px 20px 50px;
    }

    .burger-menu-footer {
        display: flex;
        justify-content: space-evenly;
        padding: 5px 0 5px 0;
    }

    .burger-menu-item a:link,
    .burger-menu-item a:visited,
    .burger-menu-item a:hover,
    .burger-menu-item a:active,
    .burger-footer-link {
        text-decoration: none;

    }

    .burger-dropdown-item {
        padding: 15px 0 15px 0;
    }

    .burger-menu-chevron-container {
        position: absolute;
        top: 0;
        left: 0;
        height: 300px;
        width: 100%;
        z-index: 101;
    }

    .burger-menu-header-container a img {
        margin-top: 14px;
        margin-left: -10px;
    }


    .burger-menu-close {
        margin-top: 0;
        margin-left: 25px;
        width: 20px;
    }

    .nav-menu {
        display: block;
    }

    .nav-search {
        display: block;
        margin-right: 20px;
    }

    .navbar-user-menu-triangle {
        top: 85px;
    }

    .navbar-user-menu {
        top: 100px;
    }

    .profile-discord-container {
        display: block;
        height: fit-content;
    }

    .profile-discord-image-container {
        margin-left: 0;
    }


    .home-section-main-news-heading {
        font-size: 18px;
    }

    .home-section-main-news-heading {
        height: fit-content;
    }

    .news-page-main-story {
        height: fit-content;
        margin: 0;
        border-radius: 0 0 10px 10px;
    }

    .news-page-heading {
        font-size: 16px;
        text-align: center;
        padding: unset;
    }

    .home-section-course-image {
        width: 35% !important;
        max-width: 35% !important;
        margin-left: auto;
        margin-right: -20px;
    }

    .home-section-course a,
    .home-section-course,
    .home-section-course-content {
        width: 100%;
    }

    .home-section-news-main-story {
        height: fit-content;
    }

    .home-section-news-list-story {
        display: flex !important;
        width: 100% !important;
    }

    .home-section-news-list-story .row {
        width: 100%;
    }

    .home-section-news-image {
        width: 194px !important;
        margin-left: auto;
        margin-right: -20px;
    }

    .deals-page-content-container {
        padding-bottom: 0;
    }

    .deals-page-card-button-container {
        position: relative;
    }

    #root .navbar {
        width: 100%;
    }

    .home-navbar-container {
        width: 90% !important;
        margin: 0 5%;
    }

    .profile-discord-input-container,
    .profile-discord-container,
    .profile-discord-outer-container {
        display: block;
    }

    .profile-discord-link-button {
        margin: 25px 0 0 0;
    }

    .profile-page-account-plus,
    .profile-page-account-preferences,
    .profile-page-account-details,
    .profile-page-account-referrals {
        margin: 0 5px 0 5px;
    }

    .leaderboard-page-body-container {
        display: block;
    }

    .leaderboard-container {
        width: 100%;
    }

    .profile-page-account-referrals {
        width: 100%;
        border: none !important;
        margin: 0 !important;
    }

    .profile-page-navigation-buttons {
        display: none;
    }

    .profile-form-container {
        width: 100%;
    }

    .profile-referrals-mobile-button {
        display: block !important;
    }

    .home-contents-extended {
        max-width: 560px;
    }

    .collection-obscurer {
        width: 50px;
        padding: 0 !important;
    }

    .collection-obscurer.left-overflow {
        left: 0;
    }

    .collection-obscurer.right-overflow {
        right: 0;
    }

    .collection-obscurer-button {
        width: 30px;
        height: 30px;
    }

    .collection-obscurer-button img {
        height: 15px;
        width: 8px;
    }

    .new-course-banner-image-container {
        display: none;
    }

    .new-course-banner-out-now-text,
    .new-course-banner-description {
        max-width: 100%;
    }

    .new-course-banner-text-container {
        padding-left: 20px;
        padding-right: 20px;
    }

    .learn-page-title-content {
        display: flex;
        width: 100%;
    }

    .learn-page-text-container {
        width: 45%;
        margin-right: 5%;
    }

    .learn-page-title-container {
        display: block;
        margin-top: -280px !important;
    }

    .top-ten-leaderboard-container {
        width: 100%;
    }

    .quiz-results-passed-container,
    .quiz-results-merit-container {
        display: block;
    }

    .quiz-results-passed-right-container,
    .quiz-results-passed-left-container,
    .quiz-results-merit-left-container,
    .quiz-results-merit-right-container {
        width: 100%;
        margin-top: 20px;
        border: none !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .quiz-result-stats-card-container-large {
        max-width: 400px;
        margin: auto;
    }

    .leaderboard-page-header-container {
        display: block !important;
    }

    .course-user-card-container {
        margin-top: 20px;
    }

    .leaderboard-page-title-container {
        width: 100%;
    }

    .learn-page-menu-container {
        display: block;
    }

    .learn-page-nav-menu-section.dropdown-section {
        border-bottom: unset;
    }

    .learn-page-dropdown-menu-container {
        position: static;
        margin-top: 20px;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .learn-page-dropdown-menu-container > div {
        margin: 0 0 5px 0
    }

    .home-news-container {
        margin-bottom: 60px;
    }

    .checkout-acceptance-message {
        margin-top: 20px;
    }

}